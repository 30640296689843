import React from "react";
import DataService from "../../services/DataService";
import {
	NavDropdown,
	Nav,
	Table,
	Image,
	Row,
	Col,
	Button,
	ButtonGroup,
} from "react-bootstrap";
import Paginator from "../../helpers/Pagination";

class FailedOrders extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.dataHandler = new DataService("retrieve_orders");
		this.state = {
			itemsDisplayed: [],
			selectedRestaurant: this.dataHandler.getSelectedRestaurant(),
		};

		this.reloadData();
	}
	/*
    fetch elements from server and reload data elements
    */
	reloadData = () => {
		let inputParams = { method: "retrieve_failed_orders" };
		if (this.state.selectedRestaurant != 0) {
			inputParams = {
				restaurant_id: this.state.selectedRestaurant,
				...inputParams,
			};
		}

		this.dataHandler.findData(inputParams).then((response) => {
			let currentData = response.data.data.orders;
			this.setState({
				itemsDisplayed: currentData,
			});
		});
	};

	render() {
		return (
			<div className="p-4 bg-white shadow-sm">
				<Row>
					<Col md={12}>
						<Nav
							activeKey={0}
							className="ml-auto"
							// onSelect={this.closeMenu}
						>
							<Nav.Link
								eventKey={0}
								// as={}
								activeclassname="active"
								exact
								to=""
							>
								<h4 className="font-weight-bold mt-0 mb-4">
									{" "}
									Failed Orders Last 7 Days{" "}
								</h4>
								<span className="sr-only">(current)</span>
							</Nav.Link>

							{/* <NavDropdown
								alignRight
								title={
									<DropDownTitle
										className="d-inline-block mt-2"
										image="img/filter.png"
										imageAlt="user"
										imageClass="nav-osahan-pic"
										title=""
									/>
								}
							>
								<NavDropdown.Item
									eventKey={4.1}
									as={NavLink}
									activeclassname="active"
									to="/myaccount/orders"
								>
									<Icofont icon="database" /> All
								</NavDropdown.Item>
								<NavDropdown.Item
									eventKey={4.2}
									as={NavLink}
									activeclassname="active"
									to="/myaccount/offers"
								>
									<Icofont icon="database-add" /> Recentely Added
								</NavDropdown.Item>
								<NavDropdown.Item
									eventKey={4.3}
									as={NavLink}
									activeclassname="active"
									to="/myaccount/favourites"
								>
									<Icofont icon="arrow-up" /> Blocked
								</NavDropdown.Item>
								<NavDropdown.Item
									eventKey={4.4}
									as={NavLink}
									activeclassname="active"
									to="/myaccount/payments"
								>
									<Icofont icon="cart" /> Closed
								</NavDropdown.Item>
							</NavDropdown>
							<Link
								to="/myaccount/newFranchaise"
								onClick=""
								className="text-primary mr-3 mt-3"
							>
								<i className="icofont-plus-circle"></i> New
								Restaurant
							</Link> */}
						</Nav>
					</Col>
					<Col md={12}>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>SI</th>
									<th> ID</th>
									{/* <th> Name</th>
									<th>Address</th>
									<th>Phone</th>
									<th>Email</th> */}
									{/* <th>Avg Preperation Time</th>
									<th>Actions</th> */}
								</tr>
							</thead>
							<tbody>
								{this.state.itemsDisplayed.map((dataObj, idx) => (
									<tr>
										<td>{idx + 1}</td>
										<td>{dataObj.id}</td>

										{/* <td>
											{dataObj.first_name +
												" " +
												dataObj.last_name}
										</td>
										<td>{dataObj.address}</td>
										<td>{dataObj.phone}</td>
										<td>{dataObj.email}</td> */}
									</tr>
								))}
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>
		);
	}
}
export default FailedOrders;
