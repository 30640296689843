import React, { Component } from "react";
import { toFullDate } from "../../services/DataFormatHandler";

class DoorDashDetails extends Component {
  // Function to format date and time

  // Function to format fee from cents to dollars
  formatFee(cents) {
    return (cents / 100).toFixed(2); // Convert to dollars and keep 2 decimal places
  }

  render() {
    const { pickupTimeEstimated, dropoffTimeEstimated, fee } = this.props;

    return (
      <div>
        {pickupTimeEstimated && dropoffTimeEstimated ? (
          <div>
            <p>
              <b>Pickup Time Estimated: </b>
              {toFullDate(pickupTimeEstimated)}
            </p>
            <p>
              <b>Dropoff Time Estimated:</b> {toFullDate(dropoffTimeEstimated)}
            </p>

            <p>Doordash Fee: ${this.formatFee(fee)}</p>
            <p>Order Delivery Fee: ${this.props.totalDelivery}</p>
          </div>
        ) : (
          <p>Details not available.</p>
        )}
      </div>
    );
  }
}

export default DoorDashDetails;
