import React from 'react';
import {Form,Modal,Button} from 'react-bootstrap';

class CoupenModel extends React.Component {
	constructor(props, context) {
		super(props, context);

this.validator=props.validator;
	}
	render() {
    	return (
	        <Modal
	        	show={this.props.show}
	        	onHide={this.props.onHide}
		        size="lg"
		        centered
		   	  >
			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h5' id="edit-profile">{this.props.title}</Modal.Title>
			  </Modal.Header>

			  <Modal.Body>
			    <Form>
               <div className="form-row">
			   <Form.Group className="col-md-4">
                              <Form.Label>Coupon Code</Form.Label>
                              
							  {this.validator.message('coupen_code', this.props.createForm['coupen_code'], 'required',{ className: 'text-danger' }) }

                                <Form.Control
                                  type="text"
								  defaultValue={this.props.createForm['coupen_code']}
                                  onChange={this.props.createFormHandler}
                                  name="coupen_code"
                                  placeholder="Coupon Code"
                                />
                             
                            </Form.Group>

                  <Form.Group className="col-md-8">
                     <Form.Label>Title</Form.Label>
					 {this.validator.message('title', this.props.createForm['title'], 'required',{ className: 'text-danger' }) }

                     <Form.Control type="text"  
					 onChange={this.props.createFormHandler} 
					 name="title" 
					 defaultValue={this.props.createForm['title']} placeholder="Enter Catogery Name" />
                  </Form.Group>
				 

               </div>
			   <div className="form-row">

<Form.Group className="col-md-4">
<Form.Check
			  type="checkbox"
			  name="enable_delivery"
			  checked={
				this.props.createForm["enable_delivery"]
			  }
			  onClick={this.props.createFormHandler}
			  label="Enable Coupen for Delivery orders"
			/> </Form.Group>
	

<Form.Group className="col-md-4">
<Form.Check
			  type="checkbox"
			  name="enable_takeout"
			  checked={
				this.props.createForm["enable_takeout"]
			  }
			  onClick={this.props.createFormHandler}
			  label="Enable Coupen for Takeout orders"
			/> </Form.Group>
			</div>
			   <div className="form-row">
                  <Form.Group className="col-md-6">
                     <Form.Label>Start Date</Form.Label>
					 {this.validator.message('start_date', this.props.createForm['start_date'], 'required',{ className: 'text-danger' }) }

                     <Form.Control type="date"  
					 onChange={this.props.createFormHandler} 
					 name="start_date" 
					 defaultValue={this.props.createForm['start_date']}
					 placeholder="Enter Start Date" />
                  </Form.Group>
				  <Form.Group className="col-md-6">
                     <Form.Label>End Date</Form.Label>
					 {this.validator.message('end_date', this.props.createForm['end_date'], 'required',{ className: 'text-danger' }) }

                     <Form.Control  type="date"  
					 onChange={this.props.createFormHandler} 
					 name="end_date" 
					 defaultValue={this.props.createForm['end_date']}
					  placeholder="Enter End Date" />
                  </Form.Group>

               </div>
			   <div className="form-row">
				   
				   <Form.Group className="col-md-8">
				   <Form.Label>Applicable only to Orders value above </Form.Label>
				   <Form.Control type="number" 
				    onChange={this.props.createFormHandler}
					 name="enble_order_limit"
					  defaultValue={this.props.createForm['enble_order_limit']} 
					  placeholder="Enter Order Value" />

				
				   </Form.Group>				  
 
			
				  </div>
				  <div className="form-row">
				   
				   <Form.Group className="col-md-8">
				   <Form.Label>Total Coupons  </Form.Label>
				   <Form.Control type="number" 
				    onChange={this.props.createFormHandler}
					 name="total_coupens"
					  defaultValue={this.props.createForm['total_coupens']} 
					  placeholder="Enter Number of coupons can apply" />

				
				   </Form.Group>				  
 
			
				  </div>
				  <div className="form-row">

				  <Form.Group className="col-md-2">
				  <Form.Check
                                type="checkbox"
                                name="enable_percentage"
                                checked={
                                  this.props.createForm["enable_percentage"]
                                }
                                onClick={this.props.createFormHandler}
                                label="Enable Discount in Precentage"
                              /> </Form.Group>
						 <Form.Group className="col-md-4">
						 <Form.Label>Coupon Discount ({(this.props.createForm["enable_percentage"]==true)?"%":"$"})</Form.Label>

                     <Form.Control type="number"  
					 max={(this.props.createForm["enable_percentage"]==true)?100:1000000}
					 min={0}
					 onChange={this.props.createFormHandler} 
					 name="coupen_discount" 
					 defaultValue={this.props.createForm['coupen_discount']} 
					 placeholder="Enter Discount" />
</Form.Group>


               </div>
		
          </Form>
			  </Modal.Body>

			  <Modal.Footer>
			  <div className="form-row">
			  <Button type='button' onClick={this.props.onHide} variant="outline-primary" className="d-flex w-50 text-center justify-content-center">CANCEL</Button>
			  <Button type='button'  onClick={
					(e)=>{
						if(this.props.addMethod==true){
					this.props.addFoodCoupen(e)
						}
						else{
							this.props.updateFoodCoupen(e)

						}
					}
					
					} variant="primary" className='d-flex w-50 text-center justify-content-center'>ADD</Button>


</div>

			    
			  </Modal.Footer>
			</Modal>
    	);
    }
}
export default CoupenModel;