import React from "react";
import { Link } from "react-router-dom";
import { Image, Card, Media, Button, Badge } from "react-bootstrap";
import Icofont from "react-icofont";
import PropTypes from "prop-types";

class UserCard extends React.Component {
	render() {
		return (
			<Card className="bg-white payments-item mb-4 shadow-sm">
				<div className="gold-members p-4">
					<span className="">
						<Button
							variant="outline-secondary"
							onClick={() =>
								this.props.showUpdateUserModel(this.props.userObj)
							}
							size="sm"
							title="edit"
						>
							<Icofont icon="pencil-alt-1" />
						</Button>

						<Button
							variant="outline-secondary"
							onClick={this.props.onClick}
							size="sm"
							className="ml-2"
							title="delete"
						>
							<Icofont icon="trash" />
						</Button>
					</span>
					<Media>
						<Media.Body>
							<h4 className="mb-1">UserName : {this.props.email}</h4>
							{this.props.userObj.assigned_restaurents &&
								this.props.userObj.assigned_restaurents.length >
									0 && (
									<p>
										<h6>Tagged Restaurents: </h6>
										{this.props.userObj.assigned_restaurents.map(
											(obj) => (
												<Badge color="primary">
													{obj.title}
												</Badge>
											)
										)}
									</p>
								)}{" "}
							<p></p>
						</Media.Body>
					</Media>
				</div>
			</Card>
		);
	}
}

UserCard.propTypes = {
	email: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};
UserCard.defaultProps = {
	email: "",
};

export default UserCard;
