import React from 'react';
import OrderCard from '../common/OrderCard';
import Icofont from 'react-icofont';
import {NavLink} from 'react-router-dom';
import {NavDropdown,Nav,Table, Form,
    InputGroup,Row,Col,
    Button,} from 'react-bootstrap';
import DropDownTitle from '../common/DropDownTitle';
import { DateRangePicker } from 'react-date-range';
import Moment from 'moment';
import DataService from "../../services/DataService";
import ExportReactCSV from '../../components/ExcelExport'
const fileName='out.csv';
const    convert=(str)=> {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}
class Reports extends React.Component {
  constructor(props, context) {
		super(props, context);
    this.cartHandler = new DataService("carts");

    this.state = {
		  selectionRange : {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
      },
    filterForm:{},
		itemsDisplayed: [],
		filter:5,
    selectedRestaurant:this.cartHandler.getSelectedRestaurant()
	  };
	  this.reloadData();
	}

  searchKeywordHandler=(event)=>{

    let keyWord= event.target.value;
    if((keyWord=="")||(keyWord.length==0)||(!keyWord))
    {
      this.reloadData();
    }
    else{
      let itemsDisplayedCopy=[...this.state.itemsDisplayed];
      let searchedData= itemsDisplayedCopy.filter(item=>(((""+item.id).includes(keyWord))||
      (item.customer_name.toLowerCase().includes(keyWord.toLowerCase()))));
      this.setState({itemsDisplayed:searchedData})
    }
  }
  handleSelect=(ranges)=>{
 ;
    if(ranges){
      var opening_date=convert(ranges.selection.startDate);
      var closing_date=convert(ranges.selection.endDate);
      let inputParams={...this.state.filterForm};
      inputParams['opening_date']=opening_date;
      inputParams['closing_date']=closing_date;

      this.setState({
        selectionRange : {
          startDate: ranges.selection.startDate,
          endDate: ranges.selection.endDate,
          key: "selection"
        },
        filterForm:inputParams


      })
    

    }
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  }
    /*
    fetch elements from server and reload data elements
    */
    unSetDeliveryStatus=()=>
    {
      let inputParams={...this.state.filterForm};
      if(inputParams['delivery_status'])
      {
        delete inputParams['delivery_status'];
      }
  
      this.setState({
    
        filterForm:inputParams
  
  
      });
  
    }
  setDeliveryStatus=(val)=>
  {
    let inputParams={...this.state.filterForm};
    inputParams['delivery_status']=val;

    this.setState({
  
      filterForm:inputParams


    });

  }
	reloadData = () => {
		let inputParams = {
      delivery_only:false,
      ...this.state.filterForm};

    console.log(inputParams);
		if (this.state.selectedRestaurant!=0)
      {
         inputParams={rest_id:this.state.selectedRestaurant,...inputParams}
      }
		
		this.cartHandler.getCartReports(inputParams).then((response) => {
		  let currentData = response.data.data;
		  this.setState({ itemsDisplayed: currentData });
		});
	  };
	render() {
        const selectionRange = {
            startDate: (new Date()-30),
            endDate: new Date(),
            key: 'selection',
          }
    	return (
    		<>
    		    <div className='p-4 bg-white shadow-sm'>
			         <Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
						 <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="">
						 <h4 className="font-weight-bold mt-0 mb-4"> All Orders </h4><span className="sr-only">(current)</span>
			            </Nav.Link>


						<NavDropdown alignRight
			            	title={
			            		<DropDownTitle
			            			className='d-inline-block mt-2'
			            			image="img/filter.png"
			            			imageAlt='user'
			            			imageClass="nav-osahan-pic rounded-pill"
			            			title='Filter By '
			            		/>
			            	}
			            	>
							<NavDropdown.Item  onClick={()=>{
this.unSetDeliveryStatus();

							}} activeclassname="active" to="/myaccount/orders"><Icofont icon='food-cart'/> All</NavDropdown.Item>
							<NavDropdown.Item onClick={()=>{
								
								this.setDeliveryStatus(0);}} activeclassname="active" to="/myaccount/offers"><Icofont icon='trash'/> Waiting</NavDropdown.Item>
							<NavDropdown.Item onClick={()=>{
								
								this.setDeliveryStatus(1);}} activeclassname="active" to="/myaccount/favourites"><Icofont icon='ui-check'/>Accepted & Ongoing</NavDropdown.Item>
							<NavDropdown.Item onClick={()=>{
								
								this.setDeliveryStatus(2);}} activeclassname="active" to="/myaccount/payments"><Icofont icon='ui-clip-board'/>  Accepted & Completed</NavDropdown.Item>
							<NavDropdown.Item onClick={()=>{
								
								this.setDeliveryStatus(3);}} activeclassname="active" to="/myaccount/addresses"><Icofont icon='logout'/> Request for Delivery</NavDropdown.Item>
							<NavDropdown.Item onClick={()=>{
								
								this.setDeliveryStatus(4);}} activeclassname="active" to="/myaccount/addresses"><Icofont icon='logout'/> Rejected</NavDropdown.Item>        </NavDropdown>
                        <Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
                     <NavDropdown alignRight
			            	title={
			            		<DropDownTitle
			            			className=' mt-2'
			            			
			            			title='Date Range '
			            		/>
			            	}
			            	>
                                <DateRangePicker
                        ranges={[this.state.selectionRange]}
                        editableDateInputs={true}
                        onChange={this.handleSelect}
                    />
                     </NavDropdown>

                    </Nav>

                    </Nav>
                    



<Row>
                    <Col  md={7}>
                      </Col>
                      <Col  md={2}>
                      <ExportReactCSV csvData={this.state.itemsDisplayed} fileName={fileName} />

                      </Col>
                      <Col  md={3}>
                      <Button className="btn btn-sm 
                        text-white font-weight-bold mb-2" 
                        onClick={
                          ()=>{
                          this.reloadData();
                        }} >Generate Report</Button>
                      </Col>
                    </Row>

                    <Form className="explore-outlets-search mb-4">
                          <InputGroup>
                            <Form.Control
                              type="text"
                              onChange={this.searchKeywordHandler}
                              placeholder="Search..."
                            />
                            <InputGroup.Append>
                              <Button type="button" variant="link">
                                <Icofont icon="search" />
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </Form>
                   
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>OrderID</th>

                            <th>Date</th>
                            <th>Customer</th>
           

                            <th>Delivery Date</th>
                            <th>Order Mode</th>
                            <th>Tax</th>
                            <th>Tip</th>

                            <th>Total</th>
                            <th>Share</th>
                            <th>Status</th>
                            </tr>
                        </thead>
                        
                        <tbody>
                          {this.state.itemsDisplayed.map((orderObj,idx)=>{
                            return <tr>
                            <td>{idx+1}</td>
                            <td>{orderObj.id}</td>
                            <td>{orderObj.published_date}</td>
                            <td>{orderObj.customer_name}</td>
                      

                            <td>{orderObj.ordered_date}</td>
                            <td>{orderObj.order_mode}</td>
                            <td>{orderObj.total_tax}</td>
                            <td>{orderObj.tip}</td>

                            <td>{orderObj.total_amount}</td>
                            <td>{orderObj.seller_amount}</td>
                            <td>{orderObj.delivery_status}</td>
                            </tr>
                          })}
                           
                        </tbody>
                        </Table> 
                            
			    </div>
		    </>
    	);
    }
}
export default Reports;