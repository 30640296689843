import React from 'react';
import {Modal,Button,ButtonGroup} from 'react-bootstrap';
import { GoogleMap, Polygon} from '@react-google-maps/api';

class DrawDeliveryZoneModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            polygonPath: []
        };
        this.polygonRef = null;
    }
    
	onLoadGoogleMap = (poly) =>{ this.polygonRef = poly };

	onChangePolygon = () =>{
		if (this.polygonRef) {
			const newPath = this.polygonRef.getPath().getArray().map((latLng) =>{
				return {lat:latLng.lat(), lng:latLng.lng()};
			});
			this.setState({ polygonPath: newPath});
		}
	}

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} 
            centered style={{width:'90%'}} onEnter={ () => this.setState({polygonPath:this.props.polygonPath})}>
                <Modal.Header closeButton={true}>
                    <Modal.Title as='h5'>Draw Delivery Zone</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="delivery-zone-map" style={{textAlign:'center',height:'100vh',fontFamily:'sans-serif',width:'100%',marginTop:'12px'}}>
                        {/* <LoadScript id="script-loader" googleMapsApiKey="" language="en" region="us" libraries={["geometry"]}> */}
                        <GoogleMap mapContainerClassName="google-map-display" center={this.props.polygonPath[0]} 
                        zoom={16} version="weekly" mapContainerStyle={{height:'100%',width:'100%'}}>
                            <Polygon editable draggable path={this.props.polygonPath} onLoad={this.onLoadGoogleMap} onMouseUp={this.onChangePolygon} 
                            onDragEnd={this.onChangePolygon}
                            />
		                    {/* <button type='button' style={{height:'30px',width:'200px',position:'absolute',top:'20px'}} onClick={this.updateZonePath}>
			                    Update Zone Path
		                    </button> */}
	                    </GoogleMap>
                        {/* </LoadScript> */}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <ButtonGroup>
                    <Button type='button' onClick={this.props.onHide} variant="outline-primary" >
                        Cancel
                    </Button>
                    <Button type='button' onClick={() => {this.props.onUpdate(this.state.polygonPath)}} variant="primary" >
                        Update Zone
                    </Button>
                    </ButtonGroup>
                </Modal.Footer>
            </Modal>
        );
    }
}
export default DrawDeliveryZoneModal;