import React from "react";
import {
	Row,
	Col,
	Form,
	Table,
	InputGroup,
	ToggleButton,
	ToggleButtonGroup,
	Tab,
	Button,
	Spinner,
} from "react-bootstrap";
import CategoryCard from "../common/CategoryCard";
import CategoryModal from "../modals/CategoryModal";
import DeleteAddressModal from "../modals/DeleteAddressModal";
import Icofont from "react-icofont";
import MenuDayItem from "../common/MenuDayItem";
import { NavLink, Link } from "react-router-dom";
import { NavDropdown, Nav } from "react-bootstrap";
import DropDownTitle from "../common/DropDownTitle";
import { DragSwitch } from "react-dragswitch";
import DataService from "../../services/DataService";
import { alertCreation, alertError } from "../Alerts";
import { getDayIndex, to12HourFormat } from "../../services/DataFormatHandler";
class MenuDay extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.dataHandler = new DataService("restaurant_food_category");
		this.dayHandler = new DataService("days");
		this.state = {
			showDeleteModal: false,
			showAddCatgoery: false,
			itemsDisplayed: [],
			daysData: [],
			daysDataOfTakeout: [],
			selectedRestaurant: this.dataHandler.getSelectedRestaurant(),

			daysDataOfDelivery: [],
		};

		this.reloadData();
		this.reloadDays();
		this.reloadData = this.reloadData.bind(this);
		this.reloadDays = this.reloadDays.bind(this);
	}
	setDailyShedules(timeShedule) {
		let daysDataOfTakeout = timeShedule.filter((item) => item.order_mode === 1);
		let daysDataOfDelivery = timeShedule.filter(
			(item) => item.order_mode === 0
		);
		//timeShedule.sort((day1, day2) => (getDayIndex(day1['day_title']) > getDayIndex(day2['day_title'])) ? 1 : -1)
		this.setState({
			daysData: timeShedule,
			daysDataOfTakeout: daysDataOfTakeout,
			daysDataOfDelivery: daysDataOfDelivery,
		});
	}

	/*
    fetch elements from server and reload data elements
    */
	reloadDays = () => {
		let inputParams = {};
		console.log("currentSelection", this.state.selectedRestaurant);
		if (this.state.selectedRestaurant != 0) {
			inputParams = { rest_id: this.state.selectedRestaurant };
		}

		this.dayHandler.getData(inputParams).then((response) => {
			let currentData = response.data.data;
			this.setDailyShedules(currentData);
			//this.setState({ daysData: currentData });
		});
	};

	/*
    fetch elements from server and reload data elements
    */
	reloadData = () => {
		let inputParams = {};
		console.log("currentSelection", this.state.selectedRestaurant);
		if (this.state.selectedRestaurant != 0) {
			inputParams = { rest_id: this.state.selectedRestaurant };
		}

		this.dataHandler.getData(inputParams).then((response) => {
			let currentData = response.data.data;
			this.setState({ itemsDisplayed: currentData });
		});
	};
	hideCatogeryNew = () => this.setState({ showAddCatgoery: false });
	hideDeleteModal = () => this.setState({ showDeleteModal: false });
	render() {
		return (
			<>
				<Tab.Container id="top-tabs" defaultActiveKey={"shedule_takeout"}>
					<div className="p-4 bg-white shadow-sm">
						<Nav variant="pills" className="flex-row">
							<Nav.Link eventKey={"shedule_takeout"}>
								TakeOut
							</Nav.Link>
							{this.state.daysDataOfDelivery.length > 0 && (
								<Nav.Link eventKey={"shedule_delivery"}>
									Delivery
								</Nav.Link>
							)}
						</Nav>
					</div>
					<Tab.Content>
						<Tab.Pane key={1} eventKey={"shedule_takeout"}>
							<div className="p-4 bg-white shadow-sm">
								<Row>
									<div className="bg-white rounded shadow-sm p-4 osahan-payment">
										<h4 className="mb-1">
											TakeOut Weekly Schedule
										</h4>

										<Tab.Container
											id="left-tabs-takeout"
											// defaultActiveKey={this.state.daysData[0]&&("shedule"+this.state.daysData[0]['id'])}
										>
											<Row>
												<Col sm={4} className="pr-0">
													<Nav
														variant="pills"
														className="flex-column"
													>
														{this.state.daysDataOfTakeout.map(
															(dayObj) => {
																// if (dayObj.order_mode === 1) {
																return (
																	<Nav.Link
																		eventKey={
																			"shedule" +
																			dayObj.id
																		}
																	>
																		<Icofont icon="calendar" />
																		{
																			dayObj.day_title
																		}
																		<p>
																			{"(" +
																				to12HourFormat(
																					dayObj.opening_time
																				) +
																				"-" +
																				to12HourFormat(
																					dayObj.closing_time
																				)}
																		</p>
																	</Nav.Link>
																);
																// }
															}
														)}
													</Nav>
												</Col>
												<Col sm={8} className="pl-0">
													<Tab.Content className="h-100">
														{this.state.daysDataOfTakeout.map(
															(dayObj) => {
																// if (dayObj.order_mode === 1) {
																return (
																	<MenuTab
																		dayHandler={
																			this
																				.dayHandler
																		}
																		itemsDisplayed={
																			this
																				.state
																				.itemsDisplayed
																		}
																		dayObj={
																			dayObj
																		}
																		selectedRestaurant={
																			this
																				.state
																				.selectedRestaurant
																		}
																		isInMenu={
																			this
																				.isInMenu
																		}
																		reloadData={
																			this
																				.reloadData
																		}
																		reloadDays={
																			this
																				.reloadDays
																		}
																	></MenuTab>
																);
																// }
																// return (
																//   <Nav.Link eventKey={dayObj.day_title}>
																//     <Icofont icon="calendar" /> {dayObj.day_title}
																//   </Nav.Link>
																// );
															}
														)}
													</Tab.Content>
												</Col>
											</Row>
										</Tab.Container>
									</div>
								</Row>
							</div>
						</Tab.Pane>
						<Tab.Pane key={1} eventKey={"shedule_delivery"}>
							<div className="p-4 bg-white shadow-sm">
								<Row>
									<div className="bg-white rounded shadow-sm p-4 osahan-payment">
										<h4 className="mb-1">
											Delivery Weekly Schedule
										</h4>

										<Tab.Container
											id="left-tabs-delivery"
											// defaultActiveKey={this.state.daysData[0]&&("shedule"+this.state.daysData[0]['id'])}
										>
											<Row>
												<Col sm={4} className="pr-0">
													<Nav
														variant="pills"
														className="flex-column"
													>
														{this.state.daysDataOfDelivery.map(
															(dayObj) => {
																// if (dayObj.order_mode === 0) {
																return (
																	<Nav.Link
																		eventKey={
																			"shedule" +
																			dayObj.id
																		}
																	>
																		<Icofont icon="calendar" />
																		{
																			dayObj.day_title
																		}
																		<p>
																			{"(" +
																				to12HourFormat(
																					dayObj.opening_time
																				) +
																				"-" +
																				to12HourFormat(
																					dayObj.closing_time
																				)}
																		</p>
																	</Nav.Link>
																);
																// }
															}
														)}
													</Nav>
												</Col>
												<Col sm={8} className="pl-0">
													<Tab.Content className="h-100">
														{this.state.daysDataOfDelivery.map(
															(dayObj) => {
																// if (dayObj.order_mode === 0) {
																return (
																	<MenuTab
																		dayHandler={
																			this
																				.dayHandler
																		}
																		itemsDisplayed={
																			this
																				.state
																				.itemsDisplayed
																		}
																		dayObj={
																			dayObj
																		}
																		selectedRestaurant={
																			this
																				.state
																				.selectedRestaurant
																		}
																		isInMenu={
																			this
																				.isInMenu
																		}
																		reloadData={
																			this
																				.reloadData
																		}
																		reloadDays={
																			this
																				.reloadDays
																		}
																	></MenuTab>
																);
																// }
																// return (
																//   <Nav.Link eventKey={dayObj.day_title}>
																//     <Icofont icon="calendar" /> {dayObj.day_title}
																//   </Nav.Link>
																// );
															}
														)}
													</Tab.Content>
												</Col>
											</Row>
										</Tab.Container>
									</div>
								</Row>
							</div>
						</Tab.Pane>
					</Tab.Content>
				</Tab.Container>
			</>
		);
	}
}

class MenuTab extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			showDeleteModal: false,
			showAddCatgoery: false,
			key: 1,
		};
		this.setState({ selectedDay: this.props.dayObj });

		this.updateMenu = this.updateMenu.bind(this);
		this.isInMenu = this.isInMenu.bind(this);
	}

	isInMenu(foodId) {
		const DELETE = 1;
		const LIVE = 0;
		let availableMenu = this.props.dayObj.available_menu;
		let status = false;
		const found = availableMenu.find((element) => element.item === foodId);
		console.log(found);
		if (typeof found != "undefined") {
			status = found.delete_status == LIVE;
		}

		return status;
	}

	updateAllMenu(deleteStatus) {
		let dayObject = this.props.dayObj;

		let inputParms = {
			day: dayObject.id,
			delete_status: deleteStatus,
		};

		if (this.props.selectedRestaurant != 0) {
			inputParms = { rest_id: this.props.selectedRestaurant, ...inputParms };
		}

		this.props.dayHandler
			.addMenuItem(inputParms)
			.then((response) => {
				if (response.data["status"] === "Sucess") {
					window.location.reload();
					this.setState({ key: Math.random() });
				} else {
					alertError(response.data["message"]);
				}
			})
			.catch((err) => alert(err));
	}
	updateMenu(foodItem, deleteStatus) {
		let dayObject = this.props.dayObj;
		let inputParms = {
			day: dayObject.id,
			delete_status: deleteStatus,
			item: foodItem,
		};
		if (this.props.selectedRestaurant != 0) {
			inputParms = { rest_id: this.props.selectedRestaurant, ...inputParms };
		}

		this.props.dayHandler
			.addMenuItem(inputParms)
			.then((response) => {
				if (response.data["status"] === "Sucess") {
					this.props.reloadData();
				} else {
					alertError(response.data["message"]);
				}
			})
			.catch((err) => alert(err));
	}

	render() {
		return (
			<Tab.Pane
				key={this.state.key}
				eventKey={"shedule" + this.props.dayObj.id}
			>
				<h5 className="mb-4 mt-3 col-md-12">
					<small className="h6 text-black-50">
						<ToggleButtonGroup
							className="d-flex w-100"
							type="radio"
							name="enable"
							onChange={(e) => {
								this.updateAllMenu(e);
							}}
							defaultValue={2}
						>
							<ToggleButton variant="primary" value={0}>
								Enable All Items
							</ToggleButton>
							<ToggleButton variant="danger" value={1}>
								Disable All items
							</ToggleButton>
						</ToggleButtonGroup>
					</small>
				</h5>

				{this.props.itemsDisplayed.map((dataObj, idx) => {
					if (
						typeof dataObj.foods_displayed_in_menu != "undefined" &&
						dataObj.foods_displayed_in_menu.length > 0
					)
						return (
							<Row>
								<h5 className="mb-4 mt-3 col-md-12">
									{dataObj.title}{" "}
									<small className="h6 text-black-50">
										{typeof dataObj.foods_displayed_in_menu !=
											"undefined" &&
											dataObj.foods_displayed_in_menu
												.length}{" "}
										Items
									</small>
								</h5>
								<Col md={12}>
									<div className="bg-white rounded border shadow-sm mb-4">
										{typeof dataObj.foods_displayed_in_menu !=
											"undefined" &&
											dataObj.foods_displayed_in_menu.map(
												(foodObj, idx) => (
													<MenuDayItem
														id={foodObj.id}
														title={foodObj.title}
														updateMenu={this.updateMenu}
														checked={this.isInMenu(
															foodObj.id
														)}
														price={foodObj.price}
														showBadge={true}
														badgeText="BEST SELLER"
														qty={1}
														priceUnit="$"
														getValue={3}
													/>
												)
											)}
									</div>
								</Col>
							</Row>
						);
				})}
			</Tab.Pane>
		);
	}
}
export default MenuDay;
