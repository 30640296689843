import React from 'react';
import {Row,Col} from 'react-bootstrap';
import CategoryCard from '../common/CategoryCard';
import CategoryModal from '../modals/CategoryModal';
import DeleteAddressModal from '../modals/DeleteAddressModal';
import Icofont from 'react-icofont';
import {NavLink,Link} from 'react-router-dom';
import {NavDropdown,Nav} from 'react-bootstrap';
import DropDownTitle from '../common/DropDownTitle';
import DataService from '../../services/DataService';
import {confirmDeleteAction,alertError,alertWarning,alertCreation,alertDeletion} from '../../components/Alerts';
import SimpleReactValidator from 'simple-react-validator';
import { data } from 'jquery';

class Categorymenu extends React.Component {
	constructor(props, context) {
	    super(props, context);
		this.dataHandler=new DataService("restaurant_food_category");
		this.validator = new SimpleReactValidator({autoForceUpdate: this});
		this.selectedRestaurant=  this.dataHandler.getSelectedRestaurant()
	    this.state = {
		itemsDisplayed:[],
		createForm:{},
		selectedItem:{},
		validator:this.validator,
		addMethod:true,	
		selectedRestaurant:this.dataHandler.getSelectedRestaurant(),

	    showDeleteModal: false,
		showAddCatgoery: false,
	    };
		this.reloadData();
		this.createFormHandler=this.createFormHandler.bind(this);
		this.showUpdateCategoryModel=this.showUpdateCategoryModel.bind(this);
		this.addFoodCategory=this.addFoodCategory.bind(this);
		this.deleteConfirm=this.deleteConfirm.bind(this);
		this.updateFoodCategory=this.updateFoodCategory.bind(this);
	}
	deleteConfirm = (id,lengthItems) => {

		if(lengthItems==0){
        confirmDeleteAction('Category').then((userResponse) => {
            if (userResponse.isConfirmed == true) {
                this.deleteCategory(id);
            }
        });
	}else{
		alertError("Please remove all items  tagged with this categery");
	}

    };

    deleteCategory = (id) => {
        let inputParams = {};
        inputParams['id'] = id;
        this.dataHandler.deleteData(inputParams)
            .then((response) => {
				console.log(response);

                if (response.data['status'] === 'sucess') {
                    alertDeletion('Category');
                    this.reloadData();
                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));
    };
	
	createFormHandler = (event) => {
        let key = '';
        let value = '';

        let inputForm = {...this.state.createForm};

        if((event.target.type == 'text')||(event.target.type == 'number')) {
            key = event.target.name;
            value = event.target.value;
        }else if (event.target.type == 'file') {
            key = event.target.name;
            value = event.target.files[0];
        }
    
                inputForm[key] = value;
                this.setState({ createForm: inputForm });
            
        
    };


	updateFoodCategory = (e) => {
		e.target.setAttribute("disabled", "disabled");
		if((this.validator.allValid())==true)
        {
        let inputParams = this.state.createForm;
     

		this.dataHandler.updateData(inputParams,this.state.selectedItem.id)
            .then((response) => {
                if (response.data['status'] === 'Sucess') {
                    alertCreation('Category');
					this.setState({createForm:{}})
					this.hideCatogeryNew();
					this.reloadData();

                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));

        
		}
		else
		{
			this.validator.showMessages();

		}
    };



	addFoodCategory = (e) => {
		e.target.setAttribute("disabled", "disabled");
		if((this.validator.allValid())==true)
        {
        let inputParams = this.state.createForm;
		console.log('currentSelection',this.state.selectedRestaurant)
		if (this.state.selectedRestaurant!=0)
		{
		   inputParams={rest_id:this.state.selectedRestaurant,...inputParams,}
		}
        let formData = new FormData(); //formdata object
        Object.keys(inputParams).forEach(function (key) {
            formData.append(key, inputParams[key]); //append the values with key, value pair
        });

        this.dataHandler.createDataFromForm(formData)
            .then((response) => {
                if (response.data['status'] === 'Sucess') {
                    alertCreation('Category');
					this.hideCatogeryNew();
					this.reloadData();

                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));

        
		}
		else
		{
			this.validator.showMessages();

		}
    };










	comparePriority( a, b ) {
		if ( a.priority < b.priority ){
		  return -1;
		}
		if ( a.priority > b.priority ){
		  return 1;
		}
		return 0;
	  }


	  showUpdateCategoryModel=(catObj)=>
	  { 
		let inputParams={'title':catObj.title,'priority':catObj.priority};		
		this.setState({ selectedItem:catObj,addMethod:false,showAddCatgoery: true,createForm:inputParams });
	  }
	  

    /*
    fetch elements from server and reload data elements
    */
    reloadData = () => {
        let inputParams = {};
		console.log('currentSelection',this.state.selectedRestaurant)
		if (this.state.selectedRestaurant!=0)
		{
		   inputParams={rest_id:this.state.selectedRestaurant}
		}
        this.dataHandler.getData(inputParams).then((response) => {
            let currentData = response.data.data;
			console.log(currentData);
            this.setState({itemsDisplayed:currentData,addMethod:true});
        });
    };

	hideCatogeryNew = () => this.setState({ showAddCatgoery: false });
    hideDeleteModal = () => this.setState({ showDeleteModal: false });
	render() {
    	return (
    		<>
				<CategoryModal show={this.state.showAddCatgoery} createFormHandler={this.createFormHandler}
				 createForm={this.state.createForm}
				 addFoodCategory={this.addFoodCategory} 
				 updateFoodCategory={this.updateFoodCategory}
				 addMethod={this.state.addMethod}
				 validator={this.state.validator} 
				  onHide={this.hideCatogeryNew}/>
        		<DeleteAddressModal show={this.state.showDeleteModal} onHide={this.hideDeleteModal}/>
    		    <div className='p-4 bg-white shadow-sm'>
	              <Row>
	               <Col md={12}>
					<Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
						<Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="">
							<h4 className="font-weight-bold mt-0 mb-4">  Category List </h4><span className="sr-only">(current)</span>
						</Nav.Link>

							<Link to='#' onClick={() => this.setState({ showAddCatgoery: true })} className="text-primary mr-3 mt-3"><i className="icofont-plus-circle"></i> NEW CATEGORY</Link>
					</Nav>
	               </Col>

				   {this.state.itemsDisplayed.sort(this.comparePriority).map((dataObj,idx)=>
	               <Col md={6}>
	               	<CategoryCard
	               		title= {dataObj.title}
						catObj={dataObj}
						priority={dataObj.priority}
						showUpdateCategoryModel={this.showUpdateCategoryModel}
						imageclassName= 'mr-3'
						subTitle= {dataObj.foods_tagged_with.length+' ITEMS'}
						onClick={() => {
							this.deleteConfirm(dataObj.id,(dataObj.foods_tagged_with.length+
								dataObj.foods_displayed_in_menu));
						}}
	               	/>
	               </Col>
				   )}
	               
	            </Row>
			    </div>
		    </>
    	);
    }
}
export default Categorymenu;