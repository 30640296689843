import React from 'react';
import PropTypes from 'prop-types'; 
import Icofont from 'react-icofont';

class CartDropdownItem extends React.Component {
	render() {
		return (
			<p className="mb-2"> 
             	<Icofont icon={this.props.icoIcon} className={"mr-1 " + this.props.iconClass}/> 
             	<b>{this.props.title}</b>
             	<span className="float-right text-secondary">{this.props.price}</span>
               <p className="text-dark">
              
                 {(this.props.item.applied_upcell_items)&&(
                   this.props.item.applied_upcell_items.map((upItem)=>
                 {
                   return upItem.item + "($"+upItem.item_price+") X "+upItem.quantity+" ,";
                 }))
                 
                 }


	                      </p>
                        <p>

                        {(this.props.item.applied_answers)&&(
                   this.props.item.applied_answers.map((upItem)=>
                 {
                   return "|  "+ upItem.question + " ?"+ upItem.answer_title +"($"+upItem.item_price+")"+ "  ";
                 }))
                 
                 }
                        </p>
                        <span>{(this.props.item.suggestion!="No suggestions")&&this.props.item.suggestion}   </span>
            </p>
		);
	}
}

CartDropdownItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  icoIcon: PropTypes.string.isRequired,
  iconclass: PropTypes.string.isRequired
};

CartDropdownItem.defaultProps = {
  title: '',
  price: '',
  icoIcon: '',
  iconclass: '',
}

export default CartDropdownItem;