import React from "react";
import { Switch, Route } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Index from "./components/Index";
import Offers from "./components/Offers";
import MyAccount from "./components/MyAccount";
import List from "./components/List";
import NotFound from "./components/NotFound";
import Thanks from "./components/Thanks";
import Extra from "./components/Extra";
import Login from "./components/Login";
import Register from "./components/Register";
import TrackOrder from "./components/TrackOrder";
import Invoice from "./components/Invoice";
import Checkout from "./components/Checkout";
import Detail from "./components/Detail";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "./App.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
class App extends React.Component {
	render() {
		return (
			<>
				{this.props.location.pathname !== "/" &&
				this.props.location.pathname !== "/register" &&
				this.props.location.pathname !== "/invoice" ? (
					<Header />
				) : (
					""
				)}
				<Switch>
					<Route path="/index" exact component={Index} />
					<Route path="/listing" exact component={List} />
					<Route path="/myaccount" component={MyAccount} />
					<Route path="/track-order" exact component={TrackOrder} />
					<Route path="/404" exact component={NotFound} />
					<Route path="/" exact component={Login} />
					<Route exact component={NotFound} />
				</Switch>
				{this.props.location.pathname !== "/login" &&
				this.props.location.pathname !== "/register" ? (
					<Footer />
				) : (
					""
				)}
			</>
		);
	}
}

export default App;
