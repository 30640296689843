import React from 'react';
import {Link} from 'react-router-dom';
import {Image,Card,Media,Button,Badge} from 'react-bootstrap';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types'; 

class ExtraPaymentCard extends React.Component {
  
	render() {
    	return (
    		<Card className="bg-white payments-item mb-4 shadow-sm">
            	<div className="gold-members p-4">
              <span className="float-right"> 
   
  
   <Button variant='outline-secondary' onClick={() => this.props.showUpdateExtraPaymentModel(this.props.extraPaymentObj)} size="sm" title="edit"><Icofont icon='pencil-alt-1'/></Button>

     
     <Button variant='outline-secondary' onClick={this.props.onClick}  size="sm" className="ml-2" title="delete"><Icofont icon='trash'/></Button>
     
   </span>
                  <Media>
                     <Media.Body>
                        <h4 className="mb-1">Title : {this.props.title}</h4>
	                  
			                	<p>
			                	
			

 
  <h6>
    Value in {(this.props.extraPaymentObj["enable_percentage"]==true)?"%":"$"}:
	<Badge  variant="danger">
    {this.props.extraPaymentObj.value}
	{" "}
	{(this.props.extraPaymentObj["enable_percentage"]==true)?"%":""}
  </Badge>{' '}

  </h6>	
  			
                  
                  </p>
                       
                    </Media.Body>
           		  </Media>
           		</div>
            </Card>
    	);
    }
}

ExtraPaymentCard.propTypes = {
  title: PropTypes.string.isRequired,
  logoImage: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  imageAlt: PropTypes.string,
  imageclassName: PropTypes.string,
  onClick: PropTypes.func.isRequired
};
ExtraPaymentCard.defaultProps = {
  subTitle: '',
  imageAlt: '',
  imageclassName: '',
}

export default ExtraPaymentCard;