import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataService from "../../../services/DataService";
import SimpleReactValidator from "simple-react-validator";
import MinAmountFreeOfferAddModal from "../../modals/MinAmountFreeOfferAddModal";
import {
	alertCreation,
	alertError,
	alertUpdation,
} from "../../../components/Alerts";
import OfferCard from "../../common/OfferCard";

class MinAmountFreeOffer extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.dataHandler = new DataService("offers");
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });

		this.state = {
			validator: this.validator,
			showOfferDeleteModal: false,
			showOfferAddModal: false,
			optionsFood: [],
			selectedRestaurant: this.dataHandler.getSelectedRestaurant(),
			offers: [],
			offerCreateForm: {
				title: "",
				minim_amount_to_be_purchased: 0,
				offer_message: "",
				items: [],
				max_number_of_items_can_be_selected: 0,
				active: true,
				enable_takeout: true,
				enable_delivery: true,
			},
			crudMode: "create",
		};
		this.reloadFoodData();
		this.reloadOffers();
	}

	reloadOffers = () => {
		let inputParams = {};
		if (this.state.selectedRestaurant != 0) {
			inputParams = { rest_id: this.state.selectedRestaurant };
		}
		this.dataHandler.getData(inputParams).then((response) => {
			let currentData = response.data.data;
			this.setState({ offers: currentData });
		});
	};

	reloadFoodData = () => {
		let inputParams = {};
		if (this.state.selectedRestaurant != 0) {
			inputParams = { rest_id: this.state.selectedRestaurant };
		}
		new DataService("restaurant_food_items")
			.getData(inputParams)
			.then((response) => {
				let currentData = response.data.data;
				let abstractData = currentData.map((obj) => ({
					name: obj["title"],
					id: obj.id,
				}));
				this.setState({ optionsFood: abstractData });
			});
	};

	createFormHandler = (event) => {
		let key = "";
		let value = "";
		let inputForm = { ...this.state.offerCreateForm };

		if (
			event.target.type === "text" ||
			event.target.type === "textarea" ||
			event.target.type === "number"
		) {
			key = event.target.name;
			value = event.target.value;
		} else if (event.target.type === "select-one") {
			key = event.target.name;

			value = event.target.value;
		} else if (event.target.type === "checkbox") {
			key = event.target.name;

			value = event.target.checked;
		} else if (event.target.type === "multiple-selection") {
			key = event.target.name;
			value = event.target.items;
		}
		inputForm[key] = value;
		this.setState({ offerCreateForm: inputForm });
	};

	createOffer = () => {
		if (this.validator.allValid() === true) {
			let inputParams = {
				title: this.state.offerCreateForm.title,
				minim_amount_to_be_purchased:
					this.state.offerCreateForm.minim_amount_to_be_purchased,
				offer_message: this.state.offerCreateForm.offer_message,
				items: this.state.offerCreateForm.items,
				max_number_of_items_can_be_selected:
					this.state.offerCreateForm.max_number_of_items_can_be_selected,
				active: this.state.offerCreateForm.active,
				enable_takeout: this.state.offerCreateForm.enable_takeout,
				enable_delivery: this.state.offerCreateForm.enable_delivery,
			};
			if (this.state.selectedRestaurant != 0) {
				inputParams = {
					rest_id: this.state.selectedRestaurant,
					...inputParams,
				};
			}

			this.dataHandler
				.createData(inputParams)
				.then((response) => {
					if (response.data["status"] === "Sucess") {
						alertCreation("Offer Item");
						this.reloadOffers();
						this.hideMinAmountFreeOfferAddModal();
					} else {
						alertError(response.data["message"]);
					}
				})
				.catch((err) => alert(err));
		} else {
			this.validator.showMessages();
		}
	};

	updateOffer = () => {
		if (this.validator.allValid() === true) {
			let inputParams = {
				title: this.state.offerCreateForm.title,
				minim_amount_to_be_purchased:
					this.state.offerCreateForm.minim_amount_to_be_purchased,
				offer_message: this.state.offerCreateForm.offer_message,
				items: this.state.offerCreateForm.items,
				max_number_of_items_can_be_selected:
					this.state.offerCreateForm.max_number_of_items_can_be_selected,
				active: this.state.offerCreateForm.active,
				enable_takeout: this.state.offerCreateForm.enable_takeout,
				enable_delivery: this.state.offerCreateForm.enable_delivery,
			};

			this.dataHandler
				.updateData(inputParams, this.state.offerCreateForm.id)
				.then((response) => {
					if (response.data["status"] === "Success") {
						alertUpdation("Offer Item");
						this.hideMinAmountFreeOfferAddModal();
						this.reloadOffers();
						// this.props.history.push("dishItems");
					} else {
						alertError(response.data["message"]);
					}
				})
				.catch((err) => alert(err));
		} else {
			this.validator.showMessages();
		}
	};

	hideMinAmountFreeOfferAddModal = () => {
		this.setState({
			showOfferAddModal: false,
			offerCreateForm: {
				title: "",
				minim_amount_to_be_purchased: 0,
				offer_message: "",
				items: [],
				max_number_of_items_can_be_selected: 0,
				active: true,
				enable_takeout: true,
				enable_delivery: true,
			},
			crudMode: "create",
		});
	};

	showMinAmountFreeOfferUpdateModal = (offer) => {
		this.setState({
			showOfferAddModal: true,
			offerCreateForm: {
				...offer,
				items: offer.offered_items.map((item) => item.item),
			},
			crudMode: "update",
		});
	};

	render() {
		return (
			<>
				<MinAmountFreeOfferAddModal
					optionsFood={this.state.optionsFood}
					show={this.state.showOfferAddModal}
					validator={this.state.validator}
					onHide={this.hideMinAmountFreeOfferAddModal}
					createFormHandler={this.createFormHandler}
					createForm={this.state.offerCreateForm}
					crudMode={this.state.crudMode}
					createOffer={this.createOffer}
					updateOffer={this.updateOffer}
				/>
				<Row>
					<Col md={12}>
						<Link
							to="#"
							onClick={() =>
								this.setState({ showOfferAddModal: true })
							}
							className="text-primary mr-3 mt-3"
						>
							<i className="icofont-plus-circle"></i> New Offer
						</Link>
					</Col>
				</Row>
				<div className="p-4 bg-white shadow-sm">
					<Row>
						{this.state.offers.map((offer, idx) => (
							<Col md={12}>
								<OfferCard
									offer={offer}
									optionsFood={this.state.optionsFood}
									showMinAmountFreeOfferUpdateModal={
										this.showMinAmountFreeOfferUpdateModal
									}
								/>
							</Col>
						))}
					</Row>
				</div>
			</>
		);
	}
}
export default MinAmountFreeOffer;
