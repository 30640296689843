import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Badge,
  Form,
  InputGroup,
  Button,
  Tab,
  Nav,
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ItemsCarousel from "../../common/ItemsCarousel";
import MutilUpload from '../../common/MultipleImageUploadComponent';
import FileUploader from '../../FileUploader'
import {timeZoneList} from '../../../helpers/globalConstants'
import {timeConvertSeconds,toFormattedDate24} from '../../../services/DataFormatHandler'
import TimePicker from 'react-bootstrap-time-picker';

import AddAddressModal from "../../modals/AddAddressModal";
import FontAwesome from "../../common/FontAwesome";
import Icofont from "react-icofont";
import DataService from '../../../services/DataService'
import { alertCreation,alertError,alertUpdation } from '../../Alerts';
import SearchLocationInput from '../../location/SearchLocationInput';
import SimpleReactValidator from "simple-react-validator";
const time_step=30;
const KeyCodes = {
	comma: 188,
	enter: 13,
  };
//	import { WithContext as ReactTags } from 'react-tag-input';
	

//center:{lat:50.445210,lng: -104.618896}
const weekDays=[ "SUNDAY", "MONDAY",
"TUESDAY",
"WEDNESDAY",
"THURSDAY",
"FRIDAY",
"SATURDAY"];
//	import { WithContext as ReactTags } from 'react-tag-input';

//center:{lat:50.445210,lng: -104.618896}

const delimiters = [KeyCodes.comma, KeyCodes.enter];
class EditFranchaise extends React.Component {
  constructor(props, context) {
    super(props, context);
    let currentData = { ...this.props.location.state.restObj };

	let restaurantName=currentData.restaurant_name;
	let restaurantAddress=currentData.address;
	let logo=currentData.logo;
this.logo=logo;
this.banner=currentData.banner
	let inputParams={}
	inputParams['phone']=currentData.phone;
	inputParams['restaurant_name']=currentData.restaurant_name;
	inputParams['alternative_phone']=currentData.alternative_phone;
	inputParams['address']=currentData.address;
	
	inputParams['delevery_charge']=currentData.delevery_charge;
	inputParams['delevery_free']=currentData.delevery_free;
	inputParams['store_email']=currentData.store_email;
	inputParams['order_notification_email']=currentData.order_notification_email;
	inputParams['avg_preparation_time']=currentData.avg_preparation_time;
	inputParams['tax_enabled']=currentData.tax_enabled

	


	this.state = {
		showAddressModal: false,
		rest_id:currentData.id,
		galleryImages: currentData.images,
		showHideDelivery: false,
		removingImages: [],
		tags: [],
		profile: [],
    dailyShedules:[],

		address: "",
		taxOptions: [],
		createForm: inputParams,
	  };









    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.dataHandler = new DataService("restaurants");
    this.logo = "";
    this.banner = "";
    this.addedGalleryImages = [];
    this.galleryImages = [];
 

    this.taxData = [];
    this.reloadTaxData();

    this.hideDelivery = this.hideDelivery.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
    this.handleMap = this.handleMap.bind(this);

  }
  componentDidMount() { 
    let currentData = { ...this.props.location.state.restObj };

    this.reloadData(currentData);

}
  handleMap(addressObject) {
    let inputForm = { ...this.state.createForm };
    inputForm["address"] = addressObject.formatted_address;
    if (addressObject.geometry) {
      let lat = addressObject.geometry.location.lat();
      let lng = addressObject.geometry.location.lng();
      inputForm["lat"] = lat;
      inputForm["lng"] = lng;
    }
    this.setState({ createForm: inputForm });
  }
  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  setLocation(centerSelected) {
    let oldCenter = { ...this.state.center };
    this.setState({ center: centerSelected });
  }
  handleAddition(tag) {
    this.setState((state) => ({ tags: [...state.tags, tag] }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleTagClick(index) {
    console.log("The tag at index " + index + " was clicked");
  }

  createFormHandler = (event) => {
	let key = '';
	let value = '';
	let inputForm = {...this.state.createForm};

	if ((event.target.type == 'text')|(event.target.type == 'number')|(event.target.type == 'email')||(event.target.type == 'password')||(event.target.type == 'textarea') ){
		key = event.target.name;
		value = event.target.value;
	} else if (event.target.type == 'date') {
		key = event.target.name;
		value = event.target.value;

	}
	else if (event.target.type == 'select') {
		key = event.target.name;
		value = event.target.value;

	}
	else if (event.target.type == 'checkbox') {
		key = event.target.name;
		value = event.target.checked;
	} else if (event.target.type == 'file') {
		key = event.target.name;
		value = event.target.files[0];
	}
	if (key == 'excelFile') {
		this.state.uploadExcelForm[key] = value;
	} else {
		if (key) {
			inputForm[key] = value;
			this.setState({ createForm: inputForm });
		}
	}
};


  handleFiles = (fileList) => {
    console.log(fileList);
    if (fileList) {
      this.galleryImages = fileList;
    }
  };

  onSelectTax = ( selectedItem) => {
		let inputForm = { ...this.state.createForm };
		let tax_id = selectedItem.target.value;
		inputForm["appliicable_tax"] = tax_id;
		this.setState({ createForm: inputForm });
	  };

 
  /*
    fetch elements from server and reload data elements
    */
  reloadTaxData = () => {
    let inputParams = {};
    new DataService("tax").getData(inputParams).then((response) => {
      let abstractData = response.data.map((obj) => ({
        name: obj["title"],
        id: obj.id,
      }));
      console.log(abstractData);
      this.setState({ taxOptions: abstractData });
    });
  };
  /*
    fetch elements from server and reload data elements
    */
  reloadCategoryData = () => {
    let inputParams = {};
    new DataService("restaurant_food_category")
      .getData(inputParams)
      .then((response) => {
        let abstractData = response.data.data.map((obj) => ({
          name: obj["title"],
          id: obj.id,
        }));
        this.setState({ taxOptions: abstractData });
      });
  };


  updateProfile = () => {
		if((this.validator.allValid())==true)
        {
        let inputParams = this.state.createForm;
		console.log(inputParams);

		if((!inputParams['order_notification_email']) ||(inputParams['order_notification_email'].length==0))
{
	inputParams['order_notification_email']=inputParams['email'];
}
if((!inputParams['store_email'])||(inputParams['store_email'].length==0))
{
	inputParams['store_email']=inputParams['email'];
}
        let formData = new FormData(); //formdata object
        Object.keys(inputParams).forEach(function (key) {
            formData.append(key, inputParams[key]); //append the values with key, value pair
        });
		for (let i = 0; i < this.galleryImages.length; i++) {
            formData.append('gallery_images', this.galleryImages[i]);
        }
		if(this.state.removingImages.length>0){

			let removingImagesString=JSON.stringify(this.state.removingImages);
			formData.append('removing_images',removingImagesString);
		}

		if(this.state.dailyShedules){
			let dailyShedulesObj=[...this.state.dailyShedules];
			//dailyShedulesObj=convertTimeDicToUTC(dailyShedulesObj);
			let time_sheduleString=JSON.stringify(dailyShedulesObj);
			formData.append('time_shedule',time_sheduleString);
		}

        this.dataHandler. updateDataFromForm(formData,this.state.rest_id)
            .then((response) => {
                if (response.data['status'] === 'Sucess') {
                    alertCreation('Restaurant');

                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));

        
		}
		else
		{
			this.validator.showMessages();

		}
    };


  deleteImage = (imageId) => {
    let currentAddedImages = this.state.galleryImages.filter((imgObj) => {
      return imgObj.id != imageId;
    });

    this.setState({ galleryImages: currentAddedImages });
    let removingImages = [...this.state.removingImages];
    removingImages.push(imageId);
    this.setState({ removingImages: removingImages });
  };

  /*
    fetch elements from server and reload data elements
    */
  reloadData = (currentData) => {

  
    console.log(currentData);
    let restaurantName = currentData.restaurant_name;
    let restaurantAddress = currentData.address;
    let logo = currentData.logo;
    this.setData(currentData);
  };

  setData = (currentData) => {
      console.log("called");
		let restaurantName=currentData.restaurant_name;
		let restaurantAddress=currentData.address;
		let logo=currentData.logo;
this.logo=logo;
this.banner=currentData.banner
console.log(currentData);
		console.log(currentData.appliicable_tax)
		let inputParams={}

		inputParams['phone']=currentData.phone;
		inputParams['restaurant_name']=currentData.restaurant_name;
		inputParams['alternative_phone']=currentData.alternative_phone;
		inputParams['address']=currentData.address;
		inputParams['minim_delevery']=currentData.minim_delevery;
		
		inputParams['time_zone']=currentData.time_zone;

		inputParams['delevery_charge']=currentData.delevery_charge;
		inputParams['delevery_free']=currentData.delevery_free;
		inputParams['store_email']=currentData.store_email;
		inputParams['order_notification_email']=currentData.order_notification_email;
		inputParams['avg_preparation_time']=currentData.avg_preparation_time;
		inputParams['tax_enabled']=currentData.tax_enabled;	
		inputParams['is_multiple_franchaise_enabled']=currentData.is_multiple_franchaise_enabled
		inputParams['payment_provided_url']=currentData.payment_provided_url;	
		inputParams['payment_app_id']=currentData.payment_app_id;	
		inputParams['hpp_key']=currentData.hpp_key;	
		inputParams['pst_number']=currentData.pst_number;	
		inputParams['gst_number']=currentData.gst_number;	
		if(inputParams['tax_enabled']===true)
		{
      if((currentData.appliicable_tax)&&(currentData.appliicable_tax!=null)){
			inputParams['appliicable_tax']=currentData.appliicable_tax['id']
      }
		}
    let timeShedule=timeConvertSeconds(currentData.available_days);
    console.log("setting staed")
    this.setState({createForm:inputParams,
      galleryImages:currentData.images,
      rest_id:currentData.id,
      dailyShedules:timeShedule
    
    
    });
    
    
	

};

setDailyShedules(timeShedule)
{
	//timeShedule.sort((day1, day2) => this.compareDicValues(day1,day2))
	 this.setState({ });
}

   /*

handles event of product partb select box

    */
    handleDayChange = (index,selectedItem) => {
		let day = selectedItem.target.value;

        let dayValue = day;
        let keyName="day";
        let list = [...this.state.dailyShedules];

            list[index][keyName] = dayValue;

        
        console.log(list);
        this.setState({ dailyShedules: list });
    };




	dayTimeSheduleChange(index,time,shedule)
	{

		
        let list = [...this.state.dailyShedules];
        list[index][shedule] = time;
			// if(shedule==='opening_time')
			// {
			// 	list[index]['closing_time']=time;
			// }
		if (shedule === 'opening_time' && time > list[index]['closing_time']) {
			// list[index]['closing_time'] = time;
			list[index]['closing_time'] = time;
		}
		else if (shedule === 'closing_time' && time < list[index]['opening_time']) {
			list[index]['opening_time'] = time;
		}
        
        this.setState({ dailyShedules: list });

	}










   //addd Return Methods

 // handle click event of the Remove button
 handleRemoveShedule = index => {
      
	let list = [...this.state.dailyShedules];
	if(list[index]['add']){
	list.splice(index, 1);
	}else{
		list[index]['remove']=true;
	}
	this.setState({ dailyShedules: list });
    
  }

  // handle click event of the Add button
    handleAddShedule = () => {
        let dayList=[...this.state.dailyShedules];
        dayList.push({ day: 'SUNDAY', opening_time: 0 , closing_time: 0,add:true});
        this.setState({dailyShedules:dayList});    
  };

  handleTimeZone=(selectedItem)=>
  {
	let value = selectedItem.target.value;

	let inputForm = {...this.state.createForm};
	inputForm['time_zone'] = value;
	this.setState({ createForm: inputForm });
  }

  hideAddressModal = () => this.setState({ showAddressModal: false });

  hideDelivery(name) {
    this.setState({ showHideDelivery: !this.state.showHideDelivery });
  }

  render() {
    console.log(this.state.createForm);
    return (
      <section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
        <AddAddressModal
          show={this.state.showAddressModal}
          onHide={this.hideAddressModal}
        />

<Container>
	            <Row>
			
	               <Col md={12}>
					   <div className="offer-dedicated-body-left">

						 <div className="pt-2"></div>

						 <span className="hidden" >{/*this.props.shouldHide ? 'hidden' : ''*/}
							<div className="bg-white rounded shadow-sm p-4 mb-4" >
								<h4 className="mb-1">Edit Restaurant Profile</h4>
								<h6 className="mb-3 text-black-50">provide Account Related Info</h6>
								<Row>
								<Col md={12}>

									<Form>
										<div className="form-row">
                                           

											
							

										    

											<Form.Group className="col-md-12">
											<Form.Label>Restaurant Name</Form.Label>
											{this.validator.message('restaurant_name', this.state.createForm['restaurant_name'], 'required',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="text" onChange={this.createFormHandler}  value={this.state.createForm['restaurant_name']} name="restaurant_name" placeholder="Restaurant Name" />

											</InputGroup>
											</Form.Group>
											<Form.Group controlId="formBasicCheckbox">
    <Form.Check type="checkbox" name="is_multiple_franchaise_enabled"
	 checked={this.state.createForm['is_multiple_franchaise_enabled']}
	 onClick={this.createFormHandler}
	label="is multiple franchise enabled" />
  </Form.Group>

											<Form.Group className="col-md-12">
											<Form.Label>Contact Number1</Form.Label>
											{this.validator.message('phone', this.state.createForm['phone'], 'required|phone',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="text" onChange={this.createFormHandler} value={this.state.createForm['phone']} name="phone" placeholder="Landline/Mobile Number" />

											</InputGroup>
											</Form.Group>


		
                                            <Form.Group className="col-md-12">
											<Form.Label>Contact Number2</Form.Label>
											{this.validator.message('alternative_phone', this.state.createForm['alternative_phone'], 'phone',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="text" onChange={this.createFormHandler} value={this.state.createForm['alternative_phone']} name="alternative_phone" placeholder="Alternative Number" />

											</InputGroup>
											</Form.Group>
										
                                            
											
											<Form.Group className="col-md-12">
											<Form.Label>Store Email</Form.Label>
											{this.validator.message('store_email', this.state.createForm['store_email'], 'required|email',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="email"  value={this.state.createForm['store_email']} name="store_email" onChange={this.createFormHandler} placeholder="Valid Email Id" />

											</InputGroup>
											</Form.Group>
											<Form.Group className="col-md-12">
											<Form.Label>Notification Email</Form.Label>
											{this.validator.message('order_notification_email', this.state.createForm['order_notification_email'], 'required|email',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="email" value={this.state.createForm['order_notification_email']} name="order_notification_email" onChange={this.createFormHandler} placeholder="Valid Email Id" />

											</InputGroup>
											</Form.Group>



											<SearchLocationInput
											setBack={this.handleMap}
										address={this.state.createForm['address']}     
    />
											
									
                                            <Form.Group className="col-md-12">
											<Form.Label>Average Preparation Time</Form.Label>
											<Form.Control type="text" onChange={this.createFormHandler} value={this.state.createForm['avg_preparation_time']}  name="avg_preparation_time" placeholder=" e.g. 20 Min-40 Min" />
											</Form.Group>
											<Form.Group controlId="formBasicCheckbox">
    <Form.Check type="checkbox" name="tax_enabled"
	 checked={this.state.createForm['tax_enabled']}
	 onClick={this.createFormHandler}
	label="tax applicable" />
  </Form.Group>
  
										   <Form.Group className="col-md-12" hidden={!this.state.createForm['tax_enabled']}>
											 
											 <Form.Label> Applicable Tax
											 </Form.Label>

											 <Form.Control as="select"
												 
												 name="appliicable_tax" 
												 onChange={this.onSelectTax} 


												 >
												{(this.state.taxOptions)&&
												this.state.taxOptions.map((taxObj)=>(

<option
selected={((this.state.createForm['appliicable_tax'])==(taxObj.id))?true:false}

value={taxObj.id}>{taxObj.name}</option>

												))}
   </Form.Control>


	                                          </Form.Group>
											  



											

										  <Form.Group className="col-md-12">
	                                             <Form.Label>Provider URL</Form.Label>
	                                             <InputGroup>
	                                                <Form.Control type="payment_provided_url" 
													
													 onChange={this.createFormHandler}
													 name="payment_provided_url"
													 value={this.state.createForm['payment_provided_url']}
													 placeholder="Provider URL" />
	                                                <InputGroup.Append>
	                                                   <Button variant="outline-secondary" type="button" id="button-addon2"><Icofont icon="web" /></Button>
	                                                </InputGroup.Append>
	                                             </InputGroup>
	                                          </Form.Group>

	                                          <Form.Group className="col-md-12">
	                                             <Form.Label>APP ID
	                                             </Form.Label>
	                                             <Form.Control type="text" 
												  name="payment_app_id"
												  value={this.state.createForm['payment_app_id']}
												  onChange={this.createFormHandler}

												 name="payment_app_id" placeholder="APP id" />
	                                          </Form.Group>

											  
	                                          <Form.Group className="col-md-12">
	                                             <Form.Label>HPP KEY
	                                             </Form.Label>
	                                             <Form.Control type="text" 
												  name="hpp_key"
												  onChange={this.createFormHandler}

												  value={this.state.createForm['hpp_key']}
												 
												 name="hpp_key" placeholder="HPP KEY" />
	                                          </Form.Group>
											  <Form.Group className="col-md-12">
	                                             <Form.Label>GST Number
	                                             </Form.Label>
	                                             <Form.Control type="text" 
												  name="gst_number"
												  onChange={this.createFormHandler}

												  value={this.state.createForm['gst_number']}
												 
												 name="gst_number" placeholder="GST Number" />
	                                          </Form.Group>
											  <Form.Group className="col-md-12">
	                                             <Form.Label>PST Number
	                                             </Form.Label>
	                                             <Form.Control type="text" 
												  name="pst_number"
												  onChange={this.createFormHandler}

												  value={this.state.createForm['pst_number']}
												 
												 placeholder="PST Number" />
	                                          </Form.Group>
											  
											  <Form.Group className="col-md-12">
	                                             <Form.Label>Time Zone
	                                             </Form.Label>
												
												 <Form.Control as="select"
												 
												 name="time_zone" onChange={this.handleTimeZone} 
												 defaultValue={this.state.createForm['time_zone']}


												 >
												{
												timeZoneList.map((timeObj)=>(

<option
selected={(this.state.createForm['time_zone']===timeObj)?true:false}

value={timeObj}>{timeObj}</option>

												))}
   </Form.Control>


	                                          </Form.Group>
											  
											  <Form.Group className="col-md-12">
	                                             <Form.Label> Deliverable Distance(KiloMeters)</Form.Label>
	                                             <InputGroup>
	                                                <Form.Control type="number"  name="minim_delevery"  
													min={0}
													max={500}
													value={this.state.createForm['minim_delevery'] }
													onChange={this.createFormHandler} placeholder="Delivarable Distance" />
	                                                
	                                             </InputGroup>
	                                          </Form.Group>

	                                          <Form.Group className="col-md-12">
	                                             <Form.Label> Delivery Charge</Form.Label>
	                                             <InputGroup>
	                                                <Form.Control type="text"  name="delevery_charge"  value={this.state.createForm['delevery_charge'] }
													onChange={this.createFormHandler} placeholder="delivery amount" />
	                                                
	                                             </InputGroup>
	                                          </Form.Group>

	                                          <Form.Group className="col-md-12">
											 
	                                             <Form.Label> Make Deliver Free After
	                                             </Form.Label>
	                                             <Form.Control type="text" value={this.state.createForm['delevery_free']}  onChange={this.createFormHandler}  
												 
												 
												 name="delevery_free" placeholder="amount" />
	                                          </Form.Group>
											 


										</div>
									</Form>
								</Col>

								</Row>
							</div>
							<div className="pt-2"></div>

							<div className="bg-white rounded shadow-sm p-4 mb-4" >
								<h4>Opening Hours</h4>
								<Row>
								<Col md={4} >
								DAY
								</Col>
								<Col md={3}>
OPENING TIME								
</Col>
								<Col md={3}>
								CLOSING TIME
								</Col>
								<Col></Col>
								<Col><button  className="ml-1" 
			 			  size="sm"
 
			  color="success" onClick={this.handleAddShedule}>+</button></Col>
								</Row>

								<Row>
									<Col md={12}></Col>
									
									</Row>{this.state.dailyShedules.map((sheduleObj,i)=>{
										if(!sheduleObj['remove']){
								return <Row>
								<Col md={4}>
								<Form.Group className="col-md-8">
	                                             

 <Form.Control
                as="select"
                name="day"
				value={sheduleObj['day']}
                onChange={(e)=>{this.handleDayChange(i,e)}}
              >
                {weekDays.map((dayObj) => {

                  return <option  value={dayObj}>{dayObj}</option>;
					
                })}
              </Form.Control>
	                                          </Form.Group>

								</Col>
								<Col md={3}>
								<TimePicker  
								
								step={time_step}  
								onChange={(time)=>{
									this.dayTimeSheduleChange(i,time,'opening_time');
									}} value={sheduleObj['opening_time'] } />								</Col>
								<Col md={3}>
								<TimePicker  step={time_step}
								
								// start={toFormattedDate24(sheduleObj['opening_time'] )}
								onChange={(time)=>{
									this.dayTimeSheduleChange(i,time,'closing_time');
									}} 
									
									value={sheduleObj['closing_time'] } />

								</Col>
								<Col lg={2}>
              {  <button
			  size="sm"
			  className="text-danger"
                onClick={() => {this.handleRemoveShedule(i);
              }}>-</button>}

            

            </Col>
            
								</Row>


	}
	
	
	})}

			


</div>


							<div className="pt-2"></div><div className="bg-white rounded shadow-sm p-4 mb-4" >
<Row>
<Col md={6}>
</Col>
						  <Col md={6}>

						  

<Button  onClick={
	
	this.updateProfile}>Update</Button>	                                          
</Col></Row></div>
						 </span>
					  </div>
	               </Col>

	            </Row>
	         </Container>
      </section>
    );
  }
}

export default EditFranchaise;
