import React from "react";
import { Row, Col, Tab, Tabs, Table, Accordion, Card, Button, Form, Modal } from "react-bootstrap";
import Icofont from "react-icofont";
import DishCustomizationModal from "./modals/DishCustomizationModal";
import DataService from "../services/DataService";
import { alertError } from "./Alerts";

class FoodCustomization extends React.Component {
    constructor (props, context) {
        super(props, context);
        this.state = {
            showDishCustomizationModal: false,
            showAddNewTabChoiceModal: false,
            customizationTabs: this.props.customizationTabs,
            currentEditTab:null,
            customization_templates: [],
            customization_template_groups: []
        }
        this.currentEditTabIndex = null;
        this.reloadCustomizationTemplate();
        this.reloadCustomizationGroups();
    }

    reloadCustomizationTemplate = ()=> {
        let inputParams = {title:true};
        new DataService("dish_customization_templates").getData(inputParams).then((response) => {
            let currentData = response.data.data;
            // let abstractData = currentData.map((obj) => ({
            //     name: obj["title"],
            //     id: obj.id,
            // }));
            this.setState({ customization_templates: currentData });
            // this.setState({ itemsDisplayed: currentData });
        });
    }

    reloadCustomizationGroups = ()=> {
        let inputParams = {};
        new DataService("dish_customization_template_groups").getData(inputParams).then((response) => {
            let currentData = response.data.data;
            // let abstractData = currentData.map((obj) => ({
            //     name: obj["title"],
            //     id: obj.id,
            // }));
            this.setState({ customization_template_groups: currentData });
            // this.setState({ itemsDisplayed: currentData });
        });
    }

    cloneTabFromTemplate = (template,currentId)=> {
        

        template["id"] = currentId;
        template["added"] = true;
        
        if (template["tab_type"]===1) {
            template.upsell_items=template.upsell_items.map(item =>({...item,added:true}))
        }
        else {
            template.custom_questions = template.custom_questions.map( (item) => {
                let question = item;
                question.answers = question.answers.map(item => ({...item,added:true}));
                question["added"] = true;
                return question
            })
        }
        return template;
        // console.log(template)
        
    }

    onSelectCustomizationTemplate = (event)=> {
        if (event.target.value>0) {
            let inputParams = {id:event.target.value};
            new DataService("dish_customization_templates").getData(inputParams).then((response) => {
                let currentData = response.data.data;
                let currentId = 1;
                currentId =
                this.state.customizationTabs.length > 0
                ? this.state.customizationTabs[this.state.customizationTabs.length - 1]
                    .id + 1
                : 1;
                let newTab=this.cloneTabFromTemplate(currentData,currentId);
                let customizationTabs = [...this.state.customizationTabs]
                customizationTabs.push(newTab);
                this.props.updateCustomSuggestionsInEditFoodItem(customizationTabs);
                this.setState({customizationTabs: customizationTabs});
            });
        }
        this.hideAddNewTabChoiceModal()
    }

    onSelectCustomizationTemplateGroup = (event)=> {
        if (event.target.value>0) {
            let inputParams = {groupId:event.target.value};
            new DataService("dish_customization_templates").getData(inputParams).then((response) => {
                let currentData = response.data.data;
                if (currentData.length>0) {
                    let currentId = 1;
                    currentId =
                    this.state.customizationTabs.length > 0
                    ? this.state.customizationTabs[this.state.customizationTabs.length - 1]
                        .id + 1
                    : 1;
                    let newTabs = [];
                    currentData.map((item)=>{
                        newTabs.push(this.cloneTabFromTemplate(item,currentId))
                        currentId = currentId+1
                    })
                    let customizationTabs = [...this.state.customizationTabs]
                    customizationTabs.push(...newTabs);
                    this.props.updateCustomSuggestionsInEditFoodItem(customizationTabs);
                    this.setState({customizationTabs: customizationTabs});
                }
                else {
                    alertError("no templates found for selected group")
                }
            });
        }
        this.hideAddNewTabChoiceModal()
    }

    showDishCustomizationModal = () => {
        this.setState({showDishCustomizationModal: true});
        this.hideAddNewTabChoiceModal()
    }

    showAddNewTabChoiceModal = ()=> {
        this.setState({showAddNewTabChoiceModal: true})
    }

    hideAddNewTabChoiceModal = ()=> {
        this.setState({showAddNewTabChoiceModal: false})
    }

    hideDishCustomizationModal = () => {
        this.setState({showDishCustomizationModal: false})
        this.setState({currentEditTab: null})
        this.currentEditTabIndex = null
    }

    setTabEditForm = (tab) => {
        // let tab = this.props.currentEditTab;
        if (tab) {
            let tabCreateForm = {
                tab_title: tab["title"],
                is_mandatory: tab["is_mandatory"],
                enable_quantity_limits: tab["enable_quantity_limits"],
                max_number_of_items_required: tab["max_number_of_items_required"],
                number_of_items_required: tab["number_of_items_required"],
                tab_type: tab["tab_type"],
                id: tab["id"],
                added: tab["added"],
                updated: tab["updated"],
                custom_questions: [],
                upsell_items: []
            }
            if (tab["tab_type"]==='1' || tab["tab_type"]===1) {
                tab.upsell_items.map((sideDishForm)=>{
                    let currentSideDish = {
                        food_item: sideDishForm["item"],
                        id: sideDishForm["id"],
                        payment_type: sideDishForm["payment_type"],
                        choice_type: sideDishForm["choice_type"],
                        free_available: sideDishForm["free_available"],
                        item_price: sideDishForm["item_price"],
                        added:sideDishForm["added"],
                        removed:sideDishForm["removed"],
                        updated:sideDishForm["updated"]
                    };
                    tabCreateForm.upsell_items.push(currentSideDish);
                })
            }
            if (tab["tab_type"]==='2' || tab["tab_type"]===2) {
                tab.custom_questions.map((suggestionForm)=>{
                    let currentSuggestion = {
                        question_title: suggestionForm["quetion_title"],
                        id: suggestionForm["id"],
                        question_type: suggestionForm["question_type"],
                        answers: suggestionForm["answers"],
                        added:suggestionForm["added"],
                        removed:suggestionForm["removed"],
                        updated:suggestionForm["updated"]
                    };
                    tabCreateForm.custom_questions.push(currentSuggestion)
                })
                
            }
            this.setState({currentEditTab: tabCreateForm})
        }
        // console.log(tab)
    }

    removeTab = (index) => {
        let currentItems = [...this.state.customizationTabs];
        let tab = currentItems[index];
        if (tab["added"]===true) {
            currentItems.splice(index,1)
        }
        else {
            currentItems[index]["removed"] = true;
        }
        this.props.updateCustomSuggestionsInEditFoodItem(currentItems);
        this.setState({customizationTabs: currentItems});

    }

    getFoodName(val) {
        // console.log(val);
        let foodObj = this.props.optionsFood.find((f) => f.id == val);
        let name = typeof foodObj != "undefined" ? foodObj.name : "unknown";
        return name;
    }

    addNewTab = (tabForm,tabType,sideDishes=[],suggestions=[]) => {
        let currentId = 1;
        currentId =
        this.state.customizationTabs.length > 0
        ? this.state.customizationTabs[this.state.customizationTabs.length - 1]
            .id + 1
        : 1;

        let currentItem = {
            title: tabForm["tab_title"],
            is_mandatory:tabForm['is_mandatory'],
            enable_quantity_limits:tabForm['enable_quantity_limits'],
            max_number_of_items_required:tabForm['max_number_of_items_required'],
            number_of_items_required:tabForm['number_of_items_required'],
            tab_type: tabForm["tab_type"],
            id: currentId,
            custom_questions: [],
            upsell_items: [],
            added: true,
            
        };

        if (tabType==='1' || tabType===1) {
            sideDishes.map((sideDishForm)=>{
                let currentSideDish = {
                    item: sideDishForm["food_item"],
                    id: currentId,
                    payment_type: sideDishForm["payment_type"],
                    choice_type: sideDishForm["choice_type"],
                    free_available: sideDishForm["free_available"],
                    item_price: sideDishForm["item_price"],
                    added:sideDishForm["added"]
                };
                currentItem.upsell_items.push(currentSideDish);
            })
        }

        if (tabType==='2' || tabType===2) {
            suggestions.map((suggestionForm)=>{
                let currentSuggestion = {
                    quetion_title: suggestionForm["question_title"],
                    id: currentId,
                    question_type: suggestionForm["question_type"],
                    answers: suggestionForm["answers"],
                    added:suggestionForm["added"]
                };
                currentItem.custom_questions.push(currentSuggestion)
            })
            
        }

        let customizationTabs = [...this.state.customizationTabs]
        customizationTabs.push(currentItem);
        this.props.updateCustomSuggestionsInEditFoodItem(customizationTabs);
        this.setState({customizationTabs: customizationTabs});
        this.hideDishCustomizationModal();
    }

    updateTab = (tabForm,tabType,sideDishes=[],suggestions=[]) => {
        let currentItem = {
            title: tabForm["tab_title"],
            is_mandatory:tabForm['is_mandatory'],
            enable_quantity_limits:tabForm['enable_quantity_limits'],
            max_number_of_items_required:tabForm['max_number_of_items_required'],
            number_of_items_required:tabForm['number_of_items_required'],
            tab_type: tabForm["tab_type"],
            id: tabForm["id"],
            custom_questions: [],
            upsell_items: [],
            added: tabForm["added"],
            updated: true,
            removed: tabForm["removed"]
        };

        if (tabType==='1' || tabType===1) {
            sideDishes.map((sideDishForm)=>{
                let currentSideDish = {
                    item: sideDishForm["food_item"],
                    id: sideDishForm["id"],
                    payment_type: sideDishForm["payment_type"],
                    choice_type: sideDishForm["choice_type"],
                    free_available: sideDishForm["free_available"],
                    item_price: sideDishForm["item_price"],
                    added:sideDishForm["added"],
                    removed:sideDishForm["removed"],
                    updated:sideDishForm["updated"]
                };
                currentItem.upsell_items.push(currentSideDish);
            })
        }

        if (tabType==='2' || tabType===2) {
            suggestions.map((suggestionForm)=>{
                let currentSuggestion = {
                    quetion_title: suggestionForm["question_title"],
                    id: suggestionForm["id"],
                    question_type: suggestionForm["question_type"],
                    answers: suggestionForm["answers"],
                    added:suggestionForm["added"],
                    removed:suggestionForm["removed"],
                    updated:suggestionForm["updated"]
                };
                currentItem.custom_questions.push(currentSuggestion)
            })
            
        }

        let customizationTabs = [...this.state.customizationTabs]
        customizationTabs[this.currentEditTabIndex] = currentItem;
        this.props.updateCustomSuggestionsInEditFoodItem(customizationTabs);
        this.setState({customizationTabs: customizationTabs})
        this.hideDishCustomizationModal();
    }


    render() {
        return (
            <>
            <div className='p-4 bg-white shadow-sm'>
                <Row>
                    <h4 className="font-weight-bold mt-0 mb-4">Food Customization Tabs</h4>
                    <button onClick={this.showAddNewTabChoiceModal} 
                    style={{borderStyle: 'none',backgroundColor: 'white',marginLeft:30, marginTop:3}}>
                        <h6 className="font-weight-bold mt-0 mb-4" style={{color: '#9BB341'}}>Add New Tab <Icofont icon='plus-circle'/></h6>
                    </button>
                    {/* <Form.Group className="col-md-12">
                        <Form.Label>Copy From Template</Form.Label>
                        <Form.Control
                        as="select"
                        name="customization_template_item"
                        onChange={this.onSelectCustomizationTemplate}
                        // value={this.state.addedItemscreateForm["food_item"]}
                        // style={{color: this.state.addedItemscreateForm["food_item"]>0?'black':'red'}}
                        >
                            <option style={{color:'red'}} value={0}>Select A Template</option>
                            {this.state.customization_templates.map((template) => {
                            return <option style={{color:'black'}} value={template.id}>{template.title}</option>;
                            })}
                            
                        </Form.Control>
                    </Form.Group> */}
                </Row>
                <div style={{height:420,overflowY:'auto'}}>
                    <Accordion>
                        {
                            this.state.customizationTabs.map((tab,index)=>{
                                if(!tab.removed){
                                    return (
                                        <>
                                        <Card>
                                            <Card.Header>
                                                <Row>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey={tab.id}>
                                                        <Icofont icon="caret-down" /> 
                                                        {tab.title}
                                                    </Accordion.Toggle>
                                                    <div style={{marginTop:10, marginLeft: 'auto', marginRight: 0}}>
                                                        <button style={{backgroundColor:'#9BB341',fontSize:16,color:'white',marginLeft:30}}
                                                        onClick={()=>{
                                                            this.setTabEditForm(tab);
                                                            this.showDishCustomizationModal();
                                                            this.currentEditTabIndex = index;
                                                        }}
                                                        >
                                                            <Icofont icon="pencil" />
                                                            Edit Tab
                                                        </button>
                                                        <button style={{backgroundColor:'red',fontSize:16,color:'white',marginLeft:30}}
                                                        onClick={()=>{
                                                            this.removeTab(index)
                                                        }}
                                                        >
                                                            <Icofont icon="trash" />
                                                            Delete Tab
                                                        </button>
                                                    </div>
                                                </Row>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey={tab.id}>
                                                <Card.Body>
                                                <h6 style={{color: '#9BB341'}}>Tab Details</h6>
                                                <div className="bg-white rounded shadow-sm p-4 osahan-payment">
                                            
                                                    { tab.is_mandatory &&
                                                    <Row>
                                                    <h7>Mandatory : Yes</h7>
                                                    </Row>
                                                    }
                                                    { !tab.is_mandatory &&
                                                    <Row>
                                                    <h7>Mandatory : No</h7>
                                                    </Row>
                                                    }
                                                    { tab.enable_quantity_limits && 
                                                    <>
                                                    <Row>
                                                    <h7>Minimum Items Required   : {tab.number_of_items_required}</h7>
                                                    </Row>
                                                    <Row>
                                                    <h7>Maximum Items Required : {tab.max_number_of_items_required}</h7>
                                                    </Row>
                                                    </>
                                                    }
                                                </div>
                                                { (tab.tab_type==='1' || tab.tab_type===1)  && 
                                                <div>
                                                    <h6 style={{color: '#9BB341',marginTop:3}}>Side Dishes</h6>
                                                    { tab.upsell_items.length>0 && 
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Available Free </th>
                                                                <th>Price</th>
                                                                {/* <th>Action</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                tab.upsell_items.map( (item,index) => {
                                                                    if(!item.removed){
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    {" "}
                                                                                    {this.getFoodName(item.item)}
                                                                                </td>
                                                                                <td>
                                                                                    {item.free_available && "Yes"}
                                                                                    {!item.free_available && "No"}
                                                                                </td>
                                                                                <td>{item.item_price}</td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                    }
                                                </div>
                                                }
                                                { (tab.tab_type==='2' || tab.tab_type===2) && 
                                                <div>
                                                    <h6 style={{color: '#9BB341',marginTop:3}}>
                                                        Suggestions ( Questions & Answers )
                                                    </h6>
                                                    { tab.custom_questions.length>0 && 
                                                    <div className="bg-white rounded shadow-sm p-4 osahan-payment">
                                                        <Accordion>
                                                        {
                                                            tab.custom_questions.map( (question,index) => {
                                                            if(!question.removed){
                                                                return (
                                                                    <>
                                                                    <Card>
                                                                    <Card.Header>
                                                                        <Row>
                                                                        <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                                                                            <Icofont icon="caret-down" /> 
                                                                            {question.quetion_title}
                                                                        </Accordion.Toggle>
                                                                        <div style={{marginTop:10, marginLeft: 'auto', marginRight: 0}}>
                                                                            { (question.question_type==='2' || question.question_type===2) && 
                                                                            <h8 style={{marginLeft: 10}}>Multiple Choices</h8>
                                                                            }
                                                                            { (question.question_type==='1' || question.question_type===1) && 
                                                                            <h8 style={{marginLeft: 10}}>Single Choice</h8>
                                                                            }
                                                                        </div>
                                                                        </Row>
                                                                    </Card.Header>
                                                                    <Accordion.Collapse eventKey={index}>
                                                                        <Card.Body>
                                                                        <div>
                                                                            <h7 style={{color: '#9BB341'}}>Choice Answers</h7>
                                                                            <Table striped bordered hover>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Sl.No</th>
                                                                                        <th>Choice</th>
                                                                                        <th>Free</th>
                                                                                        <th>Charge</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    { question.answers.map((answer,index)=>{
                                                                                        if(!answer.removed){
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td>{index+1}</td>
                                                                                                    <td>{answer.answer_title}</td>
                                                                                                    <td>
                                                                                                        { answer.free_available && "Yes"}
                                                                                                        { !answer.free_available && "No"}
                                                                                                    </td>
                                                                                                    <td>{answer.item_price}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    })
                                        
                                                                                    }
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                        </Card.Body>
                                                                    </Accordion.Collapse>
                                                                    </Card>
                                                                    {/* <br/> */}
                                                                    </>
                                                                )
                                                            }
                                                            })
                                                        }
                                                        </Accordion>
                                                    </div>
                                                    }
                                                </div>
                                                }
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        </>
                                    )
                                }
                            })
                        }
                    </Accordion>
                </div>
            </div>
            { this.state.showDishCustomizationModal && 
            <DishCustomizationModal 
            // tabId={this.state.tabId}
            addNewTab={this.addNewTab}
            updateTab={this.updateTab}
            show={this.state.showDishCustomizationModal}
            onHide={this.hideDishCustomizationModal}
            optionsFood={this.props.optionsFood}
            currentEditTab={this.state.currentEditTab}
            // payOptions={this.state.payOptions}
            // mandatoryOptions={this.state.mandatoryOptions}
            // mandatoryValues={this.state.mandatoryValues}
            // addNewCustomaization={this.addNewCustomaization}
            // customizationTabs={this.state.customizationTabs}
            />
            }

            <Modal show={this.state.showAddNewTabChoiceModal} onHide={this.hideAddNewTabChoiceModal} >
                <Modal.Header closeButton={true}>
                    <Modal.Title as='h6'>Choose An Option To Add New Tab</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='p-4 bg-white shadow-sm'>
                        <Form.Group className="col-md-12">
                            <button onClick={this.showDishCustomizationModal} 
                            >
                                <h6  style={{color: '#9BB341'}}>Add New Tab Manually<Icofont icon='plus-circle'/></h6>
                            </button>
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            <Form.Label>Copy From Template</Form.Label>
                            <Form.Control
                            as="select"
                            name="customization_template_item"
                            onChange={this.onSelectCustomizationTemplate}
                            // value={this.state.addedItemscreateForm["food_item"]}
                            // style={{color: this.state.addedItemscreateForm["food_item"]>0?'black':'red'}}
                            >
                                <option style={{color:'red'}} value={0}>Select A Template</option>
                                {this.state.customization_templates.map((template) => {
                                return <option style={{color:'black'}} value={template.id}>{template.title}</option>;
                                })}
                                
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="col-md-12">
                            <Form.Label>Copy From Template Group</Form.Label>
                            <Form.Control
                            as="select"
                            name="customization_template_group"
                            onChange={this.onSelectCustomizationTemplateGroup}
                            // value={this.state.addedItemscreateForm["food_item"]}
                            // style={{color: this.state.addedItemscreateForm["food_item"]>0?'black':'red'}}
                            >
                                <option style={{color:'red'}} value={0}>Select A Template Group</option>
                                {this.state.customization_template_groups.map((group) => {
                                return <option style={{color:'black'}} value={group.id}>{group.title}</option>;
                                })}
                                
                            </Form.Control>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>
            </>
        )
    }
}


export default FoodCustomization;

