import React from "react";
import { Row, Col, Card } from "react-bootstrap";
// import SummaryCard from '../common/SummaryCard';

class Notifications extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.notifications = []
    }

    render() {
        return (
            <>
            <div className='p-4 bg-white shadow-sm'>
                <h4>Notifications For You....</h4>
                {this.props.notifications.map( (notification) => {
                    return(
                        <Row>
                            <Col md={12}>
                                <Card className="bg-white payments-item mb-4 shadow-sm">
                                    <div className="gold-members p-4">
                                        <h6 className="mb-1">{notification.title}</h6>
                                        <p style={{fontSize:"17px"}}>{notification.content}</p>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    )
                })}
            </div>
            </>
        )
    }
}

export default Notifications;