import React from "react";
import { Form, Modal, Button } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";

class MinAmountFreeOfferAddModal extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.validator = props.validator;
	}

	onUpdateSelectedFoodItem = (items) => {
		this.props.createFormHandler({
			target: {
				type: "multiple-selection",
				name: "items",
				items: items.map((item) => item.id),
			},
		});
	};

	render() {
		return (
			<Modal
				show={this.props.show}
				onHide={this.props.onHide}
				size="lg"
				centered
			>
				<Modal.Header closeButton={true}>
					<Modal.Title as="h5" id="edit-profile">
						New Offer
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form>
						<Form.Group className="col-md-8">
							<Form.Label>Title</Form.Label>
							{this.validator.message(
								"title",
								this.props.createForm["title"],
								"required",
								{ className: "text-danger" }
							)}

							<Form.Control
								type="text"
								onChange={this.props.createFormHandler}
								name="title"
								value={this.props.createForm["title"]}
								placeholder="Enter Offer Title"
							/>
						</Form.Group>
						<Form.Group className="col-md-8">
							<Form.Label>Offer Message</Form.Label>
							{this.validator.message(
								"offerMessage",
								this.props.createForm["offer_message"],
								"required",
								{ className: "text-danger" }
							)}

							<Form.Control
								type="text"
								onChange={this.props.createFormHandler}
								name="offer_message"
								value={this.props.createForm["offer_message"]}
								placeholder="Enter Offer Message"
							/>
						</Form.Group>

						<Form.Group className="col-md-8">
							<Form.Label>
								Minimum Amount Required For Enabling Free
							</Form.Label>
							<Form.Control
								type="number"
								onChange={this.props.createFormHandler}
								name="minim_amount_to_be_purchased"
								value={
									this.props.createForm[
										"minim_amount_to_be_purchased"
									]
								}
								placeholder="Enter Order Value"
							/>
							{this.validator.message(
								"minimumAmount",

								this.props.createForm[
									"minim_amount_to_be_purchased"
								],
								"required|numeric|min:1,num",
								{ className: "text-danger" }
							)}
						</Form.Group>
						<Form.Group className="col-md-8">
							<Form.Label>Select Free Food Items</Form.Label>

							<Multiselect
								options={this.props.optionsFood} // Options to display in the dropdown
								selectedValues={this.props.optionsFood.filter(
									(item) => {
										return this.props.createForm[
											"items"
										].includes(item.id);
									}
								)} // Preselected value to persist in dropdown
								onSelect={this.onUpdateSelectedFoodItem} // Function will trigger on select event
								onRemove={this.onUpdateSelectedFoodItem} // Function will trigger on remove event
								displayValue="name" // Property name to display in the dropdown options
							/>
						</Form.Group>

						<Form.Group className="col-md-8">
							<Form.Label>
								Maximum Number Of Free Items Should Be Selected
							</Form.Label>

							<Form.Control
								type="number"
								onChange={this.props.createFormHandler}
								name="max_number_of_items_can_be_selected"
								value={
									this.props.createForm[
										"max_number_of_items_can_be_selected"
									]
								}
								placeholder="Enter Maximum Free Limit"
							/>
							{this.validator.message(
								"maximumFreeItems",

								this.props.createForm[
									"max_number_of_items_can_be_selected"
								],
								`required|numeric|min:1,num|max:${this.props.createForm["items"].length},num`,
								{ className: "text-danger" }
							)}
						</Form.Group>
						<Form.Group className="col-md-4">
							<Form.Check
								type="checkbox"
								name="enable_takeout"
								checked={this.props.createForm["enable_takeout"]}
								onClick={this.props.createFormHandler}
								label="Enable For Takeout"
							/>
						</Form.Group>
						<Form.Group className="col-md-4">
							<Form.Check
								type="checkbox"
								name="enable_delivery"
								checked={this.props.createForm["enable_delivery"]}
								onClick={this.props.createFormHandler}
								label="Enable For Delivery"
							/>
						</Form.Group>
						<Form.Group className="col-md-4">
							<Form.Check
								type="checkbox"
								name="active"
								checked={this.props.createForm["active"]}
								onClick={this.props.createFormHandler}
								label="Enable Offer"
							/>
						</Form.Group>
					</Form>
				</Modal.Body>

				<Modal.Footer>
					<div className="form-row">
						<Button
							type="button"
							onClick={this.props.onHide}
							variant="outline-primary"
							className="d-flex w-50 text-center justify-content-center"
						>
							CANCEL
						</Button>
						<Button
							type="button"
							onClick={(e) => {
								if (this.props.crudMode === "create") {
									this.props.createOffer();
								} else {
									this.props.updateOffer();
								}
							}}
							variant="primary"
							className="d-flex w-50 text-center justify-content-center"
						>
							{this.props.crudMode === "create" ? "ADD" : "UPDATE"}
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		);
	}
}
export default MinAmountFreeOfferAddModal;
