import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Card,
  Col,
  Container,
  Badge,
  Form,
  InputGroup,
  Button,
  Tab,
  Nav,
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup,
  Image,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import ItemsCarousel from "./common/ItemsCarousel";
import ChooseAddressCard from "./common/ChooseAddressCard";
import CheckoutItem from "./common/CheckoutItem";
import MutilUpload from "./common/MultipleImageUploadComponent";
import AddAddressModal from "./modals/AddAddressModal";
import FontAwesome from "./common/FontAwesome";
import Icofont from "react-icofont";
import { Multiselect } from "multiselect-react-dropdown";
import DataService from "../services/DataService";
import { alertCreation, alertError } from "../components/Alerts";
import SimpleReactValidator from "simple-react-validator";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
class SuggestionTab extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showAddressModal: false,
      createForm: { tab_type: 1 },
      addedItemscreateForm: {},
      showHideDelivery: false,
      tags: [],
      options: [],
    };
  }
  getFoodPrice(val) {
    console.log(val);
    let foodObj = this.props.optionsFood.find((f) => f.id == val);
    console.log(foodObj);
    let price = typeof foodObj != "undefined" ? foodObj.price : 0;
    return price;
  }
  getFoodName(val) {
    console.log(val);
    let foodObj = this.props.optionsFood.find((f) => f.id == val);
    let name = typeof foodObj != "undefined" ? foodObj.name : "unknown";
    return name;
  }
  getPaymentType(val) {
    console.log(val, this.props.payOptions);

    let payObj = this.props.payOptions.find((p) => p.id === val);
    let name = typeof payObj != "undefined" ? payObj.name : "unknown";
    return name;
  }
  getChoiceType(val) {
    let choiceOptions = [
      { id: "1", name: "Mandatory" },
      { id: "2", name: "Optional" },
    ];
    let choiceObj = choiceOptions.find((p) => p.id === val);
    let name = typeof choiceObj != "undefined" ? choiceObj.name : "unknown";
    return name;
  }


  getQuestionType(val) {
    let choiceOptions = [
      { id: "2", name: "Multiple Selection" },
      { id: "1", name: "Single Selection" },
    ];
    let choiceObj = choiceOptions.find((p) => p.id === val);
    let name = typeof choiceObj != "undefined" ? choiceObj.name : "unknown";
    return name;
  }
  createAddedItemsFormHandler = (event) => {
    let key = "";
    let value = "";
    let inputForm = { ...this.state.addedItemscreateForm };
    if (event.target.type == "text" || event.target.type == "textarea") {
      key = event.target.name;
      value = event.target.value;
    } else if (event.target.type == "select-one") {
      key = event.target.name;
      value = event.target.value;
      if (key == "food_type") {
        inputForm["item_price"] = this.getFoodPrice(value);
      }
    }

    inputForm[key] = value;
    this.setState({ addedItemscreateForm: inputForm });
  };
  createFormHandler = (event) => {
    let key = "";
    let value = "";
    let inputForm = { ...this.state.createForm };

    if (
      event.target.type == "text" ||
      event.target.type == "textarea" ||
      event.target.type == "number"
    ) {
      key = event.target.name;
      value = event.target.value;
    } else if (event.target.type == "select-one") {
      key = event.target.name;

      value = event.target.value;
    }

    inputForm[key] = value;
    this.setState({ createForm: inputForm });
  };

  hideAddressModal = () => this.setState({ showAddressModal: false });

  hideDelivery(name) {
    this.setState({ showHideDelivery: !this.state.showHideDelivery });
  }

  getQty = ({ id, quantity }) => {
    //console.log(id);
    //console.log(quantity);
  };
  isTabFormValid() {
    return (
      this.validator.fieldValid("tab_title") &&
      this.validator.fieldValid("tab_type")
    );
  }
  isFoodItemFormValid() {
    return (
      this.validator.fieldValid("payment_type") &&
      this.validator.fieldValid("food_type")
    );
  }
  fieldValid;
  render() {
    const { tags } = this.state;
    return (
      <div>
        <div className="pt-2"></div>

        <span className="hidden">
          {/*this.props.shouldHide ? 'hidden' : ''*/}

          <div className="pt-2"></div>

          <div className="pt-2"></div>
          <div className="bg-white rounded shadow-sm p-4 osahan-payment">
            <span></span>
            <Card>
              <Card.Body>
                <h6 className="mb-1">Add Customization</h6>
                <Form.Group className="col-md-12">
                  <Form.Label>Tab Title</Form.Label>
                  {this.validator.message(
                    "tab_title",
                    this.state.createForm["tab_title"],
                    "required|min:2|max:10"
                  )}

                  <InputGroup>
                    <Form.Control
                      type="text"
                      onChange={this.createFormHandler}
                      name="tab_title"
                      placeholder="Enter Tab Title"
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="col-md-12">
                  <Form.Label>Tab Type</Form.Label>
                  {this.validator.message(
                    "tab_type",
                    this.state.createForm["tab_type"],
                    "required"
                  )}

                  <Form.Control
                    as="select"
                    onChange={this.createFormHandler}
                    name="tab_type"
                  >
                    <option value="1">
                      Select Extra Items available with this food
                    </option>
                    <option value="2">
                      Customization available for this Item
                    </option>
                  </Form.Control>
                </Form.Group>
                <Nav>
                  <Nav.Link
                    eventKey={0}
                    as={NavLink}
                    activeclassname="active"
                    className="pt-3"
                    to="#"
                    onClick={() => {
                      if (this.isTabFormValid() == true) {
                        this.props.addNewTab(this.state.createForm);
                      } else {
                        this.validator.showMessages();
                      }
                    }}
                  >
                    Add New tab
                    <Icofont icon="plus-circle" />
                    <span className="sr-only">(current)</span>
                  </Nav.Link>
                </Nav>
              </Card.Body>
            </Card>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={4} className="pr-0">
                  <Nav variant="pills" className="flex-column">
                    {this.props.upsellItems.map((upsellObj) => {
                      let tabId = "item" + upsellObj.id;
                      return (
                        <Nav.Link eventKey={tabId}>
                          <Icofont icon="food-basket" /> {upsellObj.title}
                        </Nav.Link>
                      );
                    })}
                  </Nav>
                </Col>
                <Col sm={8} className="pl-0">
                  <Tab.Content className="h-100">
                    {this.props.upsellItems.map((upsellObj) => {
                      let tabId = "item" + upsellObj.id;
                      let itemChoice = upsellObj.tab_type == 1;

                      return (
                        <Tab.Pane eventKey={tabId}>
                          <h6 className="mb-3 mt-0 mb-3">
                            Add New Customization
                          </h6>
                          {itemChoice && (
                            <FoodItemForm
                              tabId={upsellObj.id}
                              payOptions={this.state.payOptions}
                              addNewCustomaization={this.props.addNewCustomaization}
                              removeFoodItem={this.props.removeFoodItem}
                              optionsFood={this.props.optionsFood}
                              getFoodPrice={this.getFoodPrice}
                              getFoodName={this.getFoodName}
                            ></FoodItemForm>
                          )}
                          {!itemChoice && (
                            <SuggestionItemForm
                              tabId={upsellObj.id}
                              payOptions={this.state.payOptions}
                              addNewCustomaization={this.props.addNewCustomaization}
                              removeFoodItem={this.props.removeFoodItem}
                 
                
                            ></SuggestionItemForm>
                          )}

                          <hr></hr>
                          {itemChoice && ( <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Choice Type</th>
                                <th>Available Free </th>
                                <th>Price</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {typeof (upsellObj.added_items != "undefined") &&
                                upsellObj.added_items.length > 0 &&
                                upsellObj.added_items.map((itemObj) => {
                                  return (
                                    <tr>
                                      <td>
                                        {" "}
                                        {this.getFoodName(itemObj.food_item)}
                                      </td>
                                      <td>
                                        {this.getChoiceType(
                                          itemObj.choice_type
                                        )}
                                      </td>

                                      <td>
                                        {itemObj.free_available && "Yes"}
                                        {!itemObj.free_available && "No"}
                                      </td>

                                      <td>{itemObj.item_price}</td>
                                      <td>
                                        <Link
                                          className="btn btn-success btn-block btn-sm"
                                          onClick={() => {
                                            this.props.removeFoodItem(
                                              upsellObj.id,
                                              itemObj
                                            );
                                          }}
                                        >
                                          <Icofont icon="trash" />
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                          
                          )}
                          
                          
                          {!itemChoice && ( <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Question Title</th>
                                <th>Question Type </th>
                                <th>Answers</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {typeof (upsellObj.added_items != "undefined") &&
                                upsellObj.added_items.length > 0 &&
                                upsellObj.added_items.map((itemObj) => {
                                  return (
                                    <tr>
                                      <td>
                                        {" "}
                                        { itemObj.quetion_title}
                                      </td>
                                      <td>
                                        {this.getQuestionType(
                                          itemObj.question_type
                                        )}
                                      </td>

                                      <td>
                                          <table>

                                          <th>Answer</th>
                                <th>Free </th>
                                <th>Charge</th>
                                {

itemObj.answers.map((answerObj)=>(


  <tr>

<td>{answerObj.answer}</td>
<td>
{answerObj.free_available && "Yes"}
                                        {!answerObj.free_available && "No"}

</td>
<td>{answerObj.item_price}</td>

  </tr>
))

                                }

                                          </table>



                                      </td>

                                    
                                      <td>
                                        <Link
                                          className="btn btn-success btn-block btn-sm"
                                          onClick={() => {
                                            this.props.removeFoodItem(
                                              upsellObj.id,
                                              itemObj
                                            );
                                          }}
                                        >
                                          <Icofont icon="trash" />
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                          
                          )}
                          
                          <card className="btn btn-danger btn-block btn-sm">
                            {"Delete"}
                            <span
                              onClick={() => {
                                this.props.removeTab(upsellObj);
                              }}
                            >
                              <Icofont icon="trash" />
                            </span>
                          </card>
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </span>
      </div>
    );
  }
}

/*
Component for Food Item Form
*/
class FoodItemForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.getFoodPrice = this.props.getFoodPrice;
    this.state = {
      addedItemscreateForm: { free_available: false, choice_type: "1" },
    };
  }
  isFoodItemFormValid() {
    return (
      this.validator.fieldValid("choice_type") &&
      this.validator.fieldValid("food_item")
    );
  }
  //to handler for added Items in Form
  createAddedItemsFormHandler = (event) => {
    let key = "";
    let value = "";
    let inputForm = { ...this.state.addedItemscreateForm };
    if (
      event.target.type == "text" ||
      event.target.type == "number" ||
      event.target.type == "textarea"
    ) {
      key = event.target.name;
      value = event.target.value;
    } else if (event.target.type == "select-one") {
      key = event.target.name;
      value = event.target.value;
      if (key == "food_item" && inputForm["free_available"] == false) {
        inputForm["item_price"] = this.getFoodPrice(value);
      }
    } else if (event.target.type == "checkbox") {
      key = event.target.name;
      value = event.target.checked;
      if (value == true) {
        delete inputForm["item_price"];
      }
    }

    inputForm[key] = value;
    this.setState({ addedItemscreateForm: inputForm });
  };
  render() {
    return (
      <div>
        <Form>
          <div className="form-row">
            <Form.Group className="col-md-8">
              <Form.Label>Food Item</Form.Label>
              {this.validator.message(
                "food_item",
                this.state.addedItemscreateForm["food_item"],
                "required"
              )}

              <Form.Control
                as="select"
                name="food_item"
                onChange={this.createAddedItemsFormHandler}
              >
                {this.props.optionsFood.map((foodObj) => {
                  return <option value={foodObj.id}>{foodObj.name}</option>;
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group className="col-md-8">
              <Form.Label>Choice Type</Form.Label>
              {this.validator.message(
                "choice_type",
                this.state.addedItemscreateForm["choice_type"],
                "required"
              )}
              <Form.Control
                as="select"
                name="choice_type"
                onChange={this.createAddedItemsFormHandler}
              >
                <option value="1">Mandatory</option>
                <option value="2">Optional</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="col-md-8">
              <Form.Check
                type="checkbox"
                name="free_available"
                onChange={this.createAddedItemsFormHandler}
                label="Available this item as Free"
              />
            </Form.Group>

            {this.state.addedItemscreateForm["free_available"] == false && (
              <Form.Group className="col-md-12">
                <Form.Label>Item Price</Form.Label>

                <InputGroup>
                  <Form.Control
                    type="number"
                    onChange={this.createAddedItemsFormHandler}
                    name="item_price"
                    placeholder="Item Price"
                    value={this.state.addedItemscreateForm["item_price"]}
                  />
                </InputGroup>
              </Form.Group>
            )}
            <Form.Group className="col-md-12 mb-0">
              <Link
                onClick={() => {
                  if (this.isFoodItemFormValid() == true) {
                    this.props.addNewCustomaization(
                      this.props.tabId,

                      this.state.addedItemscreateForm
                    );
                  } else {
                    this.validator.showMessages();
                  }
                }}
                className="btn btn-success btn-block btn-lg"
              >
                ADD
                <Icofont icon="long-arrow-right" />
              </Link>
            </Form.Group>
          </div>
        </Form>
      </div>
    );
  }
}

/*
Component for Food Item Form
*/
class SuggestionItemForm extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
    this.getFoodPrice = this.props.getFoodPrice;
    this.state = {
      addedItemscreateForm: { answers: [],question_type:"2" },
    };
    this.addNewAnswer = this.addNewAnswer.bind(this);
    this.createAnswerFormHandler=this.createAnswerFormHandler.bind(this);
  }


    //to handler for added Items in Form
    createAnswerFormHandler = (event,id) => {
      let key = "";
      let value = "";
      let inputParams = {...this.state.addedItemscreateForm };
      let selectedForm={...inputParams.answers[id]}

      if (
        event.target.type == "text" ||
        event.target.type == "number" ||
        event.target.type == "textarea"
      ) {
        key = event.target.name;
        value = event.target.value;
      }  else if (event.target.type == "checkbox") {
        key = event.target.name;
        value = event.target.checked;
      }
  
      selectedForm[key] = value;
      inputParams.answers[id]=selectedForm;
      console.log(inputParams);
      this.setState({ addedItemscreateForm: inputParams });
    };


    removeAnswer = (answer) => {
      let inputParams = {...this.state.addedItemscreateForm };
      inputParams.answers.splice( inputParams.answers.indexOf(answer), 1);
      this.setState({ addedItemscreateForm: inputParams });

    };
  addNewAnswer = (id, answerForm = {}) => {
    let inputItems = { ...this.state.addedItemscreateForm };

    if (typeof id == "undefined") {
      let currentItem = {
        answer: "",
        item_price: 0,
        free_available: false,
      };
      inputItems.answers.push(currentItem);
    } else {
      let newAnswers = inputItems.answers.map((answerObj, idx) => {
        if (idx === id) {
          let currentItem = {
            answer: answerForm["answer"],
            item_price: answerForm["free_available"] ? 0 : answerForm["price"],
            free_available: answerForm["free_available"],
          };
          return currentItem;
        } else {
          return answerObj;
        }
      });
      inputItems.answers = { ...newAnswers };
    }
    this.setState({ addedItemscreateForm: inputItems });
  };
  isFoodItemFormValid() {
    return (
      this.validator.fieldValid("choice_type") &&
      this.validator.fieldValid("food_item")
    );
  }

  //to handler for added Items in Form
  createAddedItemsFormHandler = (event) => {
    let key = "";
    let value = "";
    let inputForm = { ...this.state.addedItemscreateForm };
    if (
      event.target.type == "text" ||
      event.target.type == "number" ||
      event.target.type == "textarea"
    ) {
      key = event.target.name;
      value = event.target.value;
    } else if (event.target.type == "select-one") {
      key = event.target.name;
      value = event.target.value;
      if (key == "question_type") {
        if (value == "1") {
          delete inputForm["answers"];
          inputForm["answers"] = [];
          inputForm["answers"] = [
            { answer: "Yes", free_available: true, item_price: 0 },
            { answer: "No", free_available: true, item_price: 0 },
          ];
        } else {
          delete inputForm["answers"];

          inputForm["answers"] = [];
        }
      }
    } else if (event.target.type == "checkbox") {
      key = event.target.name;
      value = event.target.checked;
    }

    inputForm[key] = value;
    this.setState({ addedItemscreateForm: inputForm });
  };
  render() {
    return (
      <div>
        <Form>
          <div className="form-row">
            <Form.Group className="col-md-12">
              <Form.Label>Question</Form.Label>
              {this.validator.message(
                "quetion_title",
                this.state.addedItemscreateForm["quetion_title"],
                "required"
              )}
              <Form.Control
                type="textarea"
                onChange={this.createAddedItemsFormHandler}
                name="quetion_title"
                placeholder="Enter Question Title"
              />
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label>Question Type</Form.Label>
              {this.validator.message(
                "question_type",
                this.state.addedItemscreateForm["question_type"],
                "required"
              )}
              <Form.Control
                as="select"
                name="question_type"
                onChange={this.createAddedItemsFormHandler}
              >
                <option value="2">Multi Choices</option>
                <option value="1">Single Choice</option>
              </Form.Control>
            </Form.Group>
            {this.state.addedItemscreateForm["question_type"] === "2" && (
              <Form.Group className="col-md-6 pt-4">
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.addNewAnswer();
                  }}
                  size="sm"
                >
                  <i class="icofont-plus-circle"></i>
                  New Answer
                </Button>
              </Form.Group>
            )}
            <Form.Group className="col-md-8">
              {this.state.addedItemscreateForm.answers.map((answerObj, idx) => (
                <card>   <Card.Body>
                  <AnswerChoices
                    customSugObj={this.props.customSugObj}
                    id={idx}
                    addNewAnswer={this.addNewAnswer}
                    answer={answerObj}
                    createAnswerFormHandler={this.createAnswerFormHandler}
                  ></AnswerChoices>
                 
                 
                 {this.state.addedItemscreateForm["question_type"] === "2" && (
                  <Form.Group className="col-md-8">
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    this.removeAnswer(e,answerObj);
                  }}
                  size="sm"
                >
                  <i class="icofont-minus-circle"></i>
                </Button>
              </Form.Group>)}
              
              
              
              </Card.Body>
                </card>
              ))}
            </Form.Group>




            <Form.Group className="col-md-12 mb-0">
              <Link
                onClick={() => {
                  console.log(this.state.addedItemscreateForm);
                    this.props.addNewCustomaization(
                      this.props.tabId,
                      this.state.addedItemscreateForm
                    );
                  
                }}
                className="btn btn-success btn-block btn-lg"
              >
                ADD
                <Icofont icon="long-arrow-right" />
              </Link>
            </Form.Group>
          </div>
        </Form>
      </div>
    );
  }
}

class AnswerChoices extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div className="panel panel-default">
        <div className="panel-body">
        {" "}
        <Form.Label>Answer {" " + (this.props.id + 1)}</Form.Label>
        <Form.Control type="text" name="answer" 
        onChange={
          (e )=> {this.props.createAnswerFormHandler(e, this.props.id)}
          }
        value={this.props.answer.answer}
        placeholder="Type Answer" />
        <Form.Check
          type="checkbox"
          name="free_available"
          label="Available this Customaization Free"
          checked= {this.props.answer.free_available}

          onChange={
            (e )=> {this.props.createAnswerFormHandler(e, this.props.id)}
            }
        />

        {(!this.props.answer.free_available)&&
        (<div><Form.Label>Item Price</Form.Label>
        <InputGroup>
          <Form.Control
            type="number"
            name="item_price"
            placeholder="Item Price"
            checked= {this.props.answer.item_price}

            onChange={
              (e )=> {this.props.createAnswerFormHandler(e, this.props.id)}
              }
          />
        </InputGroup></div>)
        
            }
             
            
            </div>
      </div>
    );
  }
}

export default SuggestionTab;
