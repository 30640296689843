import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Card,
  Col,
  Container,
  Badge,
  Form,
  InputGroup,
  Button,
  Tab,
  Nav,
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup,
  Image,
  OverlayTrigger,
  Tooltip,
  Table,
} from "react-bootstrap";
import ItemsCarousel from "./common/ItemsCarousel";
import ChooseAddressCard from "./common/ChooseAddressCard";
import CheckoutItem from "./common/CheckoutItem";
import MutilUpload from "./common/MultipleImageUploadComponent";
import AddAddressModal from "./modals/AddAddressModal";
import FontAwesome from "./common/FontAwesome";
import Icofont from "react-icofont";
import { Multiselect } from "multiselect-react-dropdown";
import DataService from "../services/DataService";
import { alertCreation, alertError } from "./Alerts";
import SimpleReactValidator from "simple-react-validator";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];
class CustomSuggestionTab extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      showAddressModal: false,
      createForm: {},
      addedItemscreateForm: {},
      showHideDelivery: false,
      tags: [],
      options: [],
    };
  }

  getFoodName(val) {
    console.log(val);
    let foodObj = this.props.optionsFood.find((f) => f.id == val);
    let name = typeof foodObj != "undefined" ? foodObj.name : "unknown";
    return name;
  }
  getPaymentType(val) {
    console.log(val, this.props.payOptions);

    let payObj = this.props.payOptions.find((p) => p.id === val);
    let name = typeof payObj != "undefined" ? payObj.name : "unknown";
    return name;
  }
  getQuestionType(val) {

    let questObj = this.props.questionOptions.find((p) => p.id === val);
    let name = typeof questObj != "undefined" ? questObj.name : "unknown";
    return name;
  }
  createAddedItemsFormHandler = (event) => {
    let key = "";
    let value = "";
    let inputForm = { ...this.state.addedItemscreateForm };
    if (event.target.type == "text" || event.target.type == "textarea") {
      key = event.target.name;
      value = event.target.value;
    } else if (event.target.type == "select-one") {
      key = event.target.name;
      value = event.target.value;
    }

    inputForm[key] = value;
    this.setState({ addedItemscreateForm: inputForm });
  };
  createFormHandler = (event) => {
    let key = "";
    let value = "";
    let inputForm = { ...this.state.createForm };

    if (event.target.type == "text" || event.target.type == "textarea") {
      key = event.target.name;
      value = event.target.value;
    } else if (event.target.type == "select-one") {
      key = event.target.name;
      value = event.target.value;
    }

    inputForm[key] = value;
    console.log(inputForm);
    this.setState({ createForm: inputForm });
  };

  hideAddressModal = () => this.setState({ showAddressModal: false });

  hideDelivery(name) {
    this.setState({ showHideDelivery: !this.state.showHideDelivery });
  }

  getQty = ({ id, quantity }) => {
    //console.log(id);
    //console.log(quantity);
  };
  isTabFormValid() {
    return this.validator.fieldValid("tab_title");
  }
  isQuestionItemValid() {
    return (
      this.validator.fieldValid("quetion_title") &&
      this.validator.fieldValid("question_type")
    );
  }
  fieldValid;
  render() {
    const { tags } = this.state;
    return (
      <div>
        <div className="pt-2"></div>

        <span className="hidden">
          {/*this.props.shouldHide ? 'hidden' : ''*/}

          <div className="pt-2"></div>

          <div className="pt-2"></div>
          <div className="bg-white rounded shadow-sm p-4 osahan-payment">
            <span></span>
            <Card>
              <Card.Body>
                <h6 className="mb-1">Add Custom Suggestion Options</h6>
                <Form.Group className="col-md-12">
                  <Form.Label>Tab Title</Form.Label>
                  {this.validator.message(
                    "tab_title",
                    this.state.createForm["tab_title"],
                    "required|min:2|max:10"
                  )}

                  <InputGroup>
                    <Form.Control
                      type="text"
                      onChange={this.createFormHandler}
                      name="tab_title"
                      placeholder="Enter Tab Title"
                    />
                  </InputGroup>
                </Form.Group>

                <Nav>
                  <Nav.Link
                    eventKey={0}
                    as={NavLink}
                    to="#"
                    activeclassname="active"
                    className="pt-3"
                    onClick={() => {
                      if (this.isTabFormValid() == true) {
                        this.props.addNewQuestionTab(this.state.createForm);
                      } else {
                        this.validator.showMessages();
                      }
                    }}
                  >
                    Add New tab
                    <Icofont icon="plus-circle" />
                    <span className="sr-only">(current)</span>
                  </Nav.Link>
                </Nav>
              </Card.Body>
            </Card>

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={4} className="pr-0">
                  <Nav variant="pills" className="flex-column">
                    {this.props.customSuggestions.map((customSugObj) => {
                      console.log(customSugObj);
                      let tabId = "item" + customSugObj.id;
                      console.log(tabId);
                      return (
                        <Nav.Link eventKey={tabId}>
                          <Icofont icon="food-basket" /> {customSugObj.title}
                        </Nav.Link>
                      );
                    })}
                  </Nav>
                </Col>
                <Col sm={8} className="pl-0">
                  <Tab.Content className="h-100">
                  {this.props.customSuggestions.map((customSugObj) => {
                      let tabId = "item" + customSugObj.id;
                      return (
                        <Tab.Pane eventKey={tabId}>
                          <h6 className="mb-3 mt-0 mb-3">Add new </h6>

                          <Form>
                            <div className="form-row">
                              <Form.Group className="col-md-8">
                                <Form.Label>Question</Form.Label>
                                {this.validator.message(
                                  "quetion_title",
                                  this.state.addedItemscreateForm[
                                    "quetion_title"
                                  ],
                                  "required"
                                )}
                                <Form.Control
                                  type="text"
                                  onChange={this.createAddedItemsFormHandler}
                                  name="quetion_title"
                                  placeholder="Enter Questions"
                                />
                              </Form.Group>
                              <Form.Group className="col-md-8">
                                <Form.Label>Question Type</Form.Label>
                                {this.validator.message(
                                  "question_type",
                                  this.state.addedItemscreateForm[
                                    "question_type"
                                  ],
                                  "required"
                                )}
                                <Form.Control
                                  as="select"
                                  name="question_type"
                                  onChange={this.createAddedItemsFormHandler}
                                >
                                  <option value="1">Yes Or No</option>
                                  <option value="2">Descriptive</option>
                                </Form.Control>
                              </Form.Group>

                              <Form.Group className="col-md-12 mb-0">
                                <Link
                                  onClick={() => {
                                    if (this.isQuestionItemValid() == true) {
                                      this.props.addNewQuestionItem(
                                        customSugObj.id,
                                        this.state.addedItemscreateForm
                                      );
                                    } else {
                                      this.validator.showMessages();
                                    }
                                  }}
                                  className="btn btn-success btn-block btn-lg"
                                >
                                  ADD
                                  <Icofont icon="long-arrow-right" />
                                </Link>
                              </Form.Group>
                            </div>
                          </Form>
                          <hr></hr>
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th> question</th>
                                <th>Question Type</th>

                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {typeof (customSugObj.questions != "undefined") &&
                                customSugObj.questions.length > 0 &&
                                customSugObj.questions.map((itemObj) => {
                                  console.log(itemObj);
                                  return (
                                    <tr>
                                      <td>{itemObj.id}</td>

                                      <td>
                                        {itemObj.quetion_title}
                                      </td>
                                      <td>
                                        {this.getQuestionType(
                                          itemObj.question_type
                                        )}
                                      </td>
                                      <td>
                                        <Link
                                          className="btn btn-success btn-block btn-sm"
                                          onClick={() => {
                                            this.props.removeQuestionItem(
                                              customSugObj.id,
                                              itemObj
                                            );
                                          }}
                                        >
                                          <Icofont icon="trash" />
                                        </Link>
                                      </td>
                                      <td>

                                        
                                      </td>

                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                          <card className="btn btn-danger btn-block btn-sm">
                            {}
                            <span 
                              onClick={() => {
                                this.props.removeQuestionTab(customSugObj);
                              }}
                            >
                              <Icofont icon="trash" />
                            </span>
                          </card>
                        </Tab.Pane>
                      );
                    })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </span>
      </div>
    );
  }
}

export default CustomSuggestionTab;
