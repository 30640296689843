import React from "react";
import { Form, Modal, Button } from "react-bootstrap";

class ExtraPaymentModel extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.validator = props.validator;
	}
	render() {
		return (
			<Modal
				show={this.props.show}
				onHide={this.props.onHide}
				size="lg"
				centered
			>
				<Modal.Header closeButton={true}>
					<Modal.Title as="h5" id="edit-profile">
						{this.props.title}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form>
						<div className="form-row">
							<Form.Group className="col-md-12">
								<Form.Label>Title</Form.Label>
								{this.validator.message(
									"title",
									this.props.createForm["title"],
									"required",
									{ className: "text-danger" }
								)}

								<Form.Control
									type="text"
									onChange={this.props.createFormHandler}
									name="title"
									defaultValue={this.props.createForm["title"]}
									placeholder="Enter Catogery Name"
								/>
							</Form.Group>
						</div>
						<div className="form-row">
							<Form.Group className="col-md-4">
								<Form.Check
									type="checkbox"
									name="enable_delivery"
									checked={
										this.props.createForm["enable_delivery"]
									}
									onClick={this.props.createFormHandler}
									label="Enable  for Delivery orders"
								/>{" "}
							</Form.Group>

							<Form.Group className="col-md-4">
								<Form.Check
									type="checkbox"
									name="enable_takeout"
									checked={
										this.props.createForm["enable_takeout"]
									}
									onClick={this.props.createFormHandler}
									label="Enable  for Takeout orders"
								/>{" "}
							</Form.Group>
						</div>

						<div className="form-row">
							<Form.Group className="col-md-2">
								<Form.Check
									type="checkbox"
									name="enable_percentage"
									checked={
										this.props.createForm["enable_percentage"]
									}
									onClick={this.props.createFormHandler}
									label="Enable Discount in Precentage"
								/>{" "}
							</Form.Group>
							<Form.Group className="col-md-4">
								<Form.Label>
									Extra Payment (
									{this.props.createForm["enable_percentage"] ==
									true
										? "%"
										: "$"}
									)
								</Form.Label>

								<Form.Control
									type="number"
									max={
										this.props.createForm[
											"enable_percentage"
										] == true
											? 100
											: 1000000
									}
									min={0}
									onChange={this.props.createFormHandler}
									name="value"
									defaultValue={this.props.createForm["value"]}
									placeholder="Enter Value"
								/>
							</Form.Group>
						</div>
					</Form>
				</Modal.Body>

				<Modal.Footer>
					<div className="form-row">
						<Button
							type="button"
							onClick={this.props.onHide}
							variant="outline-primary"
							className="d-flex w-50 text-center justify-content-center"
						>
							CANCEL
						</Button>
						<Button
							type="button"
							onClick={(e) => {
								if (this.props.addMethod == true) {
									this.props.addExtraPayment(e);
								} else {
									this.props.updateExtraPayment(e);
								}
							}}
							variant="primary"
							className="d-flex w-50 text-center justify-content-center"
						>
							ADD
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		);
	}
}
export default ExtraPaymentModel;
