import React from "react";
import { Row, Col } from "react-bootstrap";
import CoupenCard from "../common/CouponCard";
import DeleteAddressModal from "../modals/DeleteAddressModal";
import Icofont from "react-icofont";
import { NavLink, Link } from "react-router-dom";
import { NavDropdown, Nav } from "react-bootstrap";
import DropDownTitle from "../common/DropDownTitle";
import DataService from "../../services/DataService";
import {
	confirmDeleteAction,
	alertError,
	alertWarning,
	alertCreation,
	alertDeletion,
} from "../../components/Alerts";
import SimpleReactValidator from "simple-react-validator";
import { data } from "jquery";
import CoupenModel from "../modals/CoupenModel";

class Coupens extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.dataHandler = new DataService("coupens");
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });

		this.state = {
			title: "New Coupon",
			itemsDisplayed: [],
			createForm: {
				enable_takeout: true,
				enable_delivery: true,
				enable_percentage: true,
			},
			selectedItem: {},
			validator: this.validator,
			addMethod: true,
			showDeleteModal: false,
			showAddCoupen: false,
			selectedRestaurant: this.dataHandler.getSelectedRestaurant(),
		};
		this.reloadData();
		this.createFormHandler = this.createFormHandler.bind(this);
		this.showUpdateCoupenModel = this.showUpdateCoupenModel.bind(this);
		this.addFoodCoupen = this.addFoodCoupen.bind(this);
		this.deleteConfirm = this.deleteConfirm.bind(this);
		this.updateFoodCoupen = this.updateFoodCoupen.bind(this);
	}
	deleteConfirm = (id, lengthItems) => {
		confirmDeleteAction("Coupon").then((userResponse) => {
			if (userResponse.isConfirmed == true) {
				this.deleteCoupen(id);
			}
		});
	};

	deleteCoupen = (id) => {
		let inputParams = {};
		inputParams["id"] = id;
		this.dataHandler
			.deleteData(inputParams)
			.then((response) => {
				console.log(response);

				if (response.data["status"] === "sucess") {
					alertDeletion("Coupon");
					this.reloadData();
				} else {
					alertError(response.data["message"]);
				}
			})
			.catch((err) => alert(err));
	};

	createFormHandler = (event) => {
		let key = "";
		let value = "";

		let inputForm = { ...this.state.createForm };
		if ((event.target.type == "text") | (event.target.type == "number")) {
			key = event.target.name;
			value = event.target.value;
		} else if (event.target.type == "date") {
			key = event.target.name;
			value = event.target.value;
		} else if (event.target.type == "select") {
			key = event.target.name;
			value = event.target.value;
		} else if (event.target.type == "checkbox") {
			key = event.target.name;
			value = event.target.checked;
		}

		inputForm[key] = value;
		console.log(inputForm);
		this.setState({ createForm: inputForm });
	};

	updateFoodCoupen = (e) => {
		if (this.validator.allValid() == true) {
			let inputParams = this.state.createForm;

			console.log(inputParams);
			this.dataHandler
				.updateData(inputParams, this.state.selectedItem.id)
				.then((response) => {
					if (response.data["status"] === "Sucess") {
						alertCreation("Coupon");
						this.setState({ createForm: {} });
						this.hideCoupenNew();

						this.reloadData();
					} else {
						alertError(response.data["message"]);
					}
				})
				.catch((err) => alert(err));
		} else {
			this.validator.showMessages();
		}
	};

	addFoodCoupen = (e) => {
		if (this.validator.allValid() == true) {
			let inputParams = this.state.createForm;
			if (this.state.selectedRestaurant != 0) {
				inputParams = {
					rest_id: this.state.selectedRestaurant,
					...inputParams,
				};
			}
			let formData = new FormData(); //formdata object
			Object.keys(inputParams).forEach(function (key) {
				formData.append(key, inputParams[key]); //append the values with key, value pair
			});

			this.dataHandler
				.createDataFromForm(formData)
				.then((response) => {
					if (response.data["status"] === "Sucess") {
						alertCreation("Coupon");
						this.hideCoupenNew();
						this.reloadData();
					} else {
						alertError(response.data["message"]);
					}
				})
				.catch((err) => alert(err));
		} else {
			this.validator.showMessages();
		}
	};

	comparePriority(a, b) {
		if (a.priority < b.priority) {
			return -1;
		}
		if (a.priority > b.priority) {
			return 1;
		}
		return 0;
	}

	showUpdateCoupenModel = (coupenObj) => {
		let inputParams = {
			title: coupenObj.title,
			coupen_code: coupenObj.coupen_code,
			start_date: coupenObj.start_date,
			end_date: coupenObj.end_date,
			enble_order_limit: coupenObj.enble_order_limit,
			enable_percentage: coupenObj.enable_percentage,
			enable_takeout: coupenObj.enable_takeout,

			enable_delivery: coupenObj.enable_delivery,

			coupen_discount: coupenObj.coupen_discount,
			total_coupens: coupenObj.total_coupens,
		};
		this.setState({
			selectedItem: coupenObj,
			addMethod: false,
			showAddCoupen: true,
			createForm: inputParams,
		});
	};

	/*
    fetch elements from server and reload data elements
    */
	reloadData = () => {
		let inputParams = {};
		if (this.state.selectedRestaurant != 0) {
			inputParams = { rest_id: this.state.selectedRestaurant };
		}
		this.dataHandler.getData(inputParams).then((response) => {
			let currentData = response.data.data;
			console.log(currentData);
			this.setState({ itemsDisplayed: currentData, addMethod: true });
		});
	};

	hideCoupenNew = () =>
		this.setState({
			title: "New Coupon",
			createForm: {
				enable_percentage: true,
			},
			selectedItem: {},
			validator: this.validator,
			addMethod: true,
			showDeleteModal: false,
			showAddCoupen: false,
		});
	hideDeleteModal = () => this.setState({ showDeleteModal: false });
	render() {
		return (
			<>
				<CoupenModel
					show={this.state.showAddCoupen}
					createFormHandler={this.createFormHandler}
					createForm={this.state.createForm}
					addFoodCoupen={this.addFoodCoupen}
					updateFoodCoupen={this.updateFoodCoupen}
					addMethod={this.state.addMethod}
					title={this.state.title}
					validator={this.state.validator}
					onHide={this.hideCoupenNew}
				/>
				<DeleteAddressModal
					show={this.state.showDeleteModal}
					onHide={this.hideDeleteModal}
				/>
				<div className="p-4 bg-white shadow-sm">
					<Row>
						<Col md={12}>
							<Nav
								activeKey={0}
								className="ml-auto"
								onSelect={this.closeMenu}
							>
								<Nav.Link
									eventKey={0}
									as={NavLink}
									activeclassname="active"
									exact
									to=""
								>
									<h4 className="font-weight-bold mt-0 mb-4">
										{" "}
										Coupon List{" "}
									</h4>
									<span className="sr-only">(current)</span>
								</Nav.Link>

								<Link
									to="#"
									onClick={() =>
										this.setState({ showAddCoupen: true })
									}
									className="text-primary mr-3 mt-3"
								>
									<i className="icofont-plus-circle"></i> NEW
									Coupon
								</Link>
							</Nav>
						</Col>

						{this.state.itemsDisplayed.map((dataObj, idx) => (
							<Col md={6}>
								<CoupenCard
									title={dataObj.title}
									coupenObj={dataObj}
									onHide={this.hideCoupenNew}
									showUpdateCoupenModel={
										this.showUpdateCoupenModel
									}
									imageclassName="mr-3"
									subTitle={dataObj.coupen_code}
									onClick={() => {
										this.deleteConfirm(dataObj.id);
									}}
								/>
							</Col>
						))}
					</Row>
				</div>
			</>
		);
	}
}
export default Coupens;
