import React from "react";
import SimpleReactValidator from "simple-react-validator";
import { Modal, Form, InputGroup, Row, Col, Table, Accordion, Card, Button } from "react-bootstrap";
import InputRange from "react-input-range";
import Icofont from "react-icofont";

class DishCustomizationModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.tabValidator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            selectedCustomizationTabType: this.props.currentEditTab ? 
                                          this.props.currentEditTab.tab_type :  
                                          '1',
            tabCreateForm: this.props.currentEditTab ? 
                           this.props.currentEditTab : 
                           { tab_type: '1', number_of_items_required: 2, max_number_of_items_required: 4 },
            value: this.getItemRequiredLimits(),
            showTabForm: true,
            sideDishForm: this.props.currentEditTab ? this.props.currentEditTab.upsell_items : [],
            suggestionForm: this.props.currentEditTab ? this.props.currentEditTab.custom_questions : [],
        }
        
    }

    getItemRequiredLimits = ()=> {
        let value = { min: 2, max:4 }
        let currentTabForm = this.props.currentEditTab;
        if (currentTabForm && currentTabForm.enable_quantity_limits) {
            value["min"] = currentTabForm.number_of_items_required;
            value["max"] = currentTabForm.max_number_of_items_required;
        }

        return value
    }

    hideTabForm = () => {
        this.setState({showTabForm:false})
    }

    showTabForm = () => {
        this.setState({showTabForm:true})
    }

    updateSuggestionForm = (form) => {
        this.setState({suggestionForm: form})
    }

    updateSideDishForm = (form) => {
        this.setState({sideDishForm: form})
    }

    createFormHandler = (event) => {
        let key = "";
        let value = "";
        let inputForm = { ...this.state.tabCreateForm };
    
        if (
          event.target.type === "text" ||
          event.target.type === "textarea" ||
          event.target.type === "number"
        ) {
          key = event.target.name;
          value = event.target.value;
        } else if (event.target.type === "select-one") {
          key = event.target.name;
    
          value = event.target.value;
        }
        else if (event.target.type === "checkbox") {
          key = event.target.name;
    
          value = event.target.checked;
        }
        inputForm[key] = value;
        this.setState({ tabCreateForm: inputForm });
    };

    onChangeCustomizationTabType = (event) => {
        let value = event.target.value
        this.setState({selectedCustomizationTabType: value});
    }

    isTabFormValid() {
        return (
          this.tabValidator.fieldValid("tab_title") &&
          this.tabValidator.fieldValid("tab_type")
        );
    }

    

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} >
                { this.state.showTabForm && 
                <Modal.Header closeButton={true}>
                    <Modal.Title as='h6'>Add Dish Customization</Modal.Title>
                    { !this.props.currentEditTab && 
                    <button style={{width:'43%',backgroundColor:'#9BB341',fontSize:16,color:'white',marginLeft:30}}
                    onClick={()=>{
                        this.props.addNewTab(
                            this.state.tabCreateForm,
                            this.state.selectedCustomizationTabType,
                            this.state.sideDishForm,
                            this.state.suggestionForm
                        )
                    }}
                    >
                        Create Customization Tab
                    </button>
                    }
                    { this.props.currentEditTab && 
                    <button style={{width:'43%',backgroundColor:'#9BB341',fontSize:16,color:'white',marginLeft:30}}
                    onClick={()=>{
                        this.props.updateTab(
                            this.state.tabCreateForm,
                            this.state.selectedCustomizationTabType,
                            this.state.sideDishForm,
                            this.state.suggestionForm
                        )
                    }}
                    >
                        Update
                    </button>
                    }
                </Modal.Header>
                }
                <Modal.Body>
                    { this.state.showTabForm && 
                    <div className="bg-white rounded shadow-sm p-4 osahan-payment">
                        <Form.Group className="col-md-12">
                            <Form.Label>Tab Title</Form.Label>
                            {
                                this.tabValidator.message(
                                    "tab_title",
                                    this.state.tabCreateForm["tab_title"],
                                    "required|min:2|max:15"
                                )
                            }
                            <InputGroup>
                                <Form.Control
                                type="text"
                                onChange={this.createFormHandler}
                                name="tab_title"
                                placeholder="Enter Customization Title"
                                value={this.state.tabCreateForm["tab_title"]}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            <Form.Label>Tab Type</Form.Label>
                            {
                                this.tabValidator.message(
                                    "tab_type",
                                    this.state.tabCreateForm["tab_type"],
                                    "required"
                                )
                            }
                            <Form.Control
                            as="select"
                            onChange={(event)=> {
                                this.onChangeCustomizationTabType(event);
                                this.createFormHandler(event);
                            }}
                            name="tab_type"
                            value={this.state.tabCreateForm["tab_type"]}
                            >
                                <option value="1">
                                    Add side dish or extra food items as customization
                                </option>
                                <option value="2">
                                    Add suggestion as customization
                                </option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            <Form.Check
                            type="checkbox"
                            name="is_mandatory"
                            label="Make this a mandatory customaization"
                            checked= {this.state.tabCreateForm['is_mandatory']}
                            onClick={this.createFormHandler}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            <Form.Check
                            type="checkbox"
                            name="enable_quantity_limits"
                            label="Apply limit on customer choices - (Min-Max number of customer choices)"
                            checked= {this.state.tabCreateForm['enable_quantity_limits']}
                            onClick={this.createFormHandler}
                            />
                        </Form.Group>
                        { (this.state.tabCreateForm['enable_quantity_limits']===true) &&
                        <Form.Group className="col-md-12">
                            <InputRange
                            maxValue={10}
                            minValue={0}
                            allowSameValues={true}
                            value={this.state.value}
                            onChange={
                            (value)=>{
                                let inputForm = { ...this.state.tabCreateForm };
                                inputForm['number_of_items_required']=value.min;
                                inputForm['max_number_of_items_required']=value.max;
                                this.setState({tabCreateForm:inputForm,value:value});

                            }
                            }/>
                        </Form.Group>
                        }

                    </div>
                    }
                    <div>
                        { (this.state.selectedCustomizationTabType ==='1' || this.state.selectedCustomizationTabType ===1) && 
                        <SideDishForm 
                        hideTabForm={this.hideTabForm}
                        showTabForm={this.showTabForm}
                        optionsFood={this.props.optionsFood}
                        updateSideDishForm={this.updateSideDishForm}
                        currentEditTab={this.props.currentEditTab}
                        />
                        }
                        { (this.state.selectedCustomizationTabType ==='2' || this.state.selectedCustomizationTabType ===2) && 
                        <SuggestionForm 
                        hideTabForm={this.hideTabForm}
                        showTabForm={this.showTabForm}
                        updateSuggestionForm={this.updateSuggestionForm}
                        currentEditTab={this.props.currentEditTab}
                        />
                        }
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

class SideDishForm extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        // this.getFoodPrice = this.props.getFoodPrice;
        this.state = {
            addedItemscreateForm: { free_available: false},
            currentItems: this.props.currentEditTab ? this.props.currentEditTab.upsell_items : [],
            currentAction: 'show_list',
            selectedItemIndex: null
        };
        
    }

    cancelSideDishActions = () => {
        this.setState({ addedItemscreateForm: { free_available: false,food_item:0} });
        this.setState({currentAction:'show_list'})
        this.props.showTabForm()
    }

    getFoodPrice(val) {
        console.log(val);
        let foodObj = this.props.optionsFood.find((f) => f.id == val);
        console.log(foodObj);
        let price = typeof foodObj != "undefined" ? foodObj.price : 0;
        return price;
    }

    getFoodName(val) {
        console.log(val);
        let foodObj = this.props.optionsFood.find((f) => f.id == val);
        let name = typeof foodObj != "undefined" ? foodObj.name : "unknown";
        return name;
    }

    isFoodItemFormValid() {
        return (
          this.validator.fieldValid("food_item")
        );
    }

    addNewSideDish = () => {
        if (this.isFoodItemFormValid() === true && this.state.addedItemscreateForm["food_item"]>0) {
            let currentItems = [...this.state.currentItems];
            let currentForm = {...this.state.addedItemscreateForm};
            currentForm["added"] = true;
            currentItems.push(currentForm);
            this.setState({currentItems: currentItems})
            this.setState({ addedItemscreateForm: { free_available: false,food_item:0} });
            this.setState({currentAction:'show_list'})
            this.props.updateSideDishForm(currentItems)
            this.props.showTabForm()
        } else {
            this.validator.showMessages();
        }
    }

    updateSideDish = () => {
        if (this.isFoodItemFormValid() === true && this.state.addedItemscreateForm["food_item"]>0) {
            this.setState({currentAction:'create'});
            let currentItems = [...this.state.currentItems];
            currentItems[this.state.selectedItemIndex] = this.state.addedItemscreateForm;
            currentItems[this.state.selectedItemIndex]["updated"] = true;
            this.setState({currentItems: currentItems})
            this.setState({ addedItemscreateForm: { free_available: false,food_item:0} });
            this.setState({currentAction:'show_list'})
            this.props.updateSideDishForm(currentItems)
            this.props.showTabForm()
        } else {
            this.validator.showMessages();
        }
        
    }

    removeSideDish = (index) => {
        let currentItems = [...this.state.currentItems];
        let sideDish = currentItems[index];
        if (sideDish["added"]===true) {
            currentItems.splice(index,1)
        }
        else {
            currentItems[index]["removed"] = true;
        }
        this.setState({currentItems: currentItems})
        this.props.updateSideDishForm(currentItems)
    }

    onClickUpdateSideDish = (index) => {
        this.setState({currentAction:'update'})
        this.setState({ addedItemscreateForm: this.state.currentItems[index] });
        this.setState({selectedItemIndex:index})
        this.props.hideTabForm()
    }

    //to handler for added Items in Form
    createAddedItemsFormHandler = (event) => {
        let key = "";
        let value = "";
        let inputForm = { ...this.state.addedItemscreateForm };
        if (
        event.target.type === "text" ||
        event.target.type === "number" ||
        event.target.type === "textarea"
        ) {
        key = event.target.name;
        value = event.target.value;
        } else if (event.target.type === "select-one") {
        key = event.target.name;
        value = event.target.value;
        if (key === "food_item" && inputForm["free_available"] === false) {
            inputForm["item_price"] = this.getFoodPrice(value);
        }
        } else if (event.target.type === "checkbox") {
        key = event.target.name;
        value = event.target.checked;
        if (value === true) {
            delete inputForm["item_price"];
        }
        }

        inputForm[key] = value;
        this.setState({ addedItemscreateForm: inputForm });
    };

    onClickAddSideDish = () => {
        this.setState({currentAction:'create'})
        this.props.hideTabForm()
    }

    render() {
        return (
            <>
            { this.state.currentAction!=='show_list' && 
            <Form>
                <h6 style={{color:'#9BB341'}}>
                    Side Dish Customization
                </h6>
                <div className="form-row">
                    <Form.Group className="col-md-12">
                        <Form.Label>Select a side dish</Form.Label>
                        {this.validator.message(
                        "food_item",
                        this.state.addedItemscreateForm["food_item"],
                        "required"
                        )}
                        <Form.Control
                        as="select"
                        name="food_item"
                        onChange={this.createAddedItemsFormHandler}
                        value={this.state.addedItemscreateForm["food_item"]}
                        style={{color: this.state.addedItemscreateForm["food_item"]>0?'black':'red'}}
                        >
                            <option style={{color:'red'}} value={0}>Select A Side Dish</option>
                            {this.props.optionsFood.map((foodObj) => {
                            return <option style={{color:'black'}} value={foodObj.id}>{foodObj.name}</option>;
                            })}
                            
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="col-md-12">
                        <Form.Check
                        type="checkbox"
                        name="free_available"
                        onChange={this.createAddedItemsFormHandler}
                        label="Make this side dish as Free"
                        checked={this.state.addedItemscreateForm["free_available"]===true ?true:false}
                        />
                    </Form.Group>
                    {this.state.addedItemscreateForm["free_available"] === false && (
                    <Form.Group className="col-md-12">
                        <Form.Label>Provide price for side dish</Form.Label>
                        <InputGroup>
                            <Form.Control
                            type="number"
                            onChange={this.createAddedItemsFormHandler}
                            name="item_price"
                            placeholder="Item Price"
                            value={this.state.addedItemscreateForm["item_price"]}
                            />
                        </InputGroup>
                    </Form.Group>
                    )}
                    { this.state.currentAction==='create' && 
                        <button type="button" 
                        style={{width:'100%',height:30,backgroundColor:'#9BB341',color:'white',fontSize:12}}
                        onClick={this.addNewSideDish}
                        >
                            Add Side Dish
                            
                        </button>
                    }
                    { this.state.currentAction==='update' && 
                        <button type="button" 
                        style={{width:'100%',height:30,backgroundColor:'#9BB341',color:'white',fontSize:12}}
                        onClick={this.updateSideDish}
                        >
                            Update Side Dish
                            
                        </button>
                    }
                    <button type="button" 
                    style={{width:'100%',marginTop:10,backgroundColor:'#9BB341',color:'white',fontSize:16}}
                    onClick={this.cancelSideDishActions}
                    >
                        Cancel
                        
                    </button>
                </div>
            </Form>
            }        
            { this.state.currentAction==='show_list' && 
            <div className="bg-white rounded shadow-sm p-4 osahan-payment col-md-12"
            >
                <Row style={{marginBottom: 5}}>
                    <h5>Added Side Dishes</h5>
                    <button type="button" style={{marginLeft: 20,backgroundColor:'#9BB341',color:'white',fontSize:16}}
                    onClick={this.onClickAddSideDish}
                    >
                    Add New Side Dish
                    </button>
                </Row>
                { this.state.currentItems.length>0 && 
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Available Free </th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        { (typeof (this.state.currentItems) != "undefined") && 
                            this.state.currentItems.map( (item,index) => {
                                if(!item.removed){
                                    return (
                                        <tr>
                                            <td>
                                                {" "}
                                                {this.getFoodName(item.food_item)}
                                            </td>
                                            <td>
                                                {item.free_available && "Yes"}
                                                {!item.free_available && "No"}
                                            </td>
                                            <td>{item.item_price}</td>
                                            <td>
                                                <button type="button" onClick={()=>{
                                                    this.onClickUpdateSideDish(index)
                                                }}>
                                                    <Icofont icon="pencil" />
                                                </button>
                                                <button type="button" style={{marginLeft:3,color:'red'}}
                                                onClick={()=>{
                                                    this.removeSideDish(index)
                                                }}
                                                >
                                                    <Icofont icon="trash" />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            })

                        }
                    </tbody>
                </Table>
                }
            </div>
            }
            </>
        )
    }
}

class SuggestionForm extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.validator = new SimpleReactValidator({ autoForceUpdate: this });
        this.state = {
            currentQuestions: this.props.currentEditTab ? this.props.currentEditTab.custom_questions : [],
            addedItemscreateForm: { answers: [],question_type:"2" },
            answerCreateForm: { free_available: false,item_price: 0 },
            currentAnswerAction: 'create',
            currentQuestionAction: 'show_list',
            selectedAnswerIndex: null,
            selectedQuestionIndex: null
        };
    }


    cancelQuestionActions = () => {
        this.setState({addedItemscreateForm: { answers: [],question_type:"2" }})
        this.setState({answerCreateForm: { free_available: false,item_price: 0 }})
        this.setState({currentQuestionAction:'show_list'})
        this.props.showTabForm()
    }

    //to handler for added Items in Form
    createAddedItemsFormHandler = (event) => {
        let key = "";
        let value = "";
        let inputForm = { ...this.state.addedItemscreateForm };
        if (
        event.target.type === "text" ||
        event.target.type === "number" ||
        event.target.type === "textarea"
        ) {
        key = event.target.name;
        value = event.target.value;
        } else if (event.target.type === "select-one") {
        key = event.target.name;
        value = event.target.value;
        if (key === "question_type") {
            /*if (value == "1") {
            delete inputForm["answers"];
            inputForm["answers"] = [];
            inputForm["answers"] = [
                { answer_title: "Yes", free_available: true, item_price: 0 },
                { answer_title: "No", free_available: true, item_price: 0 },
            ];
            } else {*/
            inputForm["answers"].map((answer,index)=>{
                if (answer["added"]===true) {
                    inputForm["answers"].splice(index,1)
                }
                else {
                    inputForm["answers"][index]["removed"] = true;
                }
            })
            // delete inputForm["answers"];

            // inputForm["answers"] = [];
            
        }
        } else if (event.target.type === "checkbox") {
        key = event.target.name;
        value = event.target.checked;
        }

        inputForm[key] = value;
        this.setState({ addedItemscreateForm: inputForm });
    };

    isQuestionFormValid() {
        return (
            this.validator.fieldValid("question_title")
        );
    }

    addNewQuestion = () => {
        if (this.isQuestionFormValid() === true && this.state.addedItemscreateForm.answers.length>0) {
            let currentItems = [...this.state.currentQuestions]
            let currentQuestion = {...this.state.addedItemscreateForm };
            currentQuestion["added"] = true;
            currentItems.push(currentQuestion)
            this.setState({currentQuestions: currentItems})
            this.setState({addedItemscreateForm: { answers: [],question_type:"2" }})
            this.setState({answerCreateForm: { free_available: false,item_price: 0 }})
            this.setState({currentQuestionAction:'show_list'})
            this.props.updateSuggestionForm(currentItems)
            this.props.showTabForm()
        }
        else {
            // this.validator.showMessages();
        }
    }

    updateQuestion = () => {
        if (this.isQuestionFormValid() === true && this.state.addedItemscreateForm.answers.length>0) {
            this.setState({currentQuestionAction:'create'});
            let currentItems = [...this.state.currentQuestions]
            currentItems[this.state.selectedQuestionIndex] = this.state.addedItemscreateForm;
            currentItems[this.state.selectedQuestionIndex]["updated"] = true;
            this.setState({currentQuestions: currentItems})
            this.setState({addedItemscreateForm: { answers: [],question_type:"2" }})
            this.setState({answerCreateForm: { free_available: false,item_price: 0 }})
            this.setState({currentQuestionAction:'show_list'})
            this.props.updateSuggestionForm(currentItems)
            this.props.showTabForm()
        } else {
            // this.validator.showMessages();
        }
    }

    onClickUpdateQuestion = (index) => {
        this.setState({currentQuestionAction:'update'})
        this.setState({ addedItemscreateForm: this.state.currentQuestions[index] });
        this.setState({selectedQuestionIndex:index})
        this.props.hideTabForm()
    }

    onClickAddQuestion = () => {
        this.setState({currentQuestionAction:'create'})
        this.props.hideTabForm()
    }

    removeQuestion = (index) => {
        let currentItems = [...this.state.currentQuestions]
        let question = currentItems[index];
        if (question["added"]===true) {
            currentItems.splice(index,1)
        }
        else {
            currentItems[index]["removed"] = true;
        }
        this.setState({currentQuestions: currentItems})
        this.props.updateSuggestionForm(currentItems)
    }

    //to handler for added Items in Form
    createAnswerFormHandler = (event) => {
        let key = "";
        let value = "";
        // let inputParams = {...this.state.addedItemscreateForm };
        let answerForm={...this.state.answerCreateForm}
  
        if (
          event.target.type === "text" ||
          event.target.type === "number" ||
          event.target.type === "textarea"
        ) {
          key = event.target.name;
          value = event.target.value;
        }  else if (event.target.type === "checkbox") {
          key = event.target.name;
          value = event.target.checked;
        }
    
        answerForm[key] = value;
        this.setState({answerCreateForm:answerForm})
        // inputParams.answers.push(answerForm)
        // console.log(inputParams);
        // this.setState({ addedItemscreateForm: inputParams });
    };

    isAnswerFormValid() {
        return (
            this.validator.fieldValid("answer_title")
        );
    }

    addNewAnswer = () => {
        if (this.isAnswerFormValid() === true) {
            let currentQuestion = {...this.state.addedItemscreateForm };
            let currentAnswer = {...this.state.answerCreateForm}
            currentAnswer["added"] = true;
            currentQuestion.answers.push(currentAnswer)
            this.setState({addedItemscreateForm: currentQuestion})
            this.setState({answerCreateForm: { free_available: false,item_price: 0,answer_title:'' }})
        }
        else {
            // this.validator.showMessages();
        }
    }

    updateAnswer = () => {
        if (this.isAnswerFormValid() === true) {
            this.setState({currentAnswerAction:'create'});
            let currentQuestion = {...this.state.addedItemscreateForm };
            currentQuestion.answers[this.state.selectedAnswerIndex] = this.state.answerCreateForm;
            currentQuestion.answers[this.state.selectedAnswerIndex]["updated"] = true
            currentQuestion["updated"]=true;
            this.setState({addedItemscreateForm: currentQuestion})
            this.setState({answerCreateForm: { free_available: false,item_price: 0,answer_title:'' }})

        } else {
            // this.validator.showMessages();
        }
    }

    onClickUpdateAnswer = (index) => {
        this.setState({currentAnswerAction:'update'})
        this.setState({ answerCreateForm: this.state.addedItemscreateForm.answers[index] });
        this.setState({selectedAnswerIndex:index})
    }

    removeAnswer = (index) => {
        let currentQuestion = {...this.state.addedItemscreateForm };
        let answer = currentQuestion.answers[index];
        if (answer["added"]===true) {
            currentQuestion.answers.splice(index,1)
        }
        else {
            currentQuestion.answers[index]["removed"] = true;
        }
        this.setState({addedItemscreateForm: currentQuestion})
        this.setState({currentAnswerAction:'create'});
        this.setState({answerCreateForm: { free_available: false,item_price: 0,answer_title:'' }})
    }

    render() {
        return (
            <>
            { this.state.currentQuestionAction!=='show_list' && 
            <Form>
                <h6 style={{color:'#9BB341'}}>
                    Suggestion Customization ( Question & Choice Answers )
                </h6>
                <div className="form-row">
                    <div style={{borderStyle: 'solid',borderColor:'#9BB341', padding:12, marginTop:10}} className="col-md-12">
                        <Form.Group className="col-md-12">
                            <Form.Label>Provide a question to ask</Form.Label>
                            {this.validator.message(
                            "question_title",
                            this.state.addedItemscreateForm["question_title"],
                            "required"
                            )}
                            <Form.Control
                            type="textarea"
                            onChange={this.createAddedItemsFormHandler}
                            name="question_title"
                            placeholder="Enter Question"
                            value={this.state.addedItemscreateForm["question_title"]}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            <Form.Label>Select question type</Form.Label>
                            <Form.Control
                            as="select"
                            name="question_type"
                            onChange={this.createAddedItemsFormHandler}
                            value={this.state.addedItemscreateForm["question_type"]}
                            >
                                <option value="2">Multiple Choices</option>
                                <option value="1">Single Choice</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div style={{borderStyle: 'solid',borderColor:'#9BB341', padding:12, marginTop:10}} className="col-md-12">
                        <Form.Group className="col-md-12">
                            <Form.Label>Choice</Form.Label>
                            {this.validator.message(
                            "answer_title",
                            this.state.answerCreateForm["answer_title"],
                            "required"
                            )}
                            <Form.Control
                            type="textarea"
                            onChange={this.createAnswerFormHandler}
                            name="answer_title"
                            placeholder="Enter Choice Answer"
                            value={this.state.answerCreateForm["answer_title"]}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-12">
                            <Form.Check
                            type="checkbox"
                            name="free_available"
                            label="Make this customaization as free"
                            checked={this.state.answerCreateForm["free_available"]}
                            onChange={this.createAnswerFormHandler}
                            />
                        </Form.Group>
                        { this.state.answerCreateForm["free_available"]===false && 
                        <Form.Group className="col-md-12">
                            <Form.Label>Price for this customization</Form.Label>
                            <InputGroup>
                                <Form.Control
                                type="number"
                                name="item_price"
                                placeholder="Enter price"
                                onChange={this.createAnswerFormHandler}
                                value={this.state.answerCreateForm["item_price"]}
                                />
                            </InputGroup>
                        </Form.Group>
                        }
                        
                        { this.state.currentAnswerAction==='create' && 
                        <button type="button" className="col-md-12"
                        style={{backgroundColor:'#9BB341',color:'white',fontSize:16}}
                        onClick={this.addNewAnswer}
                        >
                            Add choice answer
                            
                        </button>
                        }
                        { this.state.currentAnswerAction==='update' && 
                        <button type="button" className="col-md-12"
                        style={{backgroundColor:'#9BB341',color:'white',fontSize:16}}
                        onClick={this.updateAnswer}
                        >
                            Update choice answer
                            
                        </button>
                        }
                    </div>

                    <h7 className="col-md-12" style={{marginTop:5}}>Provided Choices For Above Question</h7>
                    <div className="col-md-12">
                        { !this.state.addedItemscreateForm["answers"].length>0 && 
                        <h8 style={{color:'red'}}>empty, please add choices</h8>
                        }
                        { this.state.addedItemscreateForm["answers"].length>0 && 
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Choice</th>
                                    <th>Free</th>
                                    <th>Charge</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.state.addedItemscreateForm["answers"].map((answer,index)=>{
                                    if(!answer.removed){
                                        return (
                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{answer.answer_title}</td>
                                                <td>
                                                    { answer.free_available && "Yes"}
                                                    { !answer.free_available && "No"}
                                                </td>
                                                <td>{answer.item_price}</td>
                                                <td>
                                                    <button type="button" onClick={()=>{
                                                        this.onClickUpdateAnswer(index)
                                                    }}>
                                                        <Icofont icon="pencil" />
                                                    </button>
                                                    <button type="button" style={{marginLeft:10,color:'red'}}
                                                    onClick={()=>{
                                                        this.removeAnswer(index)
                                                    }}
                                                    >
                                                        <Icofont icon="trash" />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                })

                                }
                            </tbody>
                        </Table>
                        }
                        
                    </div>

                    { this.state.currentQuestionAction==='create' && 
                    <button type="button" 
                    style={{width:'100%',backgroundColor:'#9BB341',color:'white',fontSize:16}}
                    onClick={this.addNewQuestion}
                    >
                        Add Question & Choice Answers
                        
                    </button>
                    }
                    { this.state.currentQuestionAction==='update' && 
                    <button type="button" 
                    style={{width:'100%',backgroundColor:'#9BB341',color:'white',fontSize:16}}
                    onClick={this.updateQuestion}
                    >
                        Update Question & Choice Answers
                        
                    </button>
                    }
                    <button type="button" 
                    style={{width:'100%',marginTop:10,backgroundColor:'#9BB341',color:'white',fontSize:16}}
                    onClick={this.cancelQuestionActions}
                    >
                        Cancel
                        
                    </button>
                </div>
            </Form>
            }
            
            { this.state.currentQuestionAction==='show_list' && 
            <div className="bg-white rounded shadow-sm p-4 osahan-payment">
                <Row style={{marginBottom: 5}}>
                    <h5>Added Questions</h5>
                    <button type="button" style={{marginLeft: 20,backgroundColor:'#9BB341',color:'white',fontSize:16}}
                    onClick={this.onClickAddQuestion}
                    >
                    Add New Question
                    </button>
                </Row>
                <Accordion>
                {
                    this.state.currentQuestions.map( (question,index) => {
                    if(!question.removed){
                        return (
                            <>
                            <Card>
                            <Card.Header>
                                <Row>
                                <Accordion.Toggle as={Button} variant="link" eventKey={index}>
                                    <Icofont icon="caret-down" /> 
                                    {question.question_title}
                                </Accordion.Toggle>
                                <div style={{marginTop:10, marginLeft: 'auto', marginRight: 0}}>
                                    { (question.question_type==='2' || question.question_type===2) && 
                                    <h8 style={{marginLeft: 10}}>Multiple Choices</h8>
                                    }
                                    { (question.question_type==='1' || question.question_type===1) && 
                                    <h8 style={{marginLeft: 10}}>Single Choice</h8>
                                    }
                                    {/* <button type="button" style={{marginLeft: 20,backgroundColor:'#9BB341',color:'white',fontSize:16}}
                                    onClick={()=>{
                                        this.onClickUpdateQuestion(index)
                                    }}
                                    >
                                        Edit Question & Choices
                                    </button> */}

                                    <button type="button" style={{marginLeft:30}}
                                    onClick={()=>{
                                        this.onClickUpdateQuestion(index)
                                    }}>
                                        <Icofont icon="pencil" />
                                    </button>
                                    <button type="button" style={{marginLeft:30,color:'red'}}
                                    onClick={()=>{
                                        this.removeQuestion(index)
                                    }}
                                    >
                                        <Icofont icon="trash" />
                                    </button>
                                </div>
                                </Row>
                            </Card.Header>
                            <Accordion.Collapse eventKey={index}>
                                <Card.Body>
                                <div>
                                    <h7 style={{color: '#9BB341'}}>Choice Answers</h7>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Sl.No</th>
                                                <th>Choice</th>
                                                <th>Free</th>
                                                <th>Charge</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { question.answers.map((answer,index)=>{
                                                if(!answer.removed){
                                                    return (
                                                        <tr>
                                                            <td>{index+1}</td>
                                                            <td>{answer.answer_title}</td>
                                                            <td>
                                                                { answer.free_available && "Yes"}
                                                                { !answer.free_available && "No"}
                                                            </td>
                                                            <td>{answer.item_price}</td>
                                                        </tr>
                                                    )
                                                }
                                            })

                                            }
                                        </tbody>
                                    </Table>
                                </div>
                                </Card.Body>
                            </Accordion.Collapse>
                            </Card>
                            {/* <br/> */}
                            </>
                        )
                    }
                    })
                }
                </Accordion>
            </div>
            }
            </>
        )
    }
}

class TabGroupModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            taggedGroups: [...this.props.selectedGroups],
            groups: this.props.groups,
            currentNewGroupTitle: ""
        }
        this.taggedGroups = [...this.props.selectedGroups];
        this.removedGroups = []
        this.newGroups = []
        this.updatingGroups = {}
    }

    addNewGroup = ()=> {
        if (this.state.currentNewGroupTitle.length>0) {
            this.props.createCustomizationGroup({title:this.state.currentNewGroupTitle})
            this.setState({currentNewGroupTitle:""})
        }
    }

    updateGroup = (groupId,index)=> {
        let updatingGroup = this.updatingGroups[groupId]
        this.props.updateCustomizationGroup(updatingGroup,index)
    }

    updateTabGroup = (event,groupId) => {
        if (event.target.checked===true && !this.taggedGroups.includes(groupId)) {
            this.taggedGroups.push(groupId)
            if (!this.props.selectedGroups.includes(groupId) && !this.newGroups.includes(groupId)) {
                this.newGroups.push(groupId)
            }
        }
        if (event.target.checked===false && this.taggedGroups.includes(groupId)) {
            const index = this.taggedGroups.indexOf(groupId);
            if (index > -1) {
                this.taggedGroups.splice(index, 1);
            }
            if (this.props.selectedGroups.includes(groupId) && !this.removedGroups.includes(groupId)) {
                this.removedGroups.push(groupId)
            }
        }
        this.setState({taggedGroups:this.taggedGroups})
        console.log(this.taggedGroups)
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} >
                <Modal.Header closeButton={true}>
                    <Modal.Title as='h6'>Group Selected Tabs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="bg-white rounded shadow-sm p-4 osahan-payment">
                            
                            <Form.Group className="col-md-12">
                                <Form.Label>Add New Group</Form.Label>
                                <div style={{whiteSpace:'nowrap',marginBottom:5}}>
                                    <div style={{display:'inline-block'}}>
                                        <Form.Control
                                        type="textarea"
                                        // key={group.id}
                                        onChange={(event)=>{
                                            this.setState({currentNewGroupTitle:event.target.value})
                                        }}
                                        name="create_group_title"
                                        placeholder="Create Group Name"
                                        value={this.state.currentNewGroupTitle}
                                        />
                                    </div>
                                    { this.state.currentNewGroupTitle.length>0 && 
                                    <div style={{display:'inline-block',marginLeft:5}}>
                                        <button type="button"
                                        onClick={this.addNewGroup}
                                        >
                                            <Icofont icon="plus" /> Add Group
                                        </button>
                                    </div>
                                    }
                                </div>
                                <Form.Label>Tab Groups</Form.Label>

                                { this.props.groups.map((group,index) => {
                                    return (
                                        <div style={{whiteSpace:'nowrap',marginBottom:5}}>
                                            <div style={{display:'inline-block'}}>
                                                <Form.Check 
                                                type="checkbox"
                                                name="select_group"
                                                key={group.id}
                                                onChange={(event)=>{
                                                    this.updateTabGroup(event,group.id)
                                                }}
                                                checked={this.state.taggedGroups.includes(group.id)?true:false}
                                                />
                                            </div>
                                            <div style={{display:'inline-block'}}>
                                                <Form.Control
                                                type="textarea"
                                                key={group.id}
                                                onChange={(event)=>{
                                                    if (event.target.value.length>0) {
                                                        this.updatingGroups[group.id] = {"id":group.id,"title":event.target.value}
                                                    }
                                                }}
                                                name="update_group_title"
                                                placeholder="Update Group Name"
                                                // value={group.title}
                                                defaultValue={group.title}
                                                />
                                            </div>
                                            <div style={{display:'inline-block',marginLeft:5}}>
                                                <button type="button" style={{backgroundColor:'#9BB341',color:'white'}}
                                                onClick={()=>{this.updateGroup(group.id,index)}}
                                                >
                                                    update
                                                </button>
                                            </div>
                                            <div style={{display:'inline-block',marginLeft:5}}>
                                                <button type="button" style={{backgroundColor:'red',color:'white'}}
                                                onClick={()=>{this.props.deleteCustomizationGroup({"id":group.id},index)}}
                                                >
                                                   <Icofont icon="trash" /> delete
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })

                                }
                            </Form.Group>
                        </div>
                    </Form>
                    <button type="button" 
                    style={{width:'100%',backgroundColor:'#9BB341',color:'white',fontSize:16}}
                    onClick={()=>{
                        this.props.applyTaggedGroups(this.taggedGroups,this.removedGroups,this.newGroups)
                        this.props.onHide()
                    }}
                    >
                        Apply
                        
                    </button>
                </Modal.Body>
            </Modal>
        )
    }
}

export default DishCustomizationModal;

export {TabGroupModal};