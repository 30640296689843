import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Image, Media, Badge, span, Modal, Button } from "react-bootstrap";
import Icofont from "react-icofont";
import { alertCreation, alertError } from "../Alerts";
import Moment from "moment";
import { afterATime } from "../../services/DataFormatHandler";
import DataService from "../../services/DataService";
import DoorDashDetails from "../modals/DoorDashDetails";
const orderMode = ["Delivery Order", "Takeout Order"];
const orderSpans = ["primary", "warning"];
class OrderCard extends React.Component {
  constructor(props, context) {
    super(props, context);
    console.log(this.props.orderObj);

    this.dataHandler = new DataService("restaurants");
    this.deliveryStyles = {
      0: <span className="btn-default mr-1 ml-4">Waiting</span>,
      1: <span className="btn-primary mr-1 ml-4">Accepted </span>,
      2: <span className="btn-success mr-1 ml-4">Completed</span>,
      3: <span className="btn-info mr-1 ml-2">Requested For Delivery </span>,
      4: <span className="btn-danger mr-1 ml-4">Canceled</span>,
      5: <span className="btn-success mr-1 ml-4">Picked Up From Restaurent</span>,
      6: <span className="btn-success mr-1 ml-4">Delivered To Customer</span>,
      31: <span className="btn-success mr-1 ml-4">Transfred to Doordash</span>,
    };
    this.state = {
      lastClickTime: new Date(),
    };
  }
    componentDidMount() {
    this.setAutoUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
      this.setAutoUpdate();
    
  }

  setAutoUpdate = () => {
    setTimeout(() => {
      this.setState({ autoUpdate: !this.state.autoUpdate });
    }, 1*30* 1000); // 5 minutes
  };

  postDeliveryRequest = (orderObj) => {
    console.log(orderObj);
    let profileObj = this.dataHandler.getProfile();
    console.log(profileObj);

    let inputParams = {
      order_id: orderObj.order_id,
      team_id: 990949,
      auto_assignment: "0",
      job_description:
        "Pick up an item from " +
        profileObj.restaurant_name +
        " and deliver to " +
        orderObj.customer.first_name,
      job_pickup_phone: profileObj.phone,
      job_pickup_name: profileObj.restaurant_name,
      job_pickup_email: profileObj.order_notification_email,
      job_pickup_address: profileObj.address,
      job_pickup_latitude: profileObj.lat,
      job_pickup_longitude: profileObj.lng,
      job_pickup_datetime: this.props.orderDate,
      customer_email: this.props.orderObj.customer.email,
      customer_username: this.props.orderObj.customer.first_name,
      customer_phone: this.props.orderObj.customer.phone,
      customer_address: this.props.address,
      latitude: this.props.orderObj.customer["lat"],
      longitude: this.props.orderObj.customer["lng"],
      job_delivery_datetime: this.props.pickUpDate,
      has_pickup: "1",
      has_delivery: "1",
      layout_type: "0",
      tracking_link: 1,
      timezone: "-330",
      custom_field_template: "Template_1",
      meta_data: orderObj.added_items,
      pickup_custom_field_template: "Template_2",
      pickup_meta_data: this.props.orderObj.added_items,
      fleet_id: "",
      p_ref_images: [
        "http://tookanapp.com/wp-content/uploads/2015/11/logo_dark.png",
        "http://tookanapp.com/wp-content/uploads/2015/11/logo_dark.png",
      ],
      ref_images: [
        "http://tookanapp.com/wp-content/uploads/2015/11/logo_dark.png",
        "http://tookanapp.com/wp-content/uploads/2015/11/logo_dark.png",
      ],
      notify: 1,
      tags: "",
      geofence: 0,
      ride_type: 0,
    };
    return JSON.stringify(inputParams);
    /*
		let taskUrl='/create_task';
		createData(inputParams,taskUrl)
            .then((response) => {
				console.log(response);
                if (response.data.status === 200) {
                    alertCreation('Delevery');
					

                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));
*/
  };
  handleGetQuoteClick = (e) => {
    this.setState({ lastClickTime: new Date() });
    this.props.updateOrder(e, this.props.orderObj.id, {
      delivery_status: 30,
    });
  };
  render() {
    const fiveMinutesAgo = new Date(new Date() -5 * 60 * 1000);
    console.log("clicking time",this.state.lastClickTime, "<" ,fiveMinutesAgo);
    return (
      <div className="bg-white card mb-4 order-list shadow-sm ">
        <div className="gold-members p-4 ">
          <Media>
            <Image
              className="mr-4"
              src={this.props.image}
              alt={this.props.imageAlt}
            />
            <Media.Body>
              {this.deliveryStyles[this.props.orderObj.delivery_status]}
              {this.props.info.startsWith("in") && (
                <h6>
                  Future Order <Badge variant="info">{this.props.info}</Badge>
                </h6>
              )}
              {this.props.info.startsWith("a few") && (
                <h4>
                  Now <Badge variant="info">{this.props.info}</Badge>
                </h4>
              )}
              {!this.props.info.startsWith("few") &&
                !this.props.info.startsWith("in") && (
                  <Badge variant="info">{this.props.info}</Badge>
                )}{" "}
              {this.props.deliveredDate}
              <p className="text-gray mb-1">
                <Icofont icon="location-arrow" /> {this.props.address}
              </p>
              <p className="text-gray mb-3">
                <Icofont icon="list" /> ORDER #{this.props.orderNumber}
                <br />{" "}
                {typeof this.props.orderObj.order_mode != "undefined" && (
                  <Badge pill variant={orderSpans[this.props.orderObj.order_mode]}>
                    Order Mode :{orderMode[this.props.orderObj.order_mode]}
                  </Badge>
                )}{" "}
                <b>
                  <br />
                  Payment Mode:{" "}
                  {
                    /*(this.props.orderObj.payment_status===true)?*/
                    this.props.orderObj.payment_mode
                    /*:this.props.orderObj.customer.payment_mode*/
                  }
                </b>{" "}
                <br />
                Payment Status:
                <Badge
                  pill
                  variant={
                    this.props.orderObj.payment_status === true
                      ? "success"
                      : "primary"
                  }
                >
                  {this.props.orderObj.payment_status === true ? "PAID" : "PENDING"}
                </Badge>
                <br />
                <Link
                  className="btn-success mr-1 ml-2"
                  to={{
                    pathname: "/track-order",
                    state: {
                      orderObject: this.props.orderObj,
                      update: true,
                    },
                  }}
                >
                  Details
                </Link>
                <br />
                <Icofont icon="clock-time" className="ml-2" />{" "}
                {this.props.orderDate}
              </p>
              <p className="text-dark">{this.props.orderProducts}</p>
              <hr />
              <div className="float-right">
                {this.props.orderObj.delivery_status == 0 && (
                  <Link
                    className="btn btn-sm btn-outline-primary mr-1"
                    onClick={(e) => {
                      this.props.updateOrder(e, this.props.orderObj.id, {
                        delivery_status: 1,
                      });
                    }}
                    to={this.props.helpLink}
                  >
                    <Icofont icon="check" /> ACCEPT{" "}
                  </Link>
                )}
                {this.props.orderObj.delivery_status == 0 && (
                  <Link
                    className="btn btn-sm btn-danger mr-1"
                    onClick={(e) => {
                      this.props.updateOrder(e, this.props.orderObj.id, {
                        delivery_status: 4,
                      });
                    }}
                    to={this.props.helpLink}
                  >
                    <Icofont icon="trash" /> CANCEL
                  </Link>
                )}
                {this.props.orderObj.delivery_status == 1 &&
                  this.props.orderObj.order_mode == 0 && (
                    <Link
                      className="btn btn-sm btn-outline-primary mr-1 pl-1 pr-1"
                      onClick={(e) => {
                        //this.postDeliveryRequest(this.props.orderObj);

                        this.props.updateOrder(e, this.props.orderObj.id, {
                          delivery_status: 3,
                          delivery_params: this.postDeliveryRequest(
                            this.props.orderObj
                          ),
                        });
                      }}
                      to={this.props.helpLink}
                    >
                      <Icofont icon="headphone-alt" /> REQUEST FOR DELIVERY
                    </Link>
                  )}

{((this.props.orderObj.order_mode == 0 &&
                  this.props.orderObj.delivery_status == 3) ||  
                  (this.props.orderObj.delivery_status == 30 &&
                    (this.state.lastClickTime && (this.state.lastClickTime < fiveMinutesAgo)))) && 
                    <Link
                      className="btn btn-sm btn-outline-primary mr-1 pl-1 pr-1"
                      onClick={this.handleGetQuoteClick}
                      to={this.props.helpLink}
                    >
                      <Icofont icon="headphone-alt" />
                      GET QUOTE FROM DOORDASH{" "}
                    </Link>
                  }

                {this.props.orderObj.delivery_status == 1 && (
                  <Link
                    className="btn btn-sm btn-outline-primary mr-1 pl-1 pr-1"
                    onClick={(e) => {
                      this.props.updateOrder(e, this.props.orderObj.id, {
                        delivery_status: 2,
                      });
                    }}
                    to={this.props.helpLink}
                  >
                    <Icofont icon="headphone-alt" />
                    COMPLETE ORDER{" "}
                  </Link>
                )}
              </div>
              <p>
                {(this.props.orderObj.delivery_status == 31 ||
                  this.props.orderObj.delivery_status == 30) && (
                  <a
                    href={this.props.orderObj.tracking_url}
                    className="btn btn-sm btn-outline-primary mr-1"
                    target="_blank"
                  >
                    Doordash Track URL
                  </a>
                )}
              </p>
              {this.props.orderObj.doordash_info && (
                <DoorDashDetails
                  pickupTimeEstimated={
                    this.props.orderObj.doordash_info.pickup_time_estimated
                  }
                  dropoffTimeEstimated={
                    this.props.orderObj.doordash_info.dropoff_time_estimated
                  }
                  fee={this.props.orderObj.doordash_info.fee}
                  totalDelivery={this.props.orderObj.total_delivery}
                />
              )}
              {this.props.orderObj.order_mode == 0 &&
                this.props.orderObj.delivery_status == 30 && (
                  <Link
                    className="btn btn-sm btn-outline-primary mr-1 pl-1 pr-1"
                    onClick={(e) => {
                      this.props.updateOrder(e, this.props.orderObj.id, {
                        delivery_status: 31,
                      });
                    }}
                    to={this.props.helpLink}
                  >
                    <Icofont icon="headphone-alt" />
                    ACCEPT DOORDASH QUOTE{" "}
                  </Link>
                )}
              <p className="mb-0 text-black text-primary pt-2">
                {this.props.orderObj.applied_coupens.length > 0 && (
                  <span>
                    <p>Applied Coupens:</p>
                    {this.props.orderObj.applied_coupens.map((c_obj) => (
                      <span>
                        <Badge variant="primary"> {c_obj.title}</Badge>{" "}
                      </span>
                    ))}
                    Coupon Amount:
                    {this.props.orderObj.total_coupens_applied}
                    <hr></hr>
                  </span>
                )}
                {this.props.orderObj.extra_payments.length > 0 && (
                  <span>
                    <p>Extra Charges:</p>
                    {this.props.orderObj.extra_payments.map((e_obj) => (
                      <span>
                        <Badge variant="primary"> {e_obj.title} :</Badge>{" "}
                        {e_obj.value}$
                      </span>
                    ))}{" "}
                    <br></br>
                    Total Extra Amount:
                    {this.props.orderObj.total_extra}$<hr></hr>
                  </span>
                )}
                <span className="text-black font-weight-bold bg-red">
                  {" "}
                  Total Amount:
                </span>{" "}
                {this.props.orderTotal}
              </p>
            </Media.Body>
          </Media>
        </div>
        <div
          className="gold-members p-1"
          style={{ background: this.props.backColor }}
        ></div>
      </div>
    );
  }
}

OrderCard.propTypes = {
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  orderNumber: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  backColor: PropTypes.string.isRequired,
  deliveredDate: PropTypes.string,
  orderTitle: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  orderProducts: PropTypes.string.isRequired,
  helpLink: PropTypes.string.isRequired,
  detailLink: PropTypes.string.isRequired,
  orderTotal: PropTypes.string.isRequired,
};
export default OrderCard;
