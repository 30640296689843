import React from "react";
import {
	Row,
	Col,
	Tab,
	Tabs,
	Table,
	Accordion,
	Card,
	Button,
	Form,
} from "react-bootstrap";
import Icofont from "react-icofont";
import DishCustomizationModal from "../modals/DishCustomizationModal";
import { TabGroupModal } from "../modals/DishCustomizationModal";
import DataService from "../../services/DataService";
import {
	alertCreation,
	alertDeletion,
	alertError,
	alertUpdation,
	confirmDeleteAction,
} from "../Alerts";

class DishCustomization extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.dataHandler = new DataService("dish_customization_templates");
		this.groupsDataHandler = new DataService(
			"dish_customization_template_groups"
		);
		// this.mdata = new DataService("restaurant_food_customizations");
		this.state = {
			showDishCustomizationModal: false,
			showTabGroupModal: false,
			optionsFood: [],
			selectedRestaurant: this.dataHandler.getSelectedRestaurant(),
			customizationTabs: [],
			currentEditTab: null,
			groups: [],
			readyToGroup: false,
		};
		this.currentEditTabIndex = null;
		this.selectedTabs = [];
		this.selectedGroups = [];
		this.reloadData();
		this.reloadFoodData();
		this.reloadCustomizationGroups();
	}

	reloadData = () => {
		let inputParams = {};
		if (this.state.selectedRestaurant != 0) {
			inputParams = { rest_id: this.state.selectedRestaurant };
		}
		this.dataHandler.getData(inputParams).then((response) => {
			let currentData = response.data.data;
			console.log(currentData);
			this.setState({ customizationTabs: currentData });
		});
	};

	reloadCustomizationGroups = () => {
		let inputParams = {};
		if (this.state.selectedRestaurant != 0) {
			inputParams = { rest_id: this.state.selectedRestaurant };
		}
		this.groupsDataHandler.getData(inputParams).then((response) => {
			let currentData = response.data.data;
			// let abstractData = currentData.map((obj) => ({
			//     name: obj["title"],
			//     id: obj.id,
			// }));
			this.setState({ groups: currentData });
			// this.setState({ itemsDisplayed: currentData });
		});
	};

	createCustomizationGroup = (currentItem) => {
		let formData = new FormData(); //formdata object
		//added current restaurant id also with inputparams

		alert("creating group for ", this.state.selectedRestaurant);
		let customizationGroupString = JSON.stringify(currentItem);
		formData.append("customization_group", customizationGroupString);

		if (this.state.selectedRestaurant != 0) {
			formData.append("rest_id", this.state.selectedRestaurant);
		}
		this.groupsDataHandler
			.createDataFromForm(formData)
			.then((response) => {
				if (response.data["status"] === "Sucess") {
					alertCreation("Group");

					let groups = [...this.state.groups];
					groups.push(response.data.data);
					this.setState({ groups: groups });
					// console.log(groups)
					// this.props.history.push("customizationTab");
				} else {
					alertError(response.data["message"]);
				}
			})
			.catch((err) => alert(err));
	};

	groupSelect = (event, tabIndex) => {
		let customizationTabs = [...this.state.customizationTabs];
		if (
			event.target.checked === true &&
			!this.selectedTabs.includes(tabIndex)
		) {
			this.selectedTabs.push(tabIndex);
			customizationTabs[tabIndex]["checked"] = true;
		}
		if (
			event.target.checked === false &&
			this.selectedTabs.includes(tabIndex)
		) {
			const index = this.selectedTabs.indexOf(tabIndex);
			if (index > -1) {
				this.selectedTabs.splice(index, 1);
			}
			customizationTabs[tabIndex]["checked"] = false;
		}
		if (this.selectedTabs.length > 0) {
			this.selectedGroups = [];
			this.selectedTabs.map((tabIndex, index) => {
				let tab = this.state.customizationTabs[tabIndex];
				if (tab.hasOwnProperty("groups")) {
					if (index === 0) {
						tab.groups.map((group) => {
							this.selectedGroups.push(group.id);
						});
					} else {
						let selectedGroups = [...this.selectedGroups];
						let tabGroups = tab.groups.map(function (group) {
							return group.id;
						});
						selectedGroups.map((groupId, index) => {
							if (
								!tabGroups.includes(groupId) ||
								!tabGroups ||
								tabGroups.length === 0
							) {
								const index = this.selectedGroups.indexOf(groupId);
								if (index > -1) {
									this.selectedGroups.splice(index, 1);
								}
							}
						});
					}
					// tab.groups.map((group)=>{
					//     if (index===0) {
					//         this.selectedGroups.push(group.id)
					//     }
					//     else {
					//         this.selectedGroups.forEach(id => {
					//             if (id===group.id)
					//         });
					//     }
					// })
				}
			});
			this.setState({ readyToGroup: true });
		} else {
			this.setState({ readyToGroup: false });
		}
		this.setState({ customizationTabs: customizationTabs });
	};

	getGroupTitle = (val) => {
		let groupObj = this.state.groups.find((f) => f.id == val);
		let title = typeof groupObj != "undefined" ? groupObj.title : "unknown";
		return title;
	};

	applyTaggedGroups = (taggedGroupsIds, removedGroupsIds, newGroupsIds) => {
		let selectedTabIds = [];
		// let taggedGroups = taggedGroupsIds.map(item => this.state.groups.find((f) => f.id == item))
		let newGroups = newGroupsIds.map((item) =>
			this.state.groups.find((f) => f.id == item)
		);
		let customizationTabs = [...this.state.customizationTabs];
		this.selectedTabs.map((tabIndex) => {
			selectedTabIds.push(customizationTabs[tabIndex]["id"]);
			let taggedGroups = [];
			customizationTabs[tabIndex]["groups"].map((group) => {
				if (
					!removedGroupsIds.includes(group.id) &&
					!newGroupsIds.includes(group.id)
				) {
					taggedGroups.push(group);
				}
			});
			customizationTabs[tabIndex]["groups"] = taggedGroups;
			customizationTabs[tabIndex]["groups"].push(...newGroups);
			customizationTabs[tabIndex]["group"] = taggedGroupsIds;
			customizationTabs[tabIndex]["group"].push(...newGroupsIds);
			customizationTabs[tabIndex]["checked"] = false;
		});

		let formData = new FormData(); //formdata object
		//added current restaurant id also with inputparams
		let inputParams = {
			tabs: selectedTabIds,
			groups: { added: taggedGroupsIds, removed: removedGroupsIds },
		};

		let applyGroupsString = JSON.stringify(inputParams);

		formData.append("apply_groups", applyGroupsString);

		if (this.state.selectedRestaurant != 0) {
			formData.append("rest_id", this.state.selectedRestaurant);
		}
		this.dataHandler
			.createDataFromForm(formData)
			.then((response) => {
				if (response.data["status"] === "Sucess") {
					alertCreation("");
					this.setState({ customizationTabs: customizationTabs });
				} else {
					alertError(response.data["message"]);
				}
			})
			.catch((err) => alert(err));

		this.setState({ readyToGroup: false });
		this.selectedTabs = [];
		this.selectedGroups = [];
	};

	setTabEditForm = (tab) => {
		// let tab = this.props.currentEditTab;
		if (tab) {
			let tabCreateForm = {
				tab_title: tab["title"],
				is_mandatory: tab["is_mandatory"],
				enable_quantity_limits: tab["enable_quantity_limits"],
				max_number_of_items_required: tab["max_number_of_items_required"],
				number_of_items_required: tab["number_of_items_required"],
				tab_type: tab["tab_type"],
				id: tab["id"],
				added: tab["added"],
				custom_questions: [],
				upsell_items: [],
			};
			if (tab["tab_type"] === "1" || tab["tab_type"] === 1) {
				tab.upsell_items.map((sideDishForm) => {
					let currentSideDish = {
						food_item: sideDishForm["item"],
						id: sideDishForm["id"],
						payment_type: sideDishForm["payment_type"],
						choice_type: sideDishForm["choice_type"],
						free_available: sideDishForm["free_available"],
						item_price: sideDishForm["item_price"],
						added: sideDishForm["added"],
						removed: sideDishForm["removed"],
						updated: sideDishForm["updated"],
					};
					tabCreateForm.upsell_items.push(currentSideDish);
				});
			}
			if (tab["tab_type"] === "2" || tab["tab_type"] === 2) {
				tab.custom_questions.map((suggestionForm) => {
					let currentSuggestion = {
						question_title: suggestionForm["quetion_title"],
						id: suggestionForm["id"],
						question_type: suggestionForm["question_type"],
						answers: suggestionForm["answers"],
						added: suggestionForm["added"],
						removed: suggestionForm["removed"],
						updated: suggestionForm["updated"],
					};
					tabCreateForm.custom_questions.push(currentSuggestion);
				});
			}
			this.setState({ currentEditTab: tabCreateForm });
		}
		// console.log(tab)
	};

	reloadFoodData = () => {
		let inputParams = {};
		console.log("currentSelection", this.state.selectedRestaurant);
		if (this.state.selectedRestaurant != 0) {
			inputParams = { rest_id: this.state.selectedRestaurant };
		}
		new DataService("restaurant_food_items")
			.getData(inputParams)
			.then((response) => {
				let currentData = response.data.data;
				let abstractData = currentData.map((obj) => ({
					name: obj["title"],
					id: obj.id,
				}));
				this.setState({ optionsFood: abstractData });
				// this.setState({ itemsDisplayed: currentData });
			});
	};

	showDishCustomizationModal = () => {
		this.setState({ showDishCustomizationModal: true });
	};

	showTabGroupModal = () => {
		this.setState({ showTabGroupModal: true });
	};

	hideTabGroupModal = () => {
		this.setState({ showTabGroupModal: false });
	};

	hideDishCustomizationModal = () => {
		// this.setState({showDishCustomizationModal: false})
		this.setState({ currentEditTab: null });
		this.currentEditTabIndex = null;
		this.setState({ showDishCustomizationModal: false });
	};

	sendData = (currentItem, activity, customizationTabs) => {
		let formData = new FormData(); //formdata object

		let customSuggestionsString = JSON.stringify(currentItem);
		formData.append("custom_suggestions", customSuggestionsString);
		if (activity === "create") {
			if (this.state.selectedRestaurant != 0) {
				formData.append("rest_id", this.state.selectedRestaurant);
			}
			this.dataHandler
				.createDataFromForm(formData)
				.then((response) => {
					if (response.data["status"] === "Sucess") {
						alertCreation("Customization Tab");

						customizationTabs.push(response.data.data);
						this.setState({ customizationTabs: customizationTabs });
						this.hideDishCustomizationModal();

						// this.props.history.push("customizationTab");
					} else {
						alertError(response.data["message"]);
					}
				})
				.catch((err) => alert(err));
		}
		if (activity === "update") {
			this.dataHandler
				.updateDataFromForm(formData, currentItem["id"])
				.then((response) => {
					if (response.data["status"] === "Sucess") {
						alertUpdation("Customization Tab");
						customizationTabs[this.currentEditTabIndex] =
							response.data.data;
						this.setState({ customizationTabs: customizationTabs });
						this.hideDishCustomizationModal();

						// this.props.history.push("customizationTab");
					} else {
						alertError(response.data["message"]);
					}
				})
				.catch((err) => alert(err));
		}
		if (activity === "delete") {
			this.dataHandler
				.deleteData(currentItem)
				.then((response) => {
					if (response.data["status"] === "Sucess") {
						alertDeletion("Customization Tab");
						this.setState({ customizationTabs: customizationTabs });
						// this.props.history.push("customizationTab");
					} else {
						alertError(response.data["message"]);
					}
				})
				.catch((err) => alert(err));
		}
	};

	addNewTab = (tabForm, tabType, sideDishes = [], suggestions = []) => {
		let currentId = 1;
		currentId =
			this.state.customizationTabs.length > 0
				? this.state.customizationTabs[
						this.state.customizationTabs.length - 1
				  ].id + 1
				: 1;

		let currentItem = {
			title: tabForm["tab_title"],
			is_mandatory: tabForm["is_mandatory"],
			enable_quantity_limits: tabForm["enable_quantity_limits"],
			max_number_of_items_required: tabForm["max_number_of_items_required"],
			number_of_items_required: tabForm["number_of_items_required"],

			id: currentId,
			custom_questions: [],
			upsell_items: [],
			added: true,
			tab_type: tabForm["tab_type"],
		};

		if (tabType === "1" || tabType === 1) {
			sideDishes.map((sideDishForm) => {
				let currentSideDish = {
					item: sideDishForm["food_item"],
					id: currentId,
					payment_type: sideDishForm["payment_type"],
					choice_type: sideDishForm["choice_type"],
					free_available: sideDishForm["free_available"],
					item_price: sideDishForm["item_price"],
					added: sideDishForm["added"],
				};
				currentItem.upsell_items.push(currentSideDish);
			});
		}

		if (tabType === "2" || tabType === 2) {
			suggestions.map((suggestionForm) => {
				let currentSuggestion = {
					quetion_title: suggestionForm["question_title"],
					id: currentId,
					question_type: suggestionForm["question_type"],
					answers: suggestionForm["answers"],
					added: suggestionForm["added"],
				};
				currentItem.custom_questions.push(currentSuggestion);
			});
		}

		let customizationTabs = [...this.state.customizationTabs];
		//added current restaurant id also with inputparams

		this.sendData(currentItem, "create", customizationTabs);
		// customizationTabs.push(currentItem)
		// this.setState({customizationTabs: customizationTabs})
		// this.hideDishCustomizationModal();
	};

	updateTab = (tabForm, tabType, sideDishes = [], suggestions = []) => {
		let currentItem = {
			title: tabForm["tab_title"],
			is_mandatory: tabForm["is_mandatory"],
			enable_quantity_limits: tabForm["enable_quantity_limits"],
			max_number_of_items_required: tabForm["max_number_of_items_required"],
			number_of_items_required: tabForm["number_of_items_required"],

			id: tabForm["id"],
			custom_questions: [],
			upsell_items: [],
			added: tabForm["added"],
			tab_type: tabForm["tab_type"],
		};

		if (tabType === "1" || tabType === 1) {
			sideDishes.map((sideDishForm) => {
				let currentSideDish = {
					item: sideDishForm["food_item"],
					id: sideDishForm["id"],
					payment_type: sideDishForm["payment_type"],
					choice_type: sideDishForm["choice_type"],
					free_available: sideDishForm["free_available"],
					item_price: sideDishForm["item_price"],
					added: sideDishForm["added"],
					removed: sideDishForm["removed"],
					updated: sideDishForm["updated"],
				};
				currentItem.upsell_items.push(currentSideDish);
			});
		}

		if (tabType === "2" || tabType === 2) {
			suggestions.map((suggestionForm) => {
				let currentSuggestion = {
					quetion_title: suggestionForm["question_title"],
					id: suggestionForm["id"],
					question_type: suggestionForm["question_type"],
					answers: suggestionForm["answers"],
					added: suggestionForm["added"],
					removed: suggestionForm["removed"],
					updated: suggestionForm["updated"],
				};
				currentItem.custom_questions.push(currentSuggestion);
			});
		}

		let customizationTabs = [...this.state.customizationTabs];
		this.sendData(currentItem, "update", customizationTabs);
		// customizationTabs[this.currentEditTabIndex] = currentItem;
		// this.setState({customizationTabs: customizationTabs})
		// this.hideDishCustomizationModal();
	};

	removeTab = (index) => {
		let currentItems = [...this.state.customizationTabs];
		let tab = currentItems[index];

		if (tab["added"] === true) {
			currentItems.splice(index, 1);
		} else {
			currentItems[index]["removed"] = true;
		}
		this.sendData(tab, "delete", currentItems);
	};

	getFoodName(val) {
		// console.log(val);
		let foodObj = this.state.optionsFood.find((f) => f.id == val);
		let name = typeof foodObj != "undefined" ? foodObj.name : "unknown";
		return name;
	}

	updateCustomizationGroup = (currentItem, index) => {
		let formData = new FormData(); //formdata object
		let customizationGroupString = JSON.stringify(currentItem);
		formData.append("customization_group", customizationGroupString);
		this.groupsDataHandler
			.updateDataFromForm(formData, currentItem["id"])
			.then((response) => {
				if (response.data["status"] === "Sucess") {
					alertUpdation("Group");
					let groups = [...this.state.groups];
					groups[index] = currentItem;
					this.setState({ groups: groups });
					// this.props.history.push("dishCustomization");
				} else {
					alertError(response.data["message"]);
				}
			})
			.catch((err) => alert(err));
	};

	deleteCustomizationGroup = (currentItem, index) => {
		this.groupsDataHandler
			.deleteData(currentItem)
			.then((response) => {
				if (response.data["status"] === "Sucess") {
					alertDeletion("Group");
					let groups = [...this.state.groups];
					groups.splice(index, 1);
					this.setState({ groups: groups });
				} else {
					alertError(response.data["message"]);
				}
			})
			.catch((err) => alert(err));
	};

	render() {
		return (
			<>
				<div className="p-4 bg-white shadow-sm">
					<Tabs className="mb-3">
						<Tab eventKey="tab_list" title="Tab List">
							<Row>
								<h4 className="font-weight-bold mt-0 mb-4 ml-3">
									Dish Customization Tabs
								</h4>
								<button
									onClick={this.showDishCustomizationModal}
									style={{
										borderStyle: "none",
										backgroundColor: "white",
										marginLeft: 30,
										marginTop: 3,
									}}
								>
									<h6
										className="font-weight-bold mt-0 mb-4"
										style={{ color: "#9BB341" }}
									>
										Add New Tab <Icofont icon="plus-circle" />
									</h6>
								</button>
								{this.state.readyToGroup && (
									<button
										onClick={this.showTabGroupModal}
										style={{
											borderStyle: "none",
											backgroundColor: "white",
											marginLeft: 30,
											marginTop: 3,
										}}
									>
										<h6
											className="font-weight-bold mt-0 mb-4"
											style={{ color: "#9BB341" }}
										>
											Group Selected Tabs
										</h6>
									</button>
								)}
							</Row>
							<div style={{ height: 420, overflowY: "auto" }}>
								<Accordion>
									{this.state.customizationTabs.map(
										(tab, index) => {
											if (!tab.removed) {
												return (
													<>
														<Card>
															<Card.Header>
																<Row>
																	<Form.Check
																		type="checkbox"
																		name="select"
																		onChange={(
																			event
																		) => {
																			this.groupSelect(
																				event,
																				index
																			);
																		}}
																		checked={
																			tab[
																				"checked"
																			]
																		}
																		// label="Make this side dish as Free"
																		// checked={this.state.addedItemscreateForm["free_available"]===true ?true:false}
																	/>
																	<Accordion.Toggle
																		as={Button}
																		variant="link"
																		eventKey={
																			tab.id
																		}
																	>
																		<Icofont icon="caret-down" />
																		{tab.title}
																	</Accordion.Toggle>
																	<div
																		style={{
																			marginTop: 10,
																			marginLeft:
																				"auto",
																			marginRight: 0,
																		}}
																	>
																		<button
																			style={{
																				backgroundColor:
																					"#9BB341",
																				fontSize: 16,
																				color: "white",
																				marginLeft: 30,
																			}}
																			onClick={() => {
																				this.setTabEditForm(
																					tab
																				);
																				this.showDishCustomizationModal();
																				this.currentEditTabIndex =
																					index;
																			}}
																		>
																			<Icofont icon="pencil" />
																			Edit Tab
																		</button>
																		<button
																			style={{
																				backgroundColor:
																					"red",
																				fontSize: 16,
																				color: "white",
																				marginLeft: 30,
																			}}
																			onClick={() => {
																				confirmDeleteAction(
																					"Tab"
																				).then(
																					({
																						isConfirmed,
																					}) => {
																						isConfirmed &&
																							this.removeTab(
																								index
																							);
																					}
																				);
																			}}
																		>
																			<Icofont icon="trash" />
																			Delete
																			Tab
																		</button>
																	</div>
																</Row>
															</Card.Header>
															<Accordion.Collapse
																eventKey={tab.id}
															>
																<Card.Body>
																	{tab.hasOwnProperty(
																		"groups"
																	) &&
																		tab.groups
																			.length >
																			0 && (
																			<>
																				<h6
																					style={{
																						color: "#9BB341",
																					}}
																				>
																					Tab
																					Groups
																				</h6>
																				<div className="bg-white rounded shadow-sm p-4 osahan-payment">
																					{tab.groups.map(
																						(
																							group
																						) => {
																							return (
																								group.title +
																								" , "
																							);
																						}
																					)}
																				</div>
																			</>
																		)}
																	<h6
																		style={{
																			color: "#9BB341",
																		}}
																	>
																		Tab Details
																	</h6>
																	<div className="bg-white rounded shadow-sm p-4 osahan-payment">
																		{tab.is_mandatory && (
																			<Row>
																				<h7>
																					Mandatory
																					:
																					Yes
																				</h7>
																			</Row>
																		)}
																		{!tab.is_mandatory && (
																			<Row>
																				<h7>
																					Mandatory
																					:
																					No
																				</h7>
																			</Row>
																		)}
																		{tab.enable_quantity_limits && (
																			<>
																				<Row>
																					<h7>
																						Minimum
																						Items
																						Required
																						:{" "}
																						{
																							tab.number_of_items_required
																						}
																					</h7>
																				</Row>
																				<Row>
																					<h7>
																						Maximum
																						Items
																						Required
																						:{" "}
																						{
																							tab.max_number_of_items_required
																						}
																					</h7>
																				</Row>
																			</>
																		)}
																	</div>
																	{(tab.tab_type ===
																		"1" ||
																		tab.tab_type ===
																			1) && (
																		<div>
																			<h6
																				style={{
																					color: "#9BB341",
																					marginTop: 3,
																				}}
																			>
																				Side
																				Dishes
																			</h6>
																			{tab
																				.upsell_items
																				.length >
																				0 && (
																				<Table
																					striped
																					bordered
																					hover
																				>
																					<thead>
																						<tr>
																							<th>
																								Name
																							</th>
																							<th>
																								Available
																								Free{" "}
																							</th>
																							<th>
																								Price
																							</th>
																							{/* <th>Action</th> */}
																						</tr>
																					</thead>
																					<tbody>
																						{tab.upsell_items.map(
																							(
																								item,
																								index
																							) => {
																								if (
																									!item.removed
																								) {
																									return (
																										<tr>
																											<td>
																												{" "}
																												{this.getFoodName(
																													item.item
																												)}
																											</td>
																											<td>
																												{item.free_available &&
																													"Yes"}
																												{!item.free_available &&
																													"No"}
																											</td>
																											<td>
																												{
																													item.item_price
																												}
																											</td>
																										</tr>
																									);
																								}
																							}
																						)}
																					</tbody>
																				</Table>
																			)}
																		</div>
																	)}
																	{(tab.tab_type ===
																		"2" ||
																		tab.tab_type ===
																			2) && (
																		<div>
																			<h6
																				style={{
																					color: "#9BB341",
																					marginTop: 3,
																				}}
																			>
																				Suggestions
																				(
																				Questions
																				&
																				Answers
																				)
																			</h6>
																			{tab
																				.custom_questions
																				.length >
																				0 && (
																				<div className="bg-white rounded shadow-sm p-4 osahan-payment">
																					{/* <Row style={{marginBottom: 5}}>
                                                                    <h5>Added Questions</h5>
                                                                    <button type="button" style={{marginLeft: 20,backgroundColor:'#9BB341',color:'white',fontSize:16}}
                                                                    onClick={this.onClickAddQuestion}
                                                                    >
                                                                    Add New Question
                                                                    </button>
                                                                </Row> */}
																					<Accordion>
																						{tab.custom_questions.map(
																							(
																								question,
																								index
																							) => {
																								if (
																									!question.removed
																								) {
																									return (
																										<>
																											<Card>
																												<Card.Header>
																													<Row>
																														<Accordion.Toggle
																															as={
																																Button
																															}
																															variant="link"
																															eventKey={
																																index
																															}
																														>
																															<Icofont icon="caret-down" />
																															{
																																question.quetion_title
																															}
																														</Accordion.Toggle>
																														<div
																															style={{
																																marginTop: 10,
																																marginLeft:
																																	"auto",
																																marginRight: 0,
																															}}
																														>
																															{(question.question_type ===
																																"2" ||
																																question.question_type ===
																																	2) && (
																																<h8
																																	style={{
																																		marginLeft: 10,
																																	}}
																																>
																																	Multiple
																																	Choices
																																</h8>
																															)}
																															{(question.question_type ===
																																"1" ||
																																question.question_type ===
																																	1) && (
																																<h8
																																	style={{
																																		marginLeft: 10,
																																	}}
																																>
																																	Single
																																	Choice
																																</h8>
																															)}
																														</div>
																													</Row>
																												</Card.Header>
																												<Accordion.Collapse
																													eventKey={
																														index
																													}
																												>
																													<Card.Body>
																														<div>
																															<h7
																																style={{
																																	color: "#9BB341",
																																}}
																															>
																																Choice
																																Answers
																															</h7>
																															<Table
																																striped
																																bordered
																																hover
																															>
																																<thead>
																																	<tr>
																																		<th>
																																			Sl.No
																																		</th>
																																		<th>
																																			Choice
																																		</th>
																																		<th>
																																			Free
																																		</th>
																																		<th>
																																			Charge
																																		</th>
																																	</tr>
																																</thead>
																																<tbody>
																																	{question.answers.map(
																																		(
																																			answer,
																																			index
																																		) => {
																																			if (
																																				!answer.removed
																																			) {
																																				return (
																																					<tr>
																																						<td>
																																							{index +
																																								1}
																																						</td>
																																						<td>
																																							{
																																								answer.answer_title
																																							}
																																						</td>
																																						<td>
																																							{answer.free_available &&
																																								"Yes"}
																																							{!answer.free_available &&
																																								"No"}
																																						</td>
																																						<td>
																																							{
																																								answer.item_price
																																							}
																																						</td>
																																					</tr>
																																				);
																																			}
																																		}
																																	)}
																																</tbody>
																															</Table>
																														</div>
																													</Card.Body>
																												</Accordion.Collapse>
																											</Card>
																											{/* <br/> */}
																										</>
																									);
																								}
																							}
																						)}
																					</Accordion>
																				</div>
																			)}
																		</div>
																	)}
																</Card.Body>
															</Accordion.Collapse>
														</Card>
													</>
												);
											}
										}
									)}
								</Accordion>
							</div>
						</Tab>
						<Tab eventKey="group_list" title="Group List">
							<Row>
								<h4 className="font-weight-bold mt-0 mb-4 ml-3">
									Dish Customization Groups
								</h4>
							</Row>
							<div style={{ height: 420, overflowY: "auto" }}>
								<Accordion>
									{this.state.groups.map((group, index) => {
										return (
											<Card>
												<Card.Header>
													<Row>
														<Accordion.Toggle
															as={Button}
															variant="link"
															eventKey={group.id}
														>
															<Icofont icon="caret-down" />
															{group.title}
														</Accordion.Toggle>
													</Row>
												</Card.Header>
												<Accordion.Collapse
													eventKey={group.id}
												>
													<Card.Body>
														{this.state.customizationTabs.map(
															(tab, index) => {
																if (
																	tab.group.includes(
																		group.id
																	) &&
																	!tab.removed
																) {
																	return (
																		<h6>
																			{
																				tab.title
																			}
																		</h6>
																	);
																}
															}
														)}
													</Card.Body>
												</Accordion.Collapse>
											</Card>
										);
									})}
								</Accordion>
							</div>
						</Tab>
					</Tabs>
				</div>
				{this.state.showDishCustomizationModal && (
					<DishCustomizationModal
						// tabId={this.state.tabId}
						addNewTab={this.addNewTab}
						updateTab={this.updateTab}
						show={this.state.showDishCustomizationModal}
						onHide={this.hideDishCustomizationModal}
						optionsFood={this.state.optionsFood}
						currentEditTab={this.state.currentEditTab}
						// payOptions={this.state.payOptions}
						// mandatoryOptions={this.state.mandatoryOptions}
						// mandatoryValues={this.state.mandatoryValues}
						// addNewCustomaization={this.addNewCustomaization}
						// customizationTabs={this.state.customizationTabs}
					/>
				)}

				{this.state.showTabGroupModal && (
					<TabGroupModal
						show={this.state.showTabGroupModal}
						onHide={this.hideTabGroupModal}
						groups={this.state.groups}
						applyTaggedGroups={this.applyTaggedGroups}
						selectedGroups={this.selectedGroups}
						createCustomizationGroup={this.createCustomizationGroup}
						updateCustomizationGroup={this.updateCustomizationGroup}
						deleteCustomizationGroup={this.deleteCustomizationGroup}
					/>
				)}
			</>
		);
	}
}

export default DishCustomization;
