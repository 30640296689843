import moment from 'moment';
import Moment from 'moment';


/*
1. isBefore

compare two times, returns true if current time is higher than given time,
else return false

*/

const compareWith=(startTime,endTime)=>{
var beginningTime = Moment(startTime, 'h:mma');
var endTime = Moment(endTime, 'h:mma');
return (beginningTime.isBefore(endTime)); 
}

const defaultStartTime="00:00";

const timeCall={"SUNDAY":1,
"MONDAY":2,
"TUESDAY":3,
"WEDNESDAY":4,
"THURSDAY":5,
"FRIDAY":6,
"SATURDAY":7


};
const getDayIndex=(key)=>
{
return timeCall[key];
}










const toUtcSeconds=(timeInSeconds)=>{ 
var d = new Date();
var offsetUTC = d.getTimezoneOffset()*60;
var utcTimeInSeconds=timeInSeconds+offsetUTC; 
return utcTimeInSeconds;
}

const toCurrentTime=(timeObj)=>{
    let time1 = Moment(timeObj, "HH:mm:ss");
    let d = new Date();
    let n = d.getTimezoneOffset();
    let subtract = time1.subtract(n);
	let format = Moment(subtract).format(('hh:mm A'))
    return format;
    };

const toLocalSeconds=(timeInSeconds)=>
{ 
    var d = new Date();
    var offsetUTC = d.getTimezoneOffset()*60;
    var localTimeInSeconds=timeInSeconds-(offsetUTC); 
    return localTimeInSeconds;
    }



    const afterATime=(timeString,timeInt)=>{
        var returned_date = Moment(timeString).add(timeInt, 'minute').format('YYYY-MM-DD H:mm:ss'); 
        return returned_date;
    }


const toFullDate=(dateObj)=>{
Moment.locale('en');
let n=new Date().getTimezoneOffset();
return(Moment(dateObj).add(n).format("dddd, MMMM Do YYYY, h:mm:ss a"));
};
const afterHour=(timeInter)=>{
    var returned_date = Moment().add(timeInter, 'minute').format('YYYY-MM-DD H:mm:ss'); 
    return returned_date;
}

const afterHourFromDate=(timeInter)=>{
    var returned_date = Moment(timeInter).add(30, 'minute').format('YYYY-MM-DD H:mm:ss'); 
    return returned_date;
}
const isNow=(dateObj)=>{
    let currentDate= Moment().format('DD/M/YYYY')
    let givenDate=Moment(dateObj).format('DD/M/YYYY')
    return (givenDate === currentDate)
    };

    const isAgo=(dateObj,days)=>{

       var today = new Date();
       var day = new Date();
        day.setDate(today.getDate()-days);
        let currentDate= Moment(day).format('DD/M/YYYY')
        let givenDate=Moment(dateObj).format('DD/M/YYYY')
        return (givenDate === currentDate)
        };
        const getCurrentDate=()=>{
            let currentDate= Moment().format('DD/M/YYYY')
            return currentDate;

        }

        const convertDate=(dateObj)=>{
            let givenDate=Moment(dateObj).format('YYYY-MM-DD')
            return givenDate;

        }
      const toFormattedDate24=(timeObj)=>{

        let formatedDay=Moment("2015-01-01").startOf('day')
        .seconds(timeObj)
        .format('H:mm:ss');
        return formatedDay;
      

      }

        const timeConvertSeconds=(sheduleDays)=>{
            sheduleDays=sheduleDays.map((sheduleObj)=>
            {
                sheduleObj['opening_time']=Moment.duration(sheduleObj['opening_time']).asSeconds();
                sheduleObj['closing_time']=Moment.duration(sheduleObj['closing_time']).asSeconds();
                return sheduleObj;
            });
            return sheduleDays; 
        }
    
        const serverToLocalTimeDic=(timeDic)=>{
let utcSecondsDic=timeConvertSeconds(timeDic);
let localSeondsDic=convertTimeDicToLocal(utcSecondsDic);
return localSeondsDic;

        }
    const convertTimeDicToUTC=(timeDic)=>{
        let timeUTCDic=timeDic.map((sheduleObj)=>{
            sheduleObj.opening_time=toUtcSeconds(sheduleObj.opening_time);
            sheduleObj.closing_time=toUtcSeconds(sheduleObj.closing_time);
            return sheduleObj;

        });
        return timeUTCDic;

    }

    const convertTimeDicToLocal=(timeDic)=>{
        let timeUTCDic=timeDic.map((sheduleObj)=>{
            sheduleObj.opening_time=toLocalSeconds(sheduleObj.opening_time);
            sheduleObj.closing_time=toLocalSeconds(sheduleObj.closing_time);
            return sheduleObj;

        });
        return timeUTCDic;

    }
    const to12HourFormat=(timeObj)=>{
        let time24 = Moment(timeObj, "HH:mm:ss");
      
        let time12 = time24.format(('hh:mm A'))
        return time12;
        };
export  {toFullDate,afterHour,isNow,isAgo,getCurrentDate,afterATime,
    serverToLocalTimeDic,timeConvertSeconds,afterHourFromDate,
    toUtcSeconds,toLocalSeconds,defaultStartTime,to12HourFormat,toFormattedDate24,convertTimeDicToUTC,convertDate,getDayIndex};