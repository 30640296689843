import React from 'react';

import { Redirect, Link,useHistory} from 'react-router-dom';
import {Row,Col,Container,Form,Button} from 'react-bootstrap';
import FontAwesome from './common/FontAwesome';
import SimpleReactValidator from 'simple-react-validator';
import {loginUser} from '../helpers/apiConnect'
import {setLoginUser} from '../helpers/authUtils';
import {logout} from '../helpers/authUtils'

class Login extends React.Component {
	

	constructor(props) {

        super(props);
        this.validator = new SimpleReactValidator({autoForceUpdate: this});

        this.state = {
            createForm: {},
			loginErorMessage:""        
        };
		logout();
        this.createFormHandler=this.createFormHandler.bind(this);
		this.submitLoginForm=this.submitLoginForm.bind(this);
        
    }

	
	createFormHandler = (event) => {
        let key="";
        let value="";   
        key = event.target.name;
        value = event.target.value;
        
        let foamParas={...this.state.createForm}
        foamParas[key]=value
        this.setState({createForm:foamParas})
        };
	

	submitLoginForm=()=>{
		let inputParams={...this.state.createForm}
		this.setState({loginErorMessage:""})
		if((this.validator.allValid())==true)
        {
			loginUser(inputParams).then((response)=>{
				console.log(response.data);
			

				setLoginUser(response.data);
				



				this.props.history.push('/myaccount/dashboard');



			}
				).catch((erorMessage)=>{
					this.setState({loginErorMessage:erorMessage})
				});

		}else{
            this.validator.showMessages();

        }
	}
    

	render() {
    	return (
    	  <Container fluid className='bg-white'>
	         <Row>
	            <Col md={4} lg={6} className="d-none d-md-flex bg-image"></Col>
	            <Col md={8} lg={6}>
	               <div className="login d-flex align-items-center py-5">
	                  <Container>
	                     <Row>
	                        <Col md={9} lg={8} className="mx-auto pl-5 pr-5">
	                           <h3 className="login-heading mb-4">Welcome back!</h3>
	                           <Form>
	                              <div className="form-label-group">
	                                 <Form.Control type="email" id="inputEmail" onChange={this.createFormHandler} placeholder="Email address" name="email"/>
									 {this.validator.message('email', this.state.createForm['email'], 'required',{ className: 'text-danger' }) }

	                                 <Form.Label htmlFor="inputEmail">Email address / Mobile</Form.Label>

	                              </div>
	                              <div className="form-label-group">
	                                 <Form.Control type="password" onChange={this.createFormHandler} id="inputPassword" name="password" placeholder="Password" />
									 {this.validator.message('password', this.state.createForm['password'], 'required',{ className: 'text-danger'  }) }

	                                 <Form.Label htmlFor="inputPassword">Password</Form.Label>

	                              </div>
	                              <Form.Check  
	                              	className='mb-3'
							        custom
							        type="checkbox"
							        id="custom-checkbox"
							        label="Remember password"
							      />
	                                <Link className="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" onClick={this.submitLoginForm}  type="submit">
                                            Sign In
                                        </Link>
									{(typeof(this.state.loginErorMessage.length)!='undefined')&&(this.state.loginErorMessage.length>0) &&	<span  className="text-danger">{this.state.loginErorMessage}</span>}
		                           <hr className="my-4" />
		                           
	                           </Form>
	                        </Col>
	                     </Row>
	                  </Container>
	               </div>
	            </Col>
	         </Row>
	      </Container>
    	);
    }
}


export default Login;