import React from "react";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";
import Icofont from "react-icofont";
import { Multiselect } from "multiselect-react-dropdown";
import DataService from "../../services/DataService";
class Combo extends React.Component {
	constructor(props) {
		super(props);
		this.dataHandler = new DataService("restaurant_food_category");
		this.state = {
			comboConfigs: [], // To store combo configurations
		};
		console.log('item i com',this.props.allItems)
		//this.reloadData();
	}

	handleComboChange = (index, name, value) => {
		const updatedCombos = [...this.props.comboConfigs];
		updatedCombos[index] = {
			...updatedCombos[index],
			[name]: value,
		};
		this.props.setComboConfigs(updatedCombos);
	};

	handleItemChoiceChange = (index, selectedList) => {
		const selectedItems = selectedList.map((item) => item.id);
		this.handleComboChange(index, "itemChoicesIds", selectedItems);
	};

	addNewCombo = () => {

		const updatedCombos = [...this.props.comboConfigs,
			{
				title: `Select Item ${this.props.comboConfigs.length + 1}`,
				itemChoicesIds: [],
			},];

		this.props.setComboConfigs(updatedCombos);
	};

	removeCombo = (index) => {
		const updatedCombos = this.props.comboConfigs.filter((_, i) => i !== index)
		this.props.setComboConfigs(updatedCombos)
	};
	render() {

		return (
			<>
				<Form.Group
					className="col-md-12"
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
					}}
				>
					<Form.Label>Combo Items</Form.Label>
					<Button
						variant="primary"
						onClick={this.addNewCombo}
						style={{
							marginLeft: 10,
							marginBottom: 30,
							alignSelf: "flex-end",
						}}
					>
						Add New Combo Item +
					</Button>
				</Form.Group>
				<Container>
					
					{this.props.comboConfigs.map((combo, index) => (
						<Card key={index} className="mb-3">
							<Card.Body>
								<Row className="align-items-center">
									<Col>
										<Form.Group>
											<Form.Label>Title</Form.Label>
											<Form.Control
												type="text"
												name="title"
												value={combo.title}
												onChange={(e) =>
													this.handleComboChange(
														index,
														"title",
														e.target.value
													)
												}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>Item Choices</Form.Label>
											<Multiselect
												options={this.props.allItems}
												selectedValues={combo.itemChoicesIds.map(
													(id) =>
														this.props.allItems.find(
															(item) => item.id === id
														)
												)}
												onSelect={(selectedList) =>
													this.handleItemChoiceChange(
														index,
														selectedList
													)
												}
												onRemove={(selectedList) =>
													this.handleItemChoiceChange(
														index,
														selectedList
													)
												}
												displayValue="title"
												placeholder="Select Items"
											/>
										</Form.Group>
									</Col>
									<Col xs="auto">
										<Button
											variant="danger"
											onClick={() => this.removeCombo(index)}
											className="mt-4"
										>
											<Icofont icon="ui-delete" />
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					))}
				</Container>
			</>
		);
	}
}

export default Combo;
