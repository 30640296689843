const baseURL=window.api_end;
const currentURL=window.current_url;


//const currentURL="http://localhost:3001/";
//const baseURL='http://127.0.0.1:8000';
const deliveryApiKey=window.deliveryApiKey;
const deliveryURL=window.deliveryURL;
const defaultHeader= {
    'content-type':'application/json',
    };
const formHeader= {
    'content-type': 'multipart/form-data'
};
const timeZoneList=[
    'Asia/Calcutta', // Indian time zone
    'Canada/Atlantic',
    'Canada/Central',
    'Canada/Eastern',
    'Canada/Mountain',
    'Canada/Newfoundland',
    'Canada/Pacific',
    'Canada/Saskatchewan',
    'Canada/Yukon',
    'US/Alaska',
    'US/Aleutian',
    'US/Arizona',
    'US/Central',
    'US/East-Indiana',
    'US/Eastern',
    'US/Hawaii',
    'US/Indiana-Starke',
    'US/Michigan',
    'US/Mountain',
    'US/Pacific',
    'US/Samoa'
];
const timeZoneDifference = {
    'Asia/Calcutta': 5.50,
    'Canada/Atlantic': -4,
    'Canada/Central': -5,
    'Canada/Eastern': -5,
    'America/New_York': -5,
    'America/Chicago': -6,
    'America/Guatemala': -6,
    'Canada/Mountain': -7,
    'Canada/Newfoundland': -3.50,
    'Canada/Pacific': -8,
    'Canada/Saskatchewan': -6,
    'Canada/Yukon': -7,
    'US/Alaska': -9,
    'US/Aleutian': -10,
    'US/Arizona': -7,
    'US/Central': -6,
    'US/East-Indiana': -5,
    'US/Eastern': -5,
    'US/Hawaii': -10,
    'US/Indiana-Starke': -5,
    'US/Michigan': -5,
    'US/Mountain': -7,
    'US/Pacific': -8,
    'US/Samoa': -11
};
const countries=['Canada','US']
const dishTags=[
    {label:"Veg",value:"Veg"},
    {label:"Non Veg",value:"Non Veg"},
    {label:"Vegan",value:"Vegan"},
    {label:"Gluten-Free",value:"Gluten-Free"},

]

//center:{lat:50.445210,lng: -104.618896}
const weekDays=[ "SUNDAY", "MONDAY",
"TUESDAY",
"WEDNESDAY",
"THURSDAY",
"FRIDAY",
"SATURDAY"];
 
export {baseURL ,currentURL, deliveryURL,defaultHeader,timeZoneList,formHeader,deliveryApiKey,countries}