import React from 'react';
import DeliveryOrderCard from '../common/DeliveryOrderCard';
import Icofont from 'react-icofont';
import {NavLink} from 'react-router-dom';
import {NavDropdown,Nav} from 'react-bootstrap';
import DropDownTitle from '../common/DropDownTitle';
import DataService from "../../services/DataService";
import {afterHour} from "../../services/DataFormatHandler";

import {toFullDate} from "../../services/DataFormatHandler";
import { alertUpdation,alertError } from '../Alerts';
import moment from 'moment';
class DeliveryOrders extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.cartHandler = new DataService("carts");

    this.state = {
		
		itemsDisplayed: [],
		filter:5,
		selectedRestaurant:this.cartHandler.getSelectedRestaurant()

	  };
	  this.updateOrder=this.updateOrder.bind(this);
	  this.reloadData();
	}
  /*
    fetch elements from server and reload data elements
    */
	reloadData = (filter) => {
	let inputParams = {"days":14,
	delivery_only:true,

	};

		if(filter<7)
		{
			inputParams['delivery_status']=filter
				
			
		}
		if (this.state.selectedRestaurant!=0)
		{
		   inputParams={rest_id:this.state.selectedRestaurant,...inputParams}
		}
		
		this.cartHandler.getOrderedCarts(inputParams).then((response) => {
		  let currentData = response.data.data;
		  console.log(currentData);
		  this.setState({ itemsDisplayed: currentData });
		});
	  };
	

	
	  updateOrder = (e,orderId,inputParams) => {
		e.target.setAttribute("disabled", "disabled");
		
        //let inputParams = {delivery_status:deliveryStatus};
     

		this.cartHandler.updateData(inputParams,orderId)
            .then((response) => {
                if (response.data['status'] === 'Sucess') {
					this.reloadData();

                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));

        
		
    };
	render() {
    	return (
    		<>
    		    <div className='p-4 bg-white shadow-sm'>
			         <Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
						 <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="">
						 <h4 className="font-weight-bold mt-0 mb-4"> All Orders </h4><span className="sr-only">(current)</span>
			         </Nav.Link>
			            
						<NavDropdown alignRight
			            	title={
			            		<DropDownTitle
			            			className='d-inline-block mt-2'
			            			image="img/filter.png"
			            			imageAlt='user'
			            			imageClass="nav-osahan-pic rounded-pill"
			            			title='Filter By '
			            		/>
			            	}
			            	>
							<NavDropdown.Item  onClick={()=>{
								
								this.reloadData();}} activeclassname="active" to="/myaccount/orders"><Icofont icon='food-cart'/> Total</NavDropdown.Item>
							<NavDropdown.Item onClick={()=>{
								
								this.reloadData(0);}} activeclassname="active" to="/myaccount/offers"><Icofont icon='trash'/> Waiting</NavDropdown.Item>
							<NavDropdown.Item onClick={()=>{
								
								this.reloadData(1);}} activeclassname="active" to="/myaccount/favourites"><Icofont icon='ui-check'/>Accepted & Ongoing</NavDropdown.Item>
							<NavDropdown.Item onClick={()=>{
								
								this.reloadData(2);}} activeclassname="active" to="/myaccount/payments"><Icofont icon='ui-clip-board'/>  Accepted & Completed</NavDropdown.Item>
							<NavDropdown.Item onClick={()=>{
								
								this.reloadData(3);}} activeclassname="active" to="/myaccount/addresses"><Icofont icon='logout'/> Request for Delivery</NavDropdown.Item>
							<NavDropdown.Item onClick={()=>{
								
								this.reloadData(4);}} activeclassname="active" to="/myaccount/addresses"><Icofont icon='logout'/> Rejected</NavDropdown.Item>

			            </NavDropdown>
					</Nav>
			   {this.state.itemsDisplayed.map((orderObj)=>{
				 let delivery_date=((orderObj.ordered_date)&&
				 (orderObj.ordered_date!=null))?
				 toFullDate(orderObj.ordered_date):moment().format("dddd, MMMM Do YYYY, h:mm:ss a");

console.log("Delivery ...",delivery_date);
console.log("Delivery ...",moment(delivery_date,"dddd, MMMM Do YYYY, h:mm:ss a").fromNow());

			     return <DeliveryOrderCard
				 	orderObj={orderObj}
			      	image='/img/fast-food.png'
			      	imageAlt=''
			      	orderNumber={"order id :"+orderObj.order_id}
					info={moment(delivery_date,"dddd, MMMM Do YYYY, h:mm:ss a").fromNow()}
			      	orderDate={toFullDate(orderObj.published_date)}
					pickUpDate={afterHour(4)}
			      	deliveredDate={delivery_date}
			      	orderTitle={(orderObj.name)?orderObj.name:""}
			      	address={(orderObj.customer_address)?orderObj.customer_address:""}
					updateOrder={this.updateOrder}  
			      	helpLink='#'
					backColor="#00FFFF"
			      	detailLink='/detail' 
			      />
			   })}
			     
			    </div>
		    </>
    	);
    }
}
export default DeliveryOrders;