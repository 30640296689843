import React from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Badge,Form,InputGroup,Button,Tab,Nav,ButtonToolbar,ToggleButton,ToggleButtonGroup,Image,OverlayTrigger,Tooltip} from 'react-bootstrap';
import ItemsCarousel from '../../common/ItemsCarousel';
import ChooseAddressCard from '../../common/ChooseAddressCard';
import CheckoutItem from '../../common/CheckoutItem';
import MutilUpload from '../../common/MultipleImageUploadComponent';
import SimpleReactValidator from 'simple-react-validator';
import { Multiselect } from "multiselect-react-dropdown";
import TimePicker from 'react-bootstrap-time-picker';
import {timeZoneList} from '../../../helpers/globalConstants'

import AddAddressModal from "../../modals/AddAddressModal";
import Icofont from 'react-icofont';

import DataService from '../../../services/DataService'
import { alertCreation,alertError,alertUpdation } from '../../Alerts';
import SearchLocationInput from '../../location/SearchLocationInput';
const KeyCodes = {
	comma: 188,
	enter: 13,
  };
  const time_step=30;
  const weekDays=[ "SUNDAY", "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"];
  const time_cal={"SUNDAY":{'opening_time':0,'closing_time':0},
  "MONDAY":{'opening_time':0,'closing_time':0},
  "TUESDAY":{'opening_time':0,'closing_time':0},
  "WEDNESDAY":{'opening_time':0,'closing_time':0},
  "THURSDAY":{'opening_time':0,'closing_time':0},
  "FRIDAY":{'opening_time':0,'closing_time':0},
  "SATURDAY":{'opening_time':0,'closing_time':0}


  };
//	import { WithContext as ReactTags } from 'react-tag-input';
	

//center:{lat:50.445210,lng: -104.618896}

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
class NewFranchaise extends React.Component {
	constructor(props, context) {
	    super(props, context);
		this.validator = new SimpleReactValidator({autoForceUpdate: this});
		this.dataHandler=new DataService("restaurants");

this.galleryImages=[]
	    this.state = {
      	  showAddressModal: false,
		  showHideDelivery:false,
		  tags: [],
		  time_shedule:{...time_cal},
		  dailyShedules:[],
		  address:{ address: '',
		  city: '',
		  area: '',
		  state: ''},
		  taxOptions:[],
		  createForm:{'tax_enabled':true},
		  center:{lat:50.445210,lng: -104.618896}
	    };
		this.taxData=[];
		this.reloadTaxData();
		this.hideDelivery = this.hideDelivery.bind(this);
		this.handleDelete = this.handleDelete.bind(this);
		this.handleAddition = this.handleAddition.bind(this);
		this.handleDrag = this.handleDrag.bind(this);
		this.handleTagClick = this.handleTagClick.bind(this);
		this.handleFiles=this.handleFiles.bind(this);
		this.handleMap=this.handleMap.bind(this);
		this.createFormHandler=this.createFormHandler.bind(this);
	}
	handleMap(addressObject)
	{
  
		let inputForm = {...this.state.createForm};
		inputForm['address']=addressObject.formatted_address; 
	  if(addressObject.geometry){
	let lat=addressObject.geometry.location.lat()
	let lng=addressObject.geometry.location.lng()
	inputForm['lat']= lat;
	inputForm['lng']= lng;

	  }
	  this.setState({ createForm: inputForm });

	}
   

	
	setDailyShedules(timeShedule)
	{
		//timeShedule.sort((day1, day2) => this.compareDicValues(day1,day2))
		 this.setState({dailyShedules:timeShedule });
	}
	
	   /*
	
	handles event of product partb select box
	
		*/
		handleDayChange = (index,selectedItem) => {
			let day = selectedItem.target.value;
	
			let dayValue = day;
			let keyName="day";
			let list = [...this.state.dailyShedules];
	
				list[index][keyName] = dayValue;
	
			
			console.log(list);
			this.setState({ dailyShedules: list });
		};
	
	
	
	
		dayTimeSheduleChange(index,time,shedule)
		{
	
			
			let list = [...this.state.dailyShedules];
			list[index][shedule] = time;
				// if(shedule==='opening_time')
				// {
				// 	list[index]['closing_time']=time;
				// }
			if (shedule === 'opening_time' && time > list[index]['closing_time']) {
				// list[index]['closing_time'] = time;
				list[index]['closing_time'] = time;
			}
			else if (shedule === 'closing_time' && time < list[index]['opening_time']) {
				list[index]['opening_time'] = time;
			}
			
			this.setState({ dailyShedules: list });
	
		}
	
	
	
	
	
	
	
	
	
	
	   //addd Return Methods
	
	 // handle click event of the Remove button
	 handleRemoveShedule = index => {
		  
		let list = [...this.state.dailyShedules];
		if(list[index]['add']){
		list.splice(index, 1);
		}else{
			list[index]['remove']=true;
		}
		this.setState({ dailyShedules: list });
		
	  }
	
	  // handle click event of the Add button
		handleAddShedule = () => {
			let dayList=[...this.state.dailyShedules];
			dayList.push({ day: 'SUNDAY', opening_time: 0 , closing_time: 0,add:true});
			this.setState({dailyShedules:dayList});    
	  };
	
	handleDelete(i) {
		const { tags } = this.state;
		this.setState({
		  tags: tags.filter((tag, index) => index !== i),
		});
	  }


	  setLocation(centerSelected)
	  {
		  let oldCenter={...this.state.center}
		  this.setState({center:centerSelected})

	  }
	  handleAddition(tag) {
	   this.setState(state => ({ tags: [...state.tags, tag] }));
	  }

	  handleDrag(tag, currPos, newPos) {
		const tags = [...this.state.tags];
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		// re-render
		this.setState({ tags: newTags });
	  }

	  handleTagClick(index) {
		console.log('The tag at index ' + index + ' was clicked');
	  }

	
	  createFormHandler = (event) => {

        let key = '';
        let value = '';
		console.log( event.target.value);
        let inputForm = {...this.state.createForm};

        if ((event.target.type == 'text')||(event.target.type == 'number')||(event.target.type == 'email')||(event.target.type == 'password')||(event.target.type == 'textarea') ){
            key = event.target.name;
            value = event.target.value;
			

				
        } else if (event.target.type == 'date') {
            key = event.target.name;
            value = event.target.value;

        } else if (event.target.type == 'checkbox') {
            key = event.target.name;
            value = event.target.checked;
        } else if (event.target.type == 'file') {
            key = event.target.name;
            value = event.target.files[0];
        }
        if (key == 'excelFile') {
            this.state.uploadExcelForm[key] = value;
        } else {
			console.log(inputForm);
            if (key) {
                inputForm[key] = value;
                this.setState({ createForm: inputForm });
            }
        }
    };


    handleFiles = (fileList) => {
		console.log(fileList);
        if (fileList) {
            this.galleryImages = fileList;
        }
    };

	onSelectTax = (selectedList, selectedItem) => {
		let inputForm = { ...this.state.createForm };
		console.log(selectedItem);
		let tax_id = selectedItem.id;
		inputForm["appliicable_tax"] = tax_id;
		this.setState({ createForm: inputForm });
	  };
	/*
    fetch elements from server and reload data elements
    */
	reloadTaxData = () => {
		let inputParams = {};
		new DataService("tax")
		  .getData(inputParams)
		  .then((response) => {
			let abstractData = response.data.map((obj) => ({
			  name: obj["title"],
			  id: obj.id,
			}));
			console.log(abstractData)
			this.setState({ taxOptions: abstractData });
		  });
	  };
/*
    fetch elements from server and reload data elements
    */
	reloadCategoryData = () => {
		let inputParams = {};
		new DataService("restaurant_food_category")
		  .getData(inputParams)
		  .then((response) => {
			let abstractData = response.data.data.map((obj) => ({
			  name: obj["title"],
			  id: obj.id,
			}));
			this.setState({ taxOptions: abstractData });
		  });
	  };

	addRestaurant = () => {
		if((this.validator.allValid())==true)
        {
        let inputParams = this.state.createForm;
		console.log(inputParams);

		if((!inputParams['order_notification_email']) ||(inputParams['order_notification_email'].length==0))
{
	inputParams['order_notification_email']=inputParams['email'];
}
if((!inputParams['store_email'])||(inputParams['store_email'].length==0))
{
	inputParams['store_email']=inputParams['email'];
}
        let formData = new FormData(); //formdata object
        Object.keys(inputParams).forEach(function (key) {
            formData.append(key, inputParams[key]); //append the values with key, value pair
        });
		console.log(this.galleryImages);
		for (let i = 0; i < this.galleryImages.length; i++) {
            formData.append('gallery_images', this.galleryImages[i]);
        }
		if(this.state.dailyShedules){
			let dailyShedulesObj=[...this.state.dailyShedules];
			//dailyShedulesObj=convertTimeDicToUTC(dailyShedulesObj);
			let time_sheduleString=JSON.stringify(dailyShedulesObj);
			formData.append('time_shedule',time_sheduleString);
		}

        this.dataHandler.createSubRestaurant(formData)
            .then((response) => {
                if (response.data['status'] === 'Sucess') {
                    alertCreation('SubRestaurant');
                    this.props.history.push('/myaccount/franchaises');

                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));

        
		}
		else
		{
			this.validator.showMessages();

		}
    };



	handleTimeZone=(selectedItem)=>
	{
	  let value = selectedItem.target.value;

	  let inputForm = {...this.state.createForm};
	  inputForm['time_zone'] = value;
	  this.setState({ createForm: inputForm });
	}








    hideAddressModal = () => this.setState({ showAddressModal: false });

	hideDelivery(name){
		this.setState({ showHideDelivery: !this.state.showHideDelivery });
	}

    getQty = ({id,quantity}) => {
    	//console.log(id);
    	//console.log(quantity);
	}

	render() {
		const { tags} = this.state;
    	return (
    		<section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
    		 <AddAddressModal show={this.state.showAddressModal} onHide={this.hideAddressModal}/>



	         <Container>
	            <Row>
			
	               <Col md={12}>
					   <div className="offer-dedicated-body-left">

						 <div className="pt-2"></div>

						 <span className="hidden" >{/*this.props.shouldHide ? 'hidden' : ''*/}
							<div className="bg-white rounded shadow-sm p-4 mb-4" >
								<h4 className="mb-1">Add Restaurant</h4>
								<h6 className="mb-3 text-black-50">provide Account Related Info</h6>
								<Row>
								<Col md={12}>

									<Form>
										<div className="form-row">
                                      		<Form.Group className="col-md-12">
											<Form.Label>Domain Name</Form.Label>
											{this.validator.message('title', this.state.createForm['title'], 'required|alpha_num_dash',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="text" onChange={this.createFormHandler}  name="title" placeholder="domain name" />

											</InputGroup>
											</Form.Group>

											<Form.Group className="col-md-12">
											<Form.Label>Restaurant Name</Form.Label>
											{this.validator.message('restaurant_name', this.state.createForm['restaurant_name'], 'required',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="text" onChange={this.createFormHandler}  name="restaurant_name" placeholder="Restaurant Name" />

											</InputGroup>
											</Form.Group>

											

											<Form.Group className="col-md-12">
											<Form.Label>Contact Number1</Form.Label>
											{this.validator.message('phone', this.state.createForm['phone'], 'required|phone',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="text" onChange={this.createFormHandler}  name="phone" placeholder="Landline/Mobile Number" />

											</InputGroup>
											</Form.Group>


											
											<SearchLocationInput
											setBack={this.handleMap}
     
    />
											
                                         
                                            <Form.Group className="col-md-12">
											<Form.Label>Contact Number2</Form.Label>
											{this.validator.message('alternative_phone', this.state.createForm['alternative_phone'], 'phone',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="text" onChange={this.createFormHandler} name="alternative_phone" placeholder="Alternative Number" />

											</InputGroup>
											</Form.Group>
											
											<Form.Group className="col-md-12">
											<Form.Label>Store Email</Form.Label>
											{this.validator.message('store_email', this.state.createForm['store_email'], 'required|email',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="email"  value={this.state.createForm['store_email']} name="store_email" onChange={this.createFormHandler} placeholder="Valid Email Id" />

											</InputGroup>
											</Form.Group>
											<Form.Group className="col-md-12">
											<Form.Label>Notification Email</Form.Label>
											{this.validator.message('order_notification_email', this.state.createForm['order_notification_email'], 'required|email',{ className: 'text-danger' }) }

											<InputGroup>
												<Form.Control type="email" value={this.state.createForm['order_notification_email']} name="order_notification_email" onChange={this.createFormHandler} placeholder="Valid Email Id" />

											</InputGroup>
											</Form.Group>


											

<Form.Group className="col-md-12">
	                                             <Form.Label>Time Zone
	                                             </Form.Label>
												
												 <Form.Control as="select"
												 
												 name="time_zone" onChange={this.handleTimeZone} 
												 defaultValue={this.state.createForm['time_zone']}


												 >
												{
												timeZoneList.map((timeObj)=>(

<option
value={timeObj}>{timeObj}</option>

												))}
   </Form.Control>


	                                          </Form.Group>
											  
											  <Form.Group className="col-md-12">
	                                             <Form.Label> Deliverable Distance(KiloMeters)</Form.Label>
	                                             <InputGroup>
	                                                <Form.Control type="number"  name="minim_delevery"  
													min={0}
													max={500}
													value={this.state.createForm['minim_delevery'] }
													onChange={this.createFormHandler} placeholder="Delivarable Distance" />
	                                                
	                                             </InputGroup>
	                                          </Form.Group>

	                                          <Form.Group className="col-md-12">
	                                             <Form.Label> Delivery Charge</Form.Label>
	                                             <InputGroup>
	                                                <Form.Control type="text"  name="delevery_charge"  value={this.state.createForm['delevery_charge'] }
													onChange={this.createFormHandler} placeholder="delivery amount" />
	                                                
	                                             </InputGroup>
	                                          </Form.Group>

	                                          <Form.Group className="col-md-12">
											 
	                                             <Form.Label> Make Deliver Free After
	                                             </Form.Label>
	                                             <Form.Control type="text" value={this.state.createForm['delevery_free']}  onChange={this.createFormHandler}  
												 
												 
												 name="delevery_free" placeholder="amount" />
	                                          </Form.Group>
											 










                                   
											  <Form.Group className="col-md-12">
											<Form.Label>Average Preparation Time</Form.Label>
											<Form.Control type="text" onChange={this.createFormHandler}  name="avg_preparation_time" placeholder=" e.g. 20 Min-40 Min" />
											</Form.Group>
											<Form.Group className="col-md-12">
	                                             <Form.Label>Provider URL</Form.Label>
	                                             <InputGroup>
	                                                <Form.Control type="payment_provided_url" 
													
													 onChange={this.createFormHandler}
													 name="payment_provided_url"
													 placeholder="Provider URL" />
	                                                <InputGroup.Append>
	                                                   <Button variant="outline-secondary" type="button" id="button-addon2"><Icofont icon="web" /></Button>
	                                                </InputGroup.Append>
	                                             </InputGroup>
	                                          </Form.Group>

	                                          <Form.Group className="col-md-12">
	                                             <Form.Label>APP ID
	                                             </Form.Label>
	                                             <Form.Control type="text" 
												  name="payment_app_id"
												  onChange={this.createFormHandler}

												 name="payment_app_id" placeholder="APP id" />
	                                          </Form.Group>

											  
	                                          <Form.Group className="col-md-12">
	                                             <Form.Label>HPP KEY
	                                             </Form.Label>
	                                             <Form.Control type="text" 
												  name="hpp_key"
												  onChange={this.createFormHandler}

												 
												 name="hpp_key" placeholder="HPP KEY" />
	                                          </Form.Group>
											  <Form.Group className="col-md-12">
	                                             <Form.Label>GST Number
	                                             </Form.Label>
	                                             <Form.Control type="text" 
												  name="gst_number"
												  onChange={this.createFormHandler}

												  placeholder="GST Number" />
	                                          </Form.Group>
											  <Form.Group className="col-md-12">
	                                             <Form.Label>PST Number
	                                             </Form.Label>
	                                             <Form.Control type="text" 
												  name="pst_number"
												  onChange={this.createFormHandler}

												 
												 placeholder="PST Number" />
	                                          </Form.Group>
										   <Form.Group className="col-md-12" hidden={!this.state.createForm['tax_enabled']}>
											 
											 <Form.Label> Applicable Tax
											 </Form.Label>
											 <Multiselect
                              options={this.state.taxOptions} // Options to display in the dropdown
                              singleSelect={true}
							  onSelect={this.onSelectTax} // Function will trigger on select event
                              displayValue="name" // Property name to display in the dropdown options
                            />
										  </Form.Group>
	                                        

											  <Form.Group className="col-md-12">
											 
											 <Form.Label> Restaurant Discount (Applicable for all items)
											 </Form.Label>
											 <Form.Control type="number"  onChange={this.createFormHandler}  
											 
											 
											 name="restaurant_discount" placeholder="amount" />
										  </Form.Group>
									
										</div>
									</Form>
								</Col>

								</Row>
							</div>
							<div className="pt-2"></div>
							<div className="pt-2"></div>

<div className="bg-white rounded shadow-sm p-4 mb-4" >
	<h4>Opening Hours</h4>
	<Row>
	<Col md={4} >
	DAY
	</Col>
	<Col md={3}>
OPENING TIME								
</Col>
	<Col md={3}>
	CLOSING TIME
	</Col>
	<Col></Col>
	<Col><button  className="ml-1" 
size="sm"

color="success" onClick={this.handleAddShedule}>+</button></Col>
	</Row>

	<Row>
		<Col md={12}></Col>
		
		</Row>{this.state.dailyShedules.map((sheduleObj,i)=>{
			if(!sheduleObj['remove']){
	return <Row>
	<Col md={4}>
	<Form.Group className="col-md-8">
					 

<Form.Control
as="select"
name="day"
value={sheduleObj['day']}
onChange={(e)=>{this.handleDayChange(i,e)}}
>
{weekDays.map((dayObj) => {

return <option  value={dayObj}>{dayObj}</option>;

})}
</Form.Control>
				  </Form.Group>

	</Col>
	<Col md={3}>
	<TimePicker  
	
	step={time_step}  
	onChange={(time)=>{
		this.dayTimeSheduleChange(i,time,'opening_time');
		}} value={sheduleObj['opening_time'] } />								</Col>
	<Col md={3}>
	<TimePicker  step={time_step}
	
	// start={toFormattedDate24(sheduleObj['opening_time'] )}
	onChange={(time)=>{
		this.dayTimeSheduleChange(i,time,'closing_time');
		}} 
		
		value={sheduleObj['closing_time'] } />

	</Col>
	<Col lg={2}>
{  <button
size="sm"
className="text-danger"
onClick={() => {this.handleRemoveShedule(i);
}}>-</button>}



</Col>

	</Row>


}


})}




</div>
<div className="pt-2"></div>
				


				
						  <div className="bg-white rounded shadow-sm p-4 mb-4" >
<Row>
	<col md={12}></col>
						  <Col md={42}>

						  <Form.Group className="col-md-12">
	                                             <Form.Label>
	                                             </Form.Label>

<Button  onClick={this.addRestaurant}>ADD Restaurant</Button>	                                          </Form.Group>
</Col></Row></div>
						 </span>
					  </div>
	               </Col>

	            </Row>
	         </Container>
	      </section>
    	);
    }
}


export default NewFranchaise;