import React from 'react';
import {Row,Col,Button} from 'react-bootstrap';
import CardItem from '../common/CardItem';
import CategoriesCarousel from '../common/CategoriesCarousel';
import OrderCard from '../common/OrderCard';
import DataService from "../../services/DataService";
import StripeCard from '../common/StripeCard';

class Stripeboard extends React.Component {
	constructor(props, context) {
	    super(props, context);
		this.dataHandler=new DataService("restaurants");

	    this.state = {
		itemsDisplayed:[],
		name:"",
		address:"",
		selectedRestaurant:this.dataHandler.getSelectedRestaurant(),

		logo:"",
		totalOrders:0,
		pendingOrders:0,
		paidOrders:0,
		canceledOrders:0,
		stripeLink: null,
		error: null,
		is_onboarding_completed:false
	    };
		
			this.reloadData()
	}


	
/*
    fetch elements from server and reload data elements
    */
    reloadData = () => {
		let inputParams = {};
		if (this.state.selectedRestaurant!=0)
		{
		   inputParams={rest_id:this.state.selectedRestaurant}
		}
		
        this.dataHandler.getStripeLink(inputParams).then((response) => {
            let currentData = response.data.data;
			let onboarding_completed=response.data.is_onboarding_completed
			
			console.log('stripe_data',currentData)
            this.setState({stripeLink:currentData.url,is_onboarding_completed:onboarding_completed});
        });
		
    };

/*
    fetch elements from server and reload data elements
    */
    reloadStatus = () => {
		
		let inputParams = {};
		if (this.state.selectedRestaurant!=0)
		{
		   inputParams={rest_id:this.state.selectedRestaurant}
		}
		
        this.dataHandler.get_stripe_stripe_status(inputParams).then((response) => {
            let currentData = response.data.data;
			
			console.log('stripe_data',currentData)
            this.setState({is_onboarding_completed:currentData.is_onboarding_completed});
        });
		
    };


	render() {

    	return (
    		<>
                
    		    <div className='p-4 bg-white shadow-sm'>
				<Row>
	               <Col md={12}>
	                  <h4 className="font-weight-bold mt-0 mb-3"> Stripe Connect -Settings</h4>
	               </Col>
	               <Col md={8}>
						<StripeCard 
							title= 'Connect'
							logoImage= 'img/stripe.png'
							imageclassName= 'mr-3'
							subTitle= {(this.state.is_onboarding_completed==true)?'active': 'Inactive'}
						/>
					</Col>
					<Col md={4}>

					<div>
                        <p>Click the link below to complete your account onboarding:</p>
                        <a href={this.state.stripeLink} target="_blank" rel="noopener noreferrer">Complete Account Onboarding</a>
                    </div>
					</Col>
				   </Row>
				   </div>
			</>
    	);
    }
}
export default Stripeboard;