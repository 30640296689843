import React from 'react';
import {Row,Col,Container,Tab,Nav,Form,InputGroup,Modal,ButtonToolbar,Button,ToggleButton,ToggleButtonGroup,Image} from 'react-bootstrap';
import Icofont from 'react-icofont';
import DishItem from '../common/DishItem';

class ViewMenuDiscriptionModal extends React.Component {

	render() {
    	return (
	        <Modal
	        	show={this.props.show}
	        	onHide={this.props.onHide}
		        centered
		   	  >
			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h5' id="add-address">Shishliki</Modal.Title>

			  </Modal.Header>

			  <Modal.Body>
  				<Form>
             <div className="form-row">
			 <div className="text-center">
	            <Image fluid className="cover" src="/img/mall-dedicated-banner.png" />
	         </div>
			 <h5 className="mt-4 mb-4">More Info</h5>
			 <p className="mb-3">Dal Makhani, Panneer Butter Masala, Kadhai Paneer, Raita, Veg Thali, Laccha Paratha, Butter Naan</p>
			 <Tab.Container defaultActiveKey="first">
				<section className="offer-dedicated-nav bg-white border-top-0 shadow-sm">
					<Container>
						<Row>
						<Col md={12}>
							
							<Nav  id="pills-tab">
								<Nav.Item>
									<Nav.Link eventKey="first">Add Extra</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="second">Compo</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="third">Offers</Nav.Link>
								</Nav.Item>
							
							</Nav>
						</Col>
						</Row>
					</Container>
				</section>
				<section className="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
		        <Container>
		            <Row>
		                <Col md={12}>
	                  		<div className="offer-dedicated-body-left">
							    <Tab.Content className='h-100'>
						            <Tab.Pane eventKey="first">
									<Row>
			                              <h5 className="mb-4 mt-3 col-md-12">Quick Bites <small className="h6 text-black-50">3 ITEMS</small></h5>
			                              <Col md={12}>
			                                 <div className="bg-white rounded border shadow-sm mb-4">
												 
				                                <DishItem
													id={1}
											   		title='Chicken Tikka Sub'
													price={250}
													priceUnit='$'
													getValue={this.getQty}
												
											   	/>
				                                <DishItem
													id={2}
											   		title='Cheese corn Roll'
													price={600}
													showBadge={true}
													badgeText='BEST SELLER'
													qty={1}
													priceUnit='$'
													getValue={this.getQty}
											   	/>
				                                <DishItem
													id={3}
											   		title='Chicken Tikka Sub'
													price={250}
													showBadge={true}
													badgeText='Pure Veg'
													badgeVariant="success"
													qty={2}
													priceUnit='$'
													getValue={this.getQty}
											   	/>
			                                 </div>
			                              </Col>
			                           </Row>
									</Tab.Pane>
									<Tab.Pane eventKey="second">
									<Row>
			                              <h5 className="mb-4 mt-3 col-md-12">Quick Bites <small className="h6 text-black-50">3 ITEMS</small></h5>
			                              <Col md={12}>
			                                 <div className="bg-white rounded border shadow-sm mb-4">
				                                <DishItem
													id={1}
											   		title='Chicken Tikka Sub'
													price={250}
													priceUnit='$'
													getValue={this.getQty}
											   	/>
				                                <DishItem
													id={2}
											   		title='Cheese corn Roll'
													price={600}
													showBadge={true}
													badgeText='BEST SELLER'
													qty={1}
													priceUnit='$'
													getValue={this.getQty}
											   	/>
				                                <DishItem
													id={3}
											   		title='Chicken Tikka Sub'
													price={250}
													showBadge={true}
													badgeText='Pure Veg'
													badgeVariant="success"
													qty={2}
													priceUnit='$'
													getValue={this.getQty}
											   	/>
			                                 </div>
			                              </Col>
			                           </Row>
									</Tab.Pane>
									<Tab.Pane eventKey="third">
									<Row>
			                              <h5 className="mb-4 mt-3 col-md-12">Quick Bites <small className="h6 text-black-50">3 ITEMS</small></h5>
			                              <Col md={12}>
			                                 <div className="bg-white rounded border shadow-sm mb-4">
				                                <DishItem
													id={1}
											   		title='Chicken Tikka Sub'
													price={250}
													priceUnit='$'
													getValue={this.getQty}
											   	/>
				                                <DishItem
													id={2}
											   		title='Cheese corn Roll'
													price={600}
													showBadge={true}
													badgeText='BEST SELLER'
													qty={1}
													priceUnit='$'
													getValue={this.getQty}
											   	/>
				                                <DishItem
													id={3}
											   		title='Chicken Tikka Sub'
													price={250}
													showBadge={true}
													badgeText='Pure Veg'
													badgeVariant="success"
													qty={2}
													priceUnit='$'
													getValue={this.getQty}
											   	/>
			                                 </div>
			                              </Col>
			                           </Row>
									</Tab.Pane>
								</Tab.Content>
							</div>
						</Col>
					</Row>
				</Container>
				</section>
			  </Tab.Container>
                
             </div>
          </Form>
			  </Modal.Body>

			  <Modal.Footer>
			    <Button type='button' onClick={this.props.onHide} variant="outline-primary" className="d-flex w-50 text-center justify-content-center">CANCEL</Button>
			    <Button type='button' variant="primary" className='d-flex w-50 text-center justify-content-center'>ADD</Button>
			  </Modal.Footer>
			</Modal>
    	);
    }
}
export default ViewMenuDiscriptionModal;