import React from 'react';
import {Link} from 'react-router-dom';
import {Image,Card,Media,Button} from 'react-bootstrap';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types'; 

class CategoryCard extends React.Component {
  
	render() {
    	return (
    		<Card className="bg-white payments-item mb-4 shadow-sm">
            	<div className="gold-members p-4">
              <span className="float-right"> 
   
  
   <Button variant='outline-secondary' onClick={() => this.props.showUpdateCategoryModel(this.props.catObj)} size="sm" title="edit"><Icofont icon='pencil-alt-1'/></Button>

     
     <Button variant='outline-secondary' onClick={this.props.onClick}  size="sm" className="ml-2" title="delete"><Icofont icon='trash'/></Button>
     
   </span>
                  <Media>
                     <Media.Body>
                        <h6 className="mb-1">{this.props.title}</h6>
	                      {this.props.subTitle?
			                	<p>
			                		{this.props.subTitle}
			                	</p>
                        
			                	:""
			              }	<p>
                    Priority:{this.props.priority}
                  </p>
                       
                    </Media.Body>
           		  </Media>
           		</div>
            </Card>
    	);
    }
}

CategoryCard.propTypes = {
  title: PropTypes.string.isRequired,
  logoImage: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  imageAlt: PropTypes.string,
  imageclassName: PropTypes.string,
  onClick: PropTypes.func.isRequired
};
CategoryCard.defaultProps = {
  subTitle: '',
  imageAlt: '',
  imageclassName: '',
}

export default CategoryCard;