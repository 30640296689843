import React from 'react';
import {Row,Col,Button} from 'react-bootstrap';
import CardItem from '../common/CardItem';
import CategoriesCarousel from '../common/CategoriesCarousel';
import OrderCard from '../common/OrderCard';
import SummaryCard from '../common/SummaryCard';
import DataService from "../../services/DataService";


class Dashboard extends React.Component {
	constructor(props, context) {
	    super(props, context);
		this.dataHandler=new DataService("restaurants");
		this.cartHandler = new DataService("carts");

	    this.state = {
		itemsDisplayed:[],
		name:"",
		address:"",
		logo:"",
		totalOrders:0,
		pendingOrders:0,
		paidOrders:0,
		canceledOrders:0,
		
			Total: 0,
			recieved_unaccepted: 0,
			recieved_accepted: "0",
			recieved_accepted_complted: "0",
			recieved_requested_delivery: "0",
			recieved_rejected: "0"
		
	    };
		this.reloadOrderData();

	}


	
/*
    fetch elements from server and reload data elements
    */
    reloadData = () => {
        let inputParams = {};
        this.dataHandler.getMyProfile().then((response) => {
            let currentData = response.data.data;
			this.dataHandler.setProfile(currentData);
			let restaurantName=currentData.restaurant_name;
			let restaurantAddress=currentData.address;
			let logo=currentData.logo;
			console.log(currentData)
            this.setState({itemsDisplayed:currentData});
        });
    };






/*
    fetch elements from server and reload data elements
    */
	reloadOrderData = () => {
		let inputParams = {};
		this.cartHandler.getOrderedCarts(inputParams).then((response) => {
		  let currentData = response.data.data;
			let Total=0,recieved_unaccepted=0,recieved_accepted_complted=0,
			recieved_rejected=0,recieved_requested_delivery=0,recieved_accepted=0;
		  if(currentData){
		  let totalOrders=currentData.length;
		  let summary= response.data.summary;
			
			Total=summary['Total'];
			recieved_unaccepted=summary['recieved_unaccepted'];
			recieved_accepted=summary['recieved_accepted'];
			recieved_accepted_complted=summary['recieved_accepted_complted'];
			recieved_requested_delivery=summary['recieved_requested_delivery'];
			recieved_rejected=summary['recieved_rejected'];
		  let paidOrders=currentData.filter(order => order.payment_status===true).length;
		  let pendingOrders=currentData.filter(order => order.payment_status===false).length;
		  this.setState({ totalOrders: Total,
			recieved_accepted:recieved_accepted,
			recieved_unaccepted:recieved_unaccepted,
			recieved_accepted_complted:recieved_accepted_complted,
			recieved_requested_delivery:recieved_requested_delivery,
			recieved_rejected:recieved_rejected,
			paidOrders:paidOrders, pendingOrders:pendingOrders});

		  }

		  console.log(currentData);
		  this.setState({ itemsDisplayed: currentData });
		});
	  };





	render() {

    	return (
    		<>
                
    		    <div className='p-4 bg-white shadow-sm'>
				<Row>
	               <Col md={12}>
	                  <h4 className="font-weight-bold mt-0 mb-3">Orders Summary-Today</h4>
	               </Col>
	               <Col md={6}>
						<SummaryCard 
							title= 'Total'
							logoImage= 'img/fast-food.png'
							imageclassName= 'mr-3'
							subTitle= {this.state.totalOrders}
							onClick={() => this.setState({ showDeleteModal: true })}
						/>
					</Col>
					<Col md={6}>
						<SummaryCard 
							title= 'Completed'
							logoImage= 'img/fast-food.png'
							imageclassName= 'mr-3'
							subTitle= {this.state.recieved_accepted_complted}
							onClick={() => this.setState({ showDeleteModal: true })}
						/>
	               </Col>
				   <Col md={6}>
						<SummaryCard 
							title= 'Canceled'
							logoImage= 'img/fast-food.png'
							imageclassName= 'mr-3'
							subTitle= {this.state.recieved_rejected}
							onClick={() => this.setState({ showDeleteModal: true })}
						/>
	               </Col>
				   <Col md={6}>
						<SummaryCard 
							title= 'Un Accepted'
							logoImage= 'img/fast-food.png'
							imageclassName= 'mr-3'
							subTitle= {this.state.recieved_unaccepted}
							onClick={() => this.setState({ showDeleteModal: true })}
						/>
	               </Col>
				   <Col md={6}>
						<SummaryCard 
							title= 'Accepted & Request For Delivery'
							logoImage= 'img/fast-food.png'
							imageclassName= 'mr-3'
							subTitle= {this.state.recieved_requested_delivery}
							onClick={() => this.setState({ showDeleteModal: true })}
						/>
	               </Col>
				   <Col md={6}>
						<SummaryCard 
							title= 'Accepted & OnGoing'
							logoImage= 'img/fast-food.png'
							imageclassName= 'mr-3'
							subTitle= {this.state.recieved_accepted}
							onClick={() => this.setState({ showDeleteModal: true })}
						/>
	               </Col>
				   </Row>
				   </div>
			</>
    	);
    }
}
export default Dashboard;