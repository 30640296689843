import React, { useState, useEffect, useRef } from "react";
import {Row,Col,Container,Badge,Form,InputGroup} from 'react-bootstrap';

let autoComplete;

const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function() {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef,setBack) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: ["address"], componentRestrictions: { country: ["us","ca" ]} }
  );
  autoComplete.setFields(["address_components", "formatted_address","geometry"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery,setBack)
  );
}

async function handlePlaceSelect(updateQuery,setBack) {
  const addressObject = autoComplete.getPlace();

  const query = addressObject.formatted_address;
  updateQuery(query);
  if(addressObject.geometry){
let lat=addressObject.geometry.location.lat()
let lng=addressObject.geometry.location.lng()
console.log(lat,lng);
  }
  setBack(addressObject);
}

function SearchLocationInput(props) {
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCAQBQvrdz8mU3j_zYpxIcdm1yN2N2RUAU&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef,props.setBack)
    );
  }, []);

  return (
   

<Form.Group className="col-md-12">
											<Form.Label>Address</Form.Label>
                      {props.address}
   
											<InputGroup>
												<Form.Control type="text" name="address"    
                         ref={autoCompleteRef}
                       
 placeholder="Address" />

											</InputGroup>
											</Form.Group>
  );
}

export default SearchLocationInput;