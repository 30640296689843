import React from 'react';
import {Link} from 'react-router-dom';
import {Image,Card,Media} from 'react-bootstrap';
import Icofont from 'react-icofont';
import PropTypes from 'prop-types'; 

class StripeCard extends React.Component {

	render() {
    	return (
    		<Card className="bg-white payments-item mb-4 shadow-sm">
            	<div className="gold-members p-4">
                  <Media>
            		<Image src={this.props.logoImage} alt={this.props.imageAlt} className={this.props.imageclassName} /> 
                     <Media.Body>
                        <h6 className="mb-1">{this.props.title}</h6>
	                      {this.props.subTitle?
			                	<p>
			                		{this.props.subTitle}
			                	</p>
			                	:""
			              }
                        <Link className="text-success" to="#" onClick={this.props.onClick}>
        <img src="/img/reload-icon.png" alt="Icon" style={{ width: '20px', height: '20px', marginRight: '8px' }} />
         Update Status
      </Link>	                   
                    </Media.Body>
           		  </Media>
           		</div>
            </Card>
    	);
    }
}

StripeCard.propTypes = {
  title: PropTypes.string.isRequired,
  logoImage: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  imageAlt: PropTypes.string,
  imageclassName: PropTypes.string,
  onClick: PropTypes.func.isRequired
};
StripeCard.defaultProps = {
  subTitle: '',
  imageAlt: '',
  imageclassName: '',
}

export default StripeCard;