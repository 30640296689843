import React from 'react';
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap';
import DeleteAddressModal from '../../modals/DeleteAddressModal';
import Icofont from 'react-icofont';
import { NavLink, Link } from 'react-router-dom';
import { NavDropdown, Nav, Table, Image } from 'react-bootstrap';
import DropDownTitle from '../../common/DropDownTitle'
import { DragSwitch } from 'react-dragswitch';
import Paginator from '../../../helpers/Pagination'
import DataService from '../../../services/DataService'
import { alertCreation,alertError,confirmDeleteAction,alertDeletion } from '../../Alerts';
import CategoryModal from '../../modals/CategoryModal';
class Franchaise extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.dataHandler = new DataService("restaurants");
        this.dataSource = []
        this.state = {
            itemsDisplayed: this.dataSource,
            totaldatas: this.dataSource.length,
            paginator: new Paginator(this.dataSource.length),
            createForm: {},
            showDeleteModal: false,
            showAddCatgoery: false,
            activePage: 15,
        };

        this.reloadData = this.reloadData.bind(this);
        this.reloadData();
        this.getNextPage = this.getNextPage.bind(this);
        this.getPrevPage = this.getPrevPage.bind(this);
    }

    hideCatogeryNew = () => this.setState({ showAddCatgoery: false });
    hideDeleteModal = () => this.setState({ showDeleteModal: false });
    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
    }


    /**
     * Gets the next page
     */
    getNextPage = () => {
        this.state.paginator.getNextPage();
        this.setState({
            itemsDisplayed: this.dataSource.slice(this.state.paginator.startIndex, this.state.paginator.endIndex),
        });
    };



    deleteConfirm = (id) => {
        confirmDeleteAction('Restaurant').then((userResponse) => {
            if (userResponse.isConfirmed == true) {
                this.deleteRestaurant(id);
            }
        });
    };

    deleteRestaurant = (id) => {
        let inputParams = {};
        inputParams['id'] = id;
        this.dataHandler.deleteData(inputParams)
            .then((response) => {
                console.log(response);

                if (response.data['status'] === 'sucess') {
                    alertDeletion('Restaurant');
                    this.reloadData();
                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));
    };


    /**
     * Gets the prev page
     */
    getPrevPage = () => {
        this.state.paginator.getPrevPage();
        this.setState({
            itemsDisplayed: this.dataSource.slice(this.state.paginator.startIndex, this.state.paginator.endIndex),
        });
    };
    /*
fetch elements from server and reload data elements
*/
    reloadData = () => {
        let inputParams = {};
        this.dataHandler.getSubRestaurants(inputParams).then((response) => {
            console.log(response.data)

        
            let currentData = response.data.data;
            this.setDataOnComponent(currentData);
        });
    };
    /**
* Form for user login
* @param {currentDataSource} variable which contains data object
*/
    setDataOnComponent = (currentDataSource) => {
        this.dataSource = currentDataSource;
        console.log(this.dataSource);
        if (this.dataSource)
        this.state.paginator.reset(this.dataSource.length);

        this.setState({
            itemsDisplayed: this.dataSource.slice(this.state.paginator.startIndex, this.state.paginator.endIndex),
        });
    };

    render() {
        return (
            <>
                <DeleteAddressModal show={this.state.showDeleteModal} onHide={this.hideDeleteModal} />
                <div className='p-4 bg-white shadow-sm'>
                    <Row>
                        <Col md={12}>
                            <Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
                                <Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="">
                                    <h4 className="font-weight-bold mt-0 mb-4">  Restaurants </h4><span className="sr-only">(current)</span>
                                </Nav.Link>

                                <NavDropdown alignRight
                                    title={
                                        <DropDownTitle
                                            className='d-inline-block mt-2'
                                            image="img/filter.png"
                                            imageAlt='user'
                                            imageClass="nav-osahan-pic"
                                            title=''
                                        />
                                    }
                                >
                                    <NavDropdown.Item eventKey={4.1} as={NavLink} activeclassname="active" to="/myaccount/orders"><Icofont icon='database' /> All</NavDropdown.Item>
                                    <NavDropdown.Item eventKey={4.2} as={NavLink} activeclassname="active" to="/myaccount/offers"><Icofont icon='database-add' /> Recentely Added</NavDropdown.Item>
                                    <NavDropdown.Item eventKey={4.3} as={NavLink} activeclassname="active" to="/myaccount/favourites"><Icofont icon='arrow-up' /> Blocked</NavDropdown.Item>
                                    <NavDropdown.Item eventKey={4.4} as={NavLink} activeclassname="active" to="/myaccount/payments"><Icofont icon='cart' /> Closed</NavDropdown.Item>
                                </NavDropdown>
                                <Link to='/myaccount/newFranchaise' onClick="" className="text-primary mr-3 mt-3"><i className="icofont-plus-circle"></i> New Restaurant</Link>
                            </Nav>
                        </Col>
                        <Col md={12}>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>SI</th>
                                        <th> ID</th>

                                        <th> Name</th>
                                        <th>Address</th>
                                        <th>Phone</th>
                                        <th>Avg Preperation Time</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.itemsDisplayed.map((dataObj, idx) =>
                                        <tr>
                                            <td>{idx + this.state.paginator.startIndex + 1}</td>
                                            <td>{dataObj.restaurent_id}</td>

                                            <td>{dataObj.title}</td>
                                            <td>{dataObj.address}</td>
                                            <td>{dataObj.phone}</td>
                                            <td>{dataObj.avg_preparation_time}</td>


                                            <td>



                                                <Button variant='outline-secondary' onClick={() => {
                                                    this.deleteConfirm(dataObj.id);
                                                }} size="sm" className="ml-2" title="delete"><Icofont icon='trash' /></Button>

                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Col>
                        <Col md={12}>
                            {/* pagination */}
                            <div className="d-inline-block align-middle float-lg-right">
                                <Row>
                                    <Col sm={9} className="align-self-center">
                                        Showing {this.state.paginator.startIndex + 1} -{' '}
                                        {this.state.paginator.endIndex} of {this.state.paginator.itemCount}
                                    </Col>
                                    <Col sm={3}>
                                        <ButtonGroup className="float-right">
                                            {this.state.paginator.page === 1 ? (
                                                <Button color="white" className="btn-sm" disabled>
                                                    <i className="uil uil-angle-left"></i>
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    className="btn-sm"
                                                    onClick={this.getPrevPage}>
                                                    <i className="uil uil-angle-left">{"<<"}</i>
                                                </Button>
                                            )}

                                            {this.state.paginator.page < this.state.paginator.totalPage ? (
                                                <Button
                                                    color="primary"
                                                    onClick={this.getNextPage}>
                                                    <i className="uil uil-angle-left">{">>"}</i>
                                                </Button>
                                            ) : (
                                                <Button color="white" className="btn-sm" disabled>
                                                    <i className="uil uil-angle-right"></i>
                                                </Button>
                                            )}
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                    </Row>
                </div>
            </>
        );
    }
}
export default Franchaise;