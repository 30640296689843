import React from 'react';
import {Row,Col,Button,Spinner} from 'react-bootstrap';
import DishItem from '../common/DishItem';
import CategoriesCarousel from '../common/CategoriesCarousel';
import Icofont from 'react-icofont';
import {NavLink} from 'react-router-dom';
import {NavDropdown,Nav} from 'react-bootstrap';
import DropDownTitle from '../common/DropDownTitle';
import DataService from '../../services/DataService';
import {confirmDeleteAction,alertError,alertCreation,alertDeletion} from '../../components/Alerts';
class DishItems extends React.Component {
	constructor(props, context) {
	    super(props, context);
		this.dataHandler=new DataService("restaurant_food_category");
	
	    this.state = {
		itemsDisplayed:[],
		editDisplayed:false,
		selectedRestaurant:this.dataHandler.getSelectedRestaurant()
	
	    };
		this.deleteFood=this.deleteFood.bind(this);
		this.updateUpCellEnabled=this.updateUpCellEnabled.bind(this);
		this.deleteConfirm=this.deleteConfirm.bind(this);
		this.reloadData();
	
	}


	updateUpCellEnabled = (id,upcellStatus) => {

		  let formData = new FormData(); //formdata object
		
			formData.append('upcell_enabled', upcellStatus); //append the values with key, value pair
		 
	  
			new DataService('restaurant_food_items')
			.updateDataFromForm(formData, id)
			.then((response) => {
			  if (response.data["status"] === "Sucess") {
				this.props.history.push("dishItems");
			  } else {
				alertError(response.data["message"]);
			  }
			})
			.catch((err) => alert(err));
		
	  };
	
	deleteConfirm = (id) => {

        confirmDeleteAction('dishitem').then((userResponse) => {
            if (userResponse.isConfirmed == true) {
                this.deleteFood(id);
            }
        });
    };

    deleteFood = (id) => {
        let inputParams = {};
        inputParams['id'] = id;
        new DataService('restaurant_food_items').deleteData(inputParams)
            .then((response) => {
				console.log(response);

                if (response.data['status'] === 'sucess') {
                    alertDeletion('dishItem');
                    this.reloadData();
                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));
    };
	
    /*
    fetch elements from server and reload data elements
    */
    reloadData = () => {
        let inputParams = {};
		if (this.state.selectedRestaurant!=0)
		{
		   inputParams={rest_id:this.state.selectedRestaurant}
		}
		
        this.dataHandler.getData(inputParams).then((response) => {
            let currentData = response.data.data;
            this.setState({itemsDisplayed:currentData},()=>{
				var foodKey="food_item_"+this.state.selectedRestaurant
				this.dataHandler.setValue({name:foodKey,value:JSON.stringify(currentData)})
			});
        });
    };
	render() {

		

    	return (
    		<>
    		    <div className='p-4 bg-white shadow-sm'>
	              <Row>
				  	
	                 <Col md={12}>
						<Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
							<Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="">
								<h4 className="font-weight-bold mt-0 mb-4">  Dish List </h4><span className="sr-only">(current)</span>
							</Nav.Link>

									<Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="/myaccount/adddishitem"  className="pt-3">
								New Item<Icofont icon='plus-circle'/><span className="sr-only">(current)</span>
							</Nav.Link>
						</Nav>
	               </Col>

	              </Row>

				  

{this.state.itemsDisplayed.map((dataObj,idx)=>

				 						 <Row>
			                              <h5 className="mb-4 mt-3 col-md-12">{dataObj.title} <small className="h6 text-black-50">{(typeof(dataObj.foods_tagged_with)!='undefined')&& dataObj.foods_tagged_with.length} ITEMS</small></h5>
			                              <Col md={12}>
										  {(typeof(dataObj.foods_tagged_with)!='undefined')&& (dataObj.foods_tagged_with.length>0)&&
			                                 <div className="bg-white rounded border shadow-sm mb-4">
												{dataObj.foods_tagged_with.map((foodObj,idx)=>
				                                <DishItem
												foodObj={foodObj}
													id={foodObj.id}
											   		title={foodObj.title}
													price={foodObj.price}
													priceUnit='$'
													getValue={this.getQty}
													deleteFood={this.deleteFood}
													foodObj={foodObj}
													deleteConfirm={this.deleteConfirm}
													updateUpCellEnabled={this.updateUpCellEnabled}
											   	/>
				                           
												)}
			                                 </div>}
			                              </Col>
			                           </Row>
)}
			                           
			    
				</div>
		    </>
    	);
    }
}
export default DishItems;