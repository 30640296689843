import React from "react";
import { Switch, Route } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";

import { Row, Col, Container, Image, Form } from "react-bootstrap";
import Offers from "./myaccount/offers/Offers";
import Orders from "./myaccount/Orders";
import Coupons from "./myaccount/Coupons";
import ExtraPayments from "./myaccount/ExtraPayments";
import AddCoupon from "./AddCoupon";
import DeliveryOrders from "./myaccount/DeliveryOrders";
import DishItems from "./myaccount/DishItems";
import Categorymenu from "./myaccount/Categorymenu";
import MenuDay from "./myaccount/MenuDay";
import Addresses from "./myaccount/Addresses";
import EditProfileModal from "./modals/EditProfileModal";
import Dashboard from "./myaccount/Dashboard";
import ProfileEdit from "./ProfileEdit";
import AddFoodItem from "./AddFoodItem";
import EditFoodItem from "./EditFoodItem";
import DataService from "../services/DataService";
import Reports from "./myaccount/Reports";
import DeliveryOnlyReports from "./myaccount/DeliveryOnlyReports";
import Franchaise from "./myaccount/franchaise/Franchaise";
import NewFranchaise from "./myaccount/franchaise/NewFranchaise";
import EditFranchaise from "./myaccount/franchaise/EditFranchaise";
import Users from "./myaccount/sub_users/Users";
import { type } from "jquery";
import SummaryReports from "./myaccount/SummaryReports";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import Notifications from "./myaccount/Notifications";
import { Multiselect } from "multiselect-react-dropdown";
import { getLoggedInUser } from "../helpers/authUtils";
// 7start
import DishCustomization from "./myaccount/DishCustomization";
import Customers from "./myaccount/customers";
import FailedOrders from "./myaccount/failedOrders";
import CustomerNotifications from "./myaccount/CustomerNotifications";
import Stripeboard from "./myaccount/Stripeboard";
// 7end

class MyAccount extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.dataHandler = new DataService("restaurants");
		this.notificationHandler = new DataService("notifications");
		this.selectedRestaurant = this.dataHandler.getSelectedRestaurant();
		this.user = getLoggedInUser();
		this.state = {
			itemsDisplayed: [],
			subRestaurants: [],
			name: "",
			address: "",
			logo: "/img/1.jpg",
			phone: "",
			notifications: [],
			currentNotifications: [],
			selectedRestaurant: this.dataHandler.getSelectedRestaurant(),
		};
		this.scheduledNotifications = [];
		this.reloadData();
		this.reloadNotificationData();
	}

	storeDataToSessionStorage = (key, value) => {
		window.sessionStorage.setItem(key, JSON.stringify(value));
	};

	getDataOnSessionStorage = (key, value) => {
		return JSON.parse(window.sessionStorage.getItem(key));
	};

	/*
fetch elements from server and reload data elements
*/
	reloadRestaurantsData = () => {
		let inputParams = {};
		this.dataHandler.getSubRestaurants(inputParams).then((response) => {
			console.log(response.data);

			let currentData = response.data.data;
			let abstractData = currentData.map((obj) => ({
				name: obj["title"],
				id: obj.id,
			}));
			if (this.user.is_sub_user == false) {
				abstractData = [{ name: "Main", id: 0 }, ...abstractData];
			}
			console.log("parent", this.state.parent);
			this.setState({ subRestaurants: abstractData });
		});
	};

	/*
    fetch elements from server and reload data elements
    */
	reloadData = (reloadRestaurants = true) => {
		let inputParams = {};
		console.log("currentSelection", this.state.selectedRestaurant);
		if (this.state.selectedRestaurant != 0) {
			inputParams = { rest_id: this.state.selectedRestaurant };
		}
		this.dataHandler.getMyProfile(inputParams).then((response) => {
			let currentData = response.data.data;
			this.dataHandler.setProfile(currentData);
			let restaurantName = currentData.restaurant_name;
			let restaurantAddress = currentData.address;
			let logo = currentData.logo;
			let phone = currentData.phone;
			this.setState(
				{
					itemsDisplayed: [],
					name: restaurantName,
					address: restaurantAddress,
					logo: logo,
					phone: phone,
					currentData: currentData,
					selectedRestaurant: currentData,
					parent: { id: 0, name: restaurantName },
				},
				() => {
					if (reloadRestaurants) {
						this.reloadRestaurantsData();
					}
				}
			);
			console.log(currentData);
			this.setState({ itemsDisplayed: currentData });
		});
	};

	processScheduledNotifications = () => {
		let notifications = this.scheduledNotifications;
		if (notifications.length > 0) {
			this.processNotifications(notifications);
		}
		console.log(notifications);
	};

	//   test = () => {
	//    console.log(this.state.scheduledNotifications)
	//   }

	setScheduledNotifications = (notifications) => {
		let bodyEle = document.getElementsByTagName("body");
		this.scheduledNotifications = notifications;
		if (notifications.length > 0) {
			if (bodyEle.length > 0) {
				bodyEle = bodyEle[0];
				bodyEle.onclick = this.processScheduledNotifications;
			}
		} else {
			if (bodyEle.length > 0) {
				bodyEle = bodyEle[0];
				bodyEle.onclick = "";
			}
		}
	};

	setCurrentNotifications = (notifications, action = "add") => {
		if (notifications.length > 0) {
			let currentNotifications = [...this.state.currentNotifications];
			if (action === "add") {
				currentNotifications.push(...notifications);
			}
			this.setState({ currentNotifications: currentNotifications });
		}
	};

	processNotifications = (notifications) => {
		let currentDateTime = new Date();
		let startDateTime = null;
		let endDateTime = null;
		let showAfter = 0;
		let currentNotifications = [];
		let scheduledNotifications = [];
		notifications.forEach((notification) => {
			if (notification.status === 2) {
				NotificationManager.info(
					notification.content,
					notification.title,
					6000
				);
				currentNotifications.push(notification);
			} else if (notification.status === 1) {
				startDateTime = notification.start_date_time
					? new Date(notification.start_date_time)
					: null;
				endDateTime = notification.end_date_time
					? new Date(notification.end_date_time)
					: null;
				if (startDateTime && endDateTime) {
					if (
						currentDateTime.valueOf() >= startDateTime.valueOf() &&
						currentDateTime.valueOf() <= endDateTime.valueOf()
					) {
						NotificationManager.info(
							notification.content,
							notification.title,
							6000
						);
						currentNotifications.push(notification);
					} else if (
						currentDateTime.valueOf() < startDateTime.valueOf()
					) {
						// showAfter = startDateTime.valueOf() - currentDateTime.valueOf()
						scheduledNotifications.push(notification);
						//
						// setTimeout(()=> {
						//    NotificationManager.info(notification.content,notification.title,6000)
						//    this.setCurrentNotifications([notification])
						// },showAfter)
					}
				} else if (startDateTime) {
					if (currentDateTime.valueOf() >= startDateTime.valueOf()) {
						NotificationManager.info(
							notification.content,
							notification.title,
							6000
						);
						currentNotifications.push(notification);
					} else if (
						currentDateTime.valueOf() < startDateTime.valueOf()
					) {
						// showAfter = startDateTime.valueOf() - currentDateTime.valueOf()
						scheduledNotifications.push(notification);
						// showAfter = startDateTime.valueOf() - currentDateTime.valueOf()
						// setTimeout(()=> {
						//    NotificationManager.info(notification.content,notification.title,6000)
						//    this.setCurrentNotifications([notification])
						// },showAfter)
					}
				} else if (endDateTime) {
					if (currentDateTime.valueOf() <= endDateTime.valueOf()) {
						NotificationManager.info(
							notification.content,
							notification.title,
							6000
						);
						currentNotifications.push(notification);
					}
				}
			}
		});
		this.setCurrentNotifications(currentNotifications);
		this.setScheduledNotifications(scheduledNotifications);
	};

	formatDateTime = (dateTime) => {
		let hour = ("0" + dateTime.getHours()).slice(-2);
		let minitue = ("0" + dateTime.getMinutes()).slice(-2);
		let year = dateTime.toLocaleString("default", { year: "numeric" });
		let month = dateTime.toLocaleString("default", { month: "2-digit" });
		let day = dateTime.toLocaleString("default", { day: "2-digit" });
		return [year, month, day].join("-") + "T" + [hour, minitue].join(":");
	};

	convertUtcToLocal = (data, many = false) => {
		if (!many) {
			if (data["start_date_time"]) {
				data["start_date_time"] = this.formatDateTime(
					new Date(data["start_date_time"])
				);
			}
			if (data["end_date_time"]) {
				data["end_date_time"] = this.formatDateTime(
					new Date(data["end_date_time"])
				);
			}
		}
		return data;
	};
	onSelectRestaurant = (selectedItem) => {
		let selectedRestaurant = selectedItem.target.value;
		this.dataHandler.setSelectedRestaurant(selectedRestaurant);
		this.setState({ selectedRestaurant: selectedRestaurant }, () => {
			window.location.reload();

			//this.reloadData(false)
		});
	};
	reloadNotificationData = () => {
		let inputParams = {};
		let data = [];
		this.notificationHandler.getData(inputParams).then((response) => {
			// let data = response.data
			data = response.data.data.map((item, index) => {
				item = this.convertUtcToLocal(item);
				return item;
			});
			this.processNotifications(data);
			this.setState({
				notifications: data,
			});
		});
	};

	render() {
		return (
			<>
				{/*
         <div>
            { this.state.currentNotifications.length &&
            <NavLink className="" activeClassName="active" exact to="/myaccount/notifications">
               <span class="notifications-info fa-stack fa-2x" data-count={this.state.currentNotifications.length}>
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="fa fa-bell fa-stack-1x fa-inverse"></i>
               </span>
            </NavLink>
            }
            <NotificationContainer/>
         </div>
         */}
				<section className="section pt-4 pb-4 osahan-account-page">
					<Container>
						<Row>
							<Col md={3}>
								<div className="osahan-account-page-left shadow-sm bg-white h-100">
									<div className="border-bottom p-4">
										<div className="osahan-user text-center">
											<div className="osahan-user-media">
												<Form.Label>
													{" "}
													Restaurants
												</Form.Label>

												<Form.Control
													as="select"
													name="selectedRestaurant"
													onChange={
														this.onSelectRestaurant
													}
												>
													{this.state.subRestaurants &&
														this.state.subRestaurants.map(
															(restObj) => (
																<option
																	selected={
																		restObj.id ==
																		this
																			.selectedRestaurant
																	}
																	value={
																		restObj.id
																	}
																>
																	{restObj.name}
																</option>
															)
														)}
												</Form.Control>
												<p>--------</p>
												<Image
													className="mb-3 rounded-pill shadow-sm mt-1"
													src={this.state.logo}
													alt="resturent"
												/>
												<div className="osahan-user-media-body">
													<h6 className="mb-2">
														{this.state.name}
													</h6>
													<p className="mb-1">
														{this.state.phone}
													</p>
													<p className="mb-0 text-black font-weight-bold">
														<Link
															to="/myaccount/editprofile"
															className="text-primary mr-3"
														>
															<i className="icofont-ui-edit"></i>{" "}
															EDIT
														</Link>
													</p>
												</div>
											</div>
										</div>
									</div>
									<ul className="nav flex-column border-0 pt-4 pl-4 pb-4">
										<li className="nav-item">
											<NavLink
												className="nav-link"
												activeClassName="active"
												exact
												to="/myaccount/dashboard"
											>
												<i className="icofont-restaurant-menu"></i>{" "}
												Dashboard
											</NavLink>
										</li>

										<li className="nav-item">
											<NavLink
												className="nav-link"
												activeClassName="active"
												exact
												to="/myaccount/orders"
											>
												<i className="icofont-food-cart"></i>{" "}
												Orders
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink
												className="nav-link"
												activeClassName="active"
												exact
												to="/myaccount/failed_orders"
											>
												<i className="icofont-food-cart"></i>{" "}
												Failed Orders
											</NavLink>
										</li>
										{(this.user.is_sub_user == false ||
											this.user.can_manage_food == true) && (
											<>
												<li className="nav-item">
													<NavLink
														className="nav-link"
														activeClassName="active"
														exact
														to="/myaccount/categorymenu"
													>
														<i className="icofont-sale-discount"></i>{" "}
														Category
													</NavLink>
												</li>

												<li className="nav-item">
													<NavLink
														className="nav-link"
														activeClassName="active"
														exact
														to="/myaccount/dishCustomization"
													>
														<i className=""></i> Dish
														Customization
													</NavLink>
												</li>

												<li className="nav-item">
													<NavLink
														className="nav-link"
														activeClassName="active"
														exact
														to="/myaccount/dishItems"
													>
														<i className="icofont-heart"></i>{" "}
														Dish Items
													</NavLink>
												</li>
											</>
										)}
										{(this.user.is_sub_user == false ||
											this.user.can_manage_menu == true) && (
											<li className="nav-item">
												<NavLink
													className="nav-link"
													activeClassName="active"
													exact
													to="/myaccount/menuday"
												>
													<i className="icofont-heart"></i>{" "}
													Menu
												</NavLink>
											</li>
										)}

										{this.user.is_sub_user == false && (
											<>
												<li className="nav-item">
													<NavLink
														className="nav-link"
														activeClassName="active"
														exact
														to="/myaccount/coupons"
													>
														<i className="icofont-heart"></i>{" "}
														Coupon
													</NavLink>
												</li>
												<li className="nav-item">
													<NavLink
														className="nav-link"
														activeClassName="active"
														exact
														to="/myaccount/offers"
													>
														<i className="icofont-heart"></i>{" "}
														Offers
													</NavLink>
												</li>
												<li className="nav-item">
													<NavLink
														className="nav-link"
														activeClassName="active"
														exact
														to="/myaccount/extrapayments"
													>
														<i className="icofont-heart"></i>{" "}
														Extra Payments
													</NavLink>
												</li>
											</>
										)}
										{this.state.selectedRestaurant &&
											this.state.selectedRestaurant
												.is_sub_restaurant === false && (
												<li className="nav-item">
													<NavLink
														className="nav-link"
														activeClassName="active"
														exact
														to="/myaccount/customerNotifications"
													>
														<i className="icofont-food-cart"></i>{" "}
														Customer Notifications
													</NavLink>
												</li>
											)}
										<li className="nav-item">
											<NavLink
												className="nav-link"
												activeClassName="active"
												exact
												to="/myaccount/deliveryOrders"
											>
												<i className="icofont-food-cart"></i>{" "}
												Delivey Requests
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink
												className="nav-link"
												activeClassName="active"
												exact
												to="/myaccount/reports"
											>
												<i className="icofont-credit-card"></i>{" "}
												Order Reports
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink
												className="nav-link"
												activeClassName="active"
												exact
												to="/myaccount/ondemand_delivery_reports"
											>
												<i className="icofont-credit-card"></i>{" "}
												OnDemand Delivery{" "}
											</NavLink>
										</li>
										<li className="nav-item">
											<NavLink
												className="nav-link"
												activeClassName="active"
												exact
												to="/myaccount/summary_reports"
											>
												<i className="icofont-credit-card"></i>{" "}
												Summary Reports
											</NavLink>
										</li>
										{this.user.is_sub_user == false && (
											<li className="nav-item">
												<NavLink
													className="nav-link"
													activeClassName="active"
													exact
													to="/myaccount/franchaises"
												>
													<i className="icofont-food-cart"></i>{" "}
													Franchises{" "}
												</NavLink>
											</li>
										)}
										{this.user.is_sub_user == false && (
											<li className="nav-item">
												<NavLink
													className="nav-link"
													activeClassName="active"
													exact
													to="/myaccount/users"
												>
													<i className="icofont-food-cart"></i>{" "}
													Users
												</NavLink>
											</li>
										)}
										<li className="nav-item">
											<NavLink
												className="nav-link"
												activeClassName="active"
												exact
												to="/myaccount/customers"
											>
												<i className="icofont-food-cart"></i>{" "}
												Customers
											</NavLink>
										</li>
										{this.user.is_sub_user == false && (
											<li className="nav-item">
												<NavLink
													className="nav-link"
													activeClassName="active"
													exact
													to="/myaccount/stripesettings"
												>
													<i className="icofont-food-cart"></i>{" "}
													Stripe Settings
												</NavLink>
											</li>
										)}
									</ul>
								</div>
							</Col>
							<Col md={9}>
								<Switch>
									<Route
										path="/myaccount/dashboard"
										exact
										component={Dashboard}
									/>
									<Route
										path="/myaccount/franchaises"
										exact
										component={Franchaise}
									/>
									<Route
										path="/myaccount/newFranchaise"
										exact
										component={NewFranchaise}
									/>

									<Route
										path="/myaccount/users"
										exact
										component={Users}
									/>

									<Route
										path="/myaccount/orders"
										exact
										component={Orders}
									/>
									<Route
										path="/myaccount/failed_orders"
										exact
										component={FailedOrders}
									/>
									<Route
										path="/myaccount/deliveryOrders"
										exact
										component={DeliveryOrders}
									/>
									<Route
										path="/myaccount/customerNotifications"
										exact
										component={CustomerNotifications}
									/>
									{/* 7start */}
									<Route
										path="/myaccount/dishCustomization"
										exact
										component={DishCustomization}
									/>
									{/* 7end */}
									<Route
										path="/myaccount/dishItems"
										exact
										component={DishItems}
									/>
									<Route
										path="/myaccount/categorymenu"
										exact
										component={Categorymenu}
									/>

									<Route
										path="/myaccount/menuday"
										exact
										component={MenuDay}
									/>
									<Route
										path="/myaccount/editprofile"
										exact
										component={ProfileEdit}
									/>
									<Route
										path="/myaccount/adddishitem"
										exact
										component={AddFoodItem}
									/>
									<Route
										path="/myaccount/coupons"
										exact
										component={Coupons}
									/>
									<Route
										path="/myaccount/offers"
										exact
										component={Offers}
									/>
									<Route
										path="/myaccount/customers"
										exact
										component={Customers}
									/>
									<Route
										path="/myaccount/extrapayments"
										exact
										component={ExtraPayments}
									/>

									<Route
										path="/myaccount/addcoupon"
										exact
										component={AddCoupon}
									/>
									<Route
										path="/myaccount/notifications"
										exact
										render={(props) => {
											return (
												<Notifications
													notifications={
														this.state
															.currentNotifications
													}
													{...props}
												/>
											);
										}}
									/>

									<Route
										path="/myaccount/editdishitem"
										render={(props) => {
											let foodObj = {};
											if (
												!this.props.history.location.state
											) {
												foodObj =
													this.getDataOnSessionStorage(
														"editingFoodObj"
													);
												console.log(foodObj);
											} else {
												foodObj =
													this.props.history.location
														.state.foodObj;

												this.storeDataToSessionStorage(
													"editingFoodObj",
													foodObj
												);
											}
											return (
												<EditFoodItem
													{...props}
													foodObj={foodObj}
												/>
											);
										}}
									/>
									<Route
										path="/myaccount/reports"
										exact
										component={Reports}
									/>
									<Route
										path="/myaccount/ondemand_delivery_reports"
										exact
										component={DeliveryOnlyReports}
									/>

									<Route
										path="/myaccount/summary_reports"
										exact
										component={SummaryReports}
									/>
									<Route
										path="/myaccount/stripesettings"
										exact
										component={Stripeboard}
									/>
								</Switch>
							</Col>
						</Row>
					</Container>
				</section>
			</>
		);
	}
}

export default MyAccount;
