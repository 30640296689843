import React from "react";
import {
	Row,
	Col,
	Container,
	InputGroup,
	FormGroup,
	Form,
	Button,
} from "react-bootstrap";
import DataService from "../../services/DataService";
import { alertCreation, alertError } from "../Alerts";

class CustomerNotifications extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.customerNotificationHandler = new DataService(
			"create_customer_notifications"
		);
		this.selectedRestaurant =
			this.customerNotificationHandler.getSelectedRestaurant();
		this.state = {
			notificationMessage: "",
			notificationTitle: "",
		};
	}

	sendNotification = () => {
		if (this.state.notificationMessage) {
			let inputParams = {
				body: this.state.notificationMessage,
				title: this.state.notificationTitle,
			};
			if (this.selectedRestaurant != 0) {
				inputParams = {};
			}
			this.customerNotificationHandler
				.createData(inputParams)
				.then((response) => {
					if (response.data.status == "fail") {
						alertError("Error on push notification");
					} else {
						alertCreation("Push Notification");
					}
				});
		}
	};

	render() {
		return (
			<>
				{(this.selectedRestaurant == 0 ||
					(this.selectedRestaurant &&
						this.selectedRestaurant.is_sub_restaurant === false)) && (
					<section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
						<Container>
							<Row>
								<Col md={12}>
									<div className="offer-dedicated-body-left">
										<div className="pt-2"></div>
										<div className="bg-white rounded shadow-sm p-4 mb-4">
											<h4 className="mb-2 pl-3">
												Send Customer Push Notification
											</h4>

											<Form.Group className="col-md-12">
												{/* <Form.Label>Message</Form.Label> */}
												{/* {this.validator.message(
											"restaurant_name",
											this.state.createForm[
												"restaurant_name"
											],
											"required",
											{
												className: "text-danger",
											}
										)} */}

												<InputGroup>
													<Form.Control
														type="text"
														onChange={(event) =>
															this.setState({
																notificationTitle:
																	event.target
																		.value,
															})
														}
														// value={
														// 	this.state.createForm[
														// 		"restaurant_name"
														// 	]
														// }
														name="notification_title"
														placeholder="Enter Title"
													/>
													<Form.Control
														type="text"
														onChange={(event) =>
															this.setState({
																notificationMessage:
																	event.target
																		.value,
															})
														}
														// value={
														// 	this.state.createForm[
														// 		"restaurant_name"
														// 	]
														// }
														name="notification_message"
														placeholder="Enter Message"
													/>

													<Button
														onClick={() =>
															this.sendNotification()
														}
													>
														send
													</Button>
												</InputGroup>
											</Form.Group>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				)}
			</>
		);
	}
}

export default CustomerNotifications;
