import React from 'react';
import { Row, Col } from 'react-bootstrap';
import UserCard from '../../common/UserCard';
import DeleteAddressModal from '../../modals/DeleteAddressModal';
import Icofont from 'react-icofont';
import { NavLink, Link } from 'react-router-dom';
import { NavDropdown, Nav } from 'react-bootstrap';
import DropDownTitle from '../../common/DropDownTitle';
import DataService from '../../../services/DataService';
import { confirmDeleteAction, alertError, alertWarning, alertCreation, alertDeletion, alertUpdation } from '../../../components/Alerts';
import SimpleReactValidator from 'simple-react-validator';
import { data } from 'jquery';
import UserModel from '../../modals/UserModel';

class Users extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.dataHandler = new DataService("restaurants");
		this.validator = new SimpleReactValidator({ autoForceUpdate: this });

		this.state = {
			title: "New User",
			itemsDisplayed: [],
			subRestaurants: [],
			createForm: {
			},
			selectedItem: {},
			validator: this.validator,
			addMethod: true,
			showDeleteModal: false,
			showAddUser: false,
		};
		this.reloadRestaurantsData()
		this.reloadData();
		this.createFormHandler = this.createFormHandler.bind(this);
		this.showUpdateUserModel = this.showUpdateUserModel.bind(this);
		this.addUser = this.addUser.bind(this);
		this.deleteConfirm = this.deleteConfirm.bind(this);
		this.updateUser = this.updateUser.bind(this);
		this.onSelectRestaurants = this.onSelectRestaurants.bind(this)
	}
	deleteConfirm = (id, lengthItems) => {


		confirmDeleteAction('User').then((userResponse) => {
			if (userResponse.isConfirmed == true) {
				this.deleteUser(id);
			}
		});


	};

	deleteUser = (id) => {
		let inputParams = {};
		inputParams['id'] = id;
		this.dataHandler.deleteSubuser(inputParams)
			.then((response) => {

				if (response.data['status'] === 'sucess') {
					alertDeletion('User');
					this.reloadData();
				} else {
					alertError(response.data['message']);
				}
			})
			.catch((err) => alert(err));
	};

	createFormHandler = (event) => {
		let key = '';
		let value = '';

		let inputForm = { ...this.state.createForm };
		if ((event.target.type == 'text') | (event.target.type == 'number') || (event.target.type == 'password')) {
			key = event.target.name;
			value = event.target.value;
		} else if (event.target.type == 'date') {
			key = event.target.name;
			value = event.target.value;

		}
		else if (event.target.type == 'select') {
			key = event.target.name;
			value = event.target.value;

		}
		else if (event.target.type == 'checkbox') {
			key = event.target.name;
			value = event.target.checked;
		}


		inputForm[key] = value;
		this.setState({ createForm: inputForm });


	};


	updateUser = (e) => {
		alert('ok')

		let inputParams = this.state.createForm;
		inputParams['id'] = this.state.selectedItem.id;

		this.dataHandler.updateSubuser(inputParams)
			.then((response) => {
				if (response.data['status'] === 'Sucess') {
					alertUpdation('User');
					this.setState({ createForm: {} })
					this.hideUserNew();

					this.reloadData();
				} else {
					alertError(response.data['message']);
				}
			})
			.catch((err) => alert(err));



	};



	addUser = (e) => {
		if ((this.validator.allValid()) == true) {
			let inputParams = this.state.createForm;
			let formData = new FormData(); //formdata object
			if (inputParams['assigned_restaurants'] && typeof (inputParams['assigned_restaurants']) != 'string') {
				inputParams['assigned_restaurants'] = JSON.stringify(inputParams['assigned_restaurants']);
			}
			Object.keys(inputParams).forEach(function (key) {
				formData.append(key, inputParams[key]); //append the values with key, value pair
			});

			this.dataHandler.createSubUser(formData)
				.then((response) => {
					if (response.data['status'] === 'Sucess') {
						alertCreation('User');
						this.hideUserNew();
						this.reloadData();

					} else {
						alertError(response.data['message']);
					}
				})
				.catch((err) => alert(err));


		}
		else {
			this.validator.showMessages();

		}
	};










	comparePriority(a, b) {
		if (a.priority < b.priority) {
			return -1;
		}
		if (a.priority > b.priority) {
			return 1;
		}
		return 0;
	}

	showUpdateUserModel = (userObj) => {
		let assigned_restaurents = []
		if (userObj.assigned_restaurents) {
			assigned_restaurents = userObj.assigned_restaurents.map((obj) => {
				return obj.id;
			});
		}
		let inputParams = {
			'email': userObj.email,
			'can_manage_menu': userObj.can_manage_menu,
			'can_manage_food': userObj.can_manage_food,

			'assigned_restaurants': assigned_restaurents

		};
		this.setState({ showAddUser: true }, () => {

			this.setState({
				selectedItem: userObj,
				addMethod: false,
				createForm: inputParams
			});
		});

	}



	/*
	fetch elements from server and reload data elements
	*/
	reloadData = () => {
		let inputParams = {};
		this.dataHandler.getSubUsers(inputParams).then((response) => {
			let currentData = response.data.data;
			this.setState({ itemsDisplayed: currentData, addMethod: true });
		});
	};
	onRemoveRestaurants = (selectedList, selectedItem) => {
		let inputForm = { ...this.state.createForm };

		let selectedRestaurants = selectedList.map((obj) => obj.id);
		inputForm["assigned_restaurants"] = selectedRestaurants;
		this.setState({ createForm: inputForm });
	};
	onSelectRestaurants = (selectedList, selectedItem) => {
		let inputForm = { ...this.state.createForm };
		let selectedRestaurants = selectedList.map((obj) => {
			return obj.id;
		});
		inputForm["assigned_restaurants"] = selectedRestaurants;
		this.setState({ createForm: inputForm });
	};




	reloadRestaurantsData = () => {
		let inputParams = {};
		this.dataHandler.getSubRestaurants(inputParams).then((response) => {
			let currentData = response.data.data;

			let abstractData = currentData.map((obj) => ({
				title: obj["title"],
				id: obj.id,
			}));
			this.setState({ subRestaurants: abstractData });
		});
	};


	hideUserNew = () => this.setState({
		title: "New User",
		createForm: {

		},
		selectedItem: {},
		validator: this.validator,
		addMethod: true,
		showDeleteModal: false,
		showAddUser: false,
	});
	hideDeleteModal = () => this.setState({ showDeleteModal: false });
	render() {
		return (
			<>
				<UserModel show={this.state.showAddUser}
					createFormHandler={this.createFormHandler}
					createForm={this.state.createForm}
					selectedItem={this.state.selectedItem}
					addUser={this.addUser}
					updateUser={this.updateUser}
					addMethod={this.state.addMethod}
					onSelect={this.onSelectRestaurants}
					subRestaurants={this.state.subRestaurants}
					title={this.state.title}
					validator={this.state.validator}
					onHide={this.hideUserNew} />
				<DeleteAddressModal show={this.state.showDeleteModal} onHide={this.hideDeleteModal} />
				<div className='p-4 bg-white shadow-sm'>
					<Row>
						<Col md={12}>
							<Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
								<Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="">
									<h4 className="font-weight-bold mt-0 mb-4">  User List </h4><span className="sr-only">(current)</span>
								</Nav.Link>

								<Link to='#' onClick={() => this.setState({ showAddUser: true })} className="text-primary mr-3 mt-3"><i className="icofont-plus-circle"></i> NEW User</Link>
							</Nav>
						</Col>

						{this.state.itemsDisplayed && this.state.itemsDisplayed.map((dataObj, idx) =>
							<Col md={6}>

								<UserCard
									email={dataObj.email}
									userObj={dataObj}
									onHide={this.hideUserNew}
									showUpdateUserModel={this.showUpdateUserModel}
									imageclassName='mr-3'
									onClick={() => {
										this.deleteConfirm(dataObj.id);
									}}
								/>
							</Col>
						)}

					</Row>
				</div>
			</>
		);
	}
}
export default Users;