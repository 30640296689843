import React from 'react';
import {Form,Modal,Button} from 'react-bootstrap';
import { Multiselect } from "multiselect-react-dropdown";

class UserModel extends React.Component {
	constructor(props, context) {
		super(props, context);

this.validator=props.validator;
	}
	render() {
    	return (
	        <Modal
	        	show={this.props.show}
	        	onHide={this.props.onHide}
		        centered
		   	  >
			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h5' id="edit-profile">{this.props.email}</Modal.Title>
			  </Modal.Header>

			  <Modal.Body>
			    <Form>
               <div className="form-row">
			   <Form.Group className="col-md-6">
                              <Form.Label>Email</Form.Label>
                              
							  	  {this.validator.message('email', this.props.createForm['email'], 'required',{ className: 'text-danger' }) }

                                <Form.Control
                                  type="text"
								  defaultValue={this.props.createForm['email']}
                                  onChange={this.props.createFormHandler}
                                  name="email"
                                  placeholder="email"
                                />
                             
                            </Form.Group>

                  <Form.Group className="col-md-4">
                     <Form.Label>Password</Form.Label>
					 {(this.props.addMethod==true)&&(this.validator.message('password', this.props.createForm['password'], 'required',{ className: 'text-danger' })) }

                     <Form.Control type="password"  
					 onChange={this.props.createFormHandler} 
					 name="password" 
					 defaultValue={this.props.createForm['password']} placeholder="Enter Password" />
                  </Form.Group>
				 

               </div>

			
			   <div className="form-row">
			   <Form.Group className="col-md-4">
<Form.Check
			  type="checkbox"
			  name="can_manage_menu"
			  checked={
				this.props.createForm["can_manage_menu"]
			  }
			  onClick={this.props.createFormHandler}
			  label="Can Manage Menu"
			/> </Form.Group>

<Form.Group className="col-md-4">
<Form.Check
			  type="checkbox"
			  name="can_manage_food"
			  checked={
				this.props.createForm["can_manage_food"]
			  }
			  onClick={this.props.createFormHandler}
			  label="Can Manage Food"
			/> </Form.Group>
				 

               </div>

			   <div className="form-row">
			   <Form.Group className="col-md-6">
                              <Form.Label>Assigned Restaurants</Form.Label>
                              

							  <Multiselect
                              options={this.props.subRestaurants} // Options to display in the dropdown
                              selectedValues={
								
								
								this.props.subRestaurants&&this.props.subRestaurants.filter(
                                (restObj) => {
									
                                  
                                    if (this.props.createForm["assigned_restaurants"]&&(this.props.createForm["assigned_restaurants"].includes(
										restObj.id)))
										{
											return true;
										}else{
                                  
                                  return false;
										}
                                }
                              )} // Preselected value to persist in dropdown
                              onSelect={this.props.onSelect} // Function will trigger on select event
                              onRemove={this.props.onSelect} // Function will trigger on remove event
                              displayValue="title" // Property name to display in the dropdown options
                            />
                             
                            </Form.Group>

				 

               </div>

          </Form>
			  </Modal.Body>

			  <Modal.Footer>
			  <div className="form-row">
				
			  <Button type='button' onClick={this.props.onHide} variant="outline-primary" className="d-flex w-50 text-left justify-content-left">CANCEL</Button>
			  <Button type='button'  onClick={
					(e)=>{
						if(this.props.addMethod==true){
					this.props.addUser(e)
						}
						else{
							this.props.updateUser(e)

						}
					}
					
					} variant="primary" className='d-flex w-50 text-left justify-content-center'>ADD</Button>


</div>

			    
			  </Modal.Footer>
			</Modal>
    	);
    }
}
export default UserModel;