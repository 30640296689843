import React from 'react';
import {Form,Modal,Button} from 'react-bootstrap';

class CategoryModal extends React.Component {
	constructor(props, context) {
		super(props, context);

this.validator=props.validator;
	}
	render() {
    	return (
	        <Modal
	        	show={this.props.show}
	        	onHide={this.props.onHide}
		        size="sm"
		        centered
		   	  >
			  <Modal.Header closeButton={true}>
			    <Modal.Title as='h5' id="edit-profile">New Category</Modal.Title>
			  </Modal.Header>

			  <Modal.Body>
			    <Form>
               <div className="form-row">
                  <Form.Group className="col-md-12">
                     <Form.Label>Category Name</Form.Label>
					 { this.validator.message('title', this.props.createForm['title'], 'required',{ className: 'text-danger' }) }

                     <Form.Control type="text"  onChange={this.props.createFormHandler} name="title" defaultValue={this.props.createForm['title']} placeholder="Enter Catogery Name" />
                  </Form.Group>
				 

               </div>
			   <div className="form-row">
                  <Form.Group className="col-md-4">
                     <Form.Label>Priority</Form.Label>

                     <Form.Control type="number"  onChange={this.props.createFormHandler} name="priority" defaultValue={this.props.createForm['priority']} placeholder="Enter Priority" />
                  </Form.Group>
				 

               </div>
          </Form>
			  </Modal.Body>

			  <Modal.Footer>
			    <Button type='button' onClick={this.props.onHide} variant="outline-primary" className="d-flex w-50 text-center justify-content-center">CANCEL</Button>
			    <Button type='button'  onClick={
					(e)=>{
						if(this.props.addMethod==true){
					this.props.addFoodCategory(e)
						}
						else{
							this.props.updateFoodCategory(e)

						}
					}
					
					} variant="primary" className='d-flex w-50 text-center justify-content-center'>ADD</Button>
			  </Modal.Footer>
			</Modal>
    	);
    }
}
export default CategoryModal;