import React from 'react';
import {Image,Badge,Button,Media} from 'react-bootstrap';
import PropTypes from 'prop-types'; 
import Icofont from 'react-icofont';
import { DragSwitch } from 'react-dragswitch'
import 'react-dragswitch/dist/index.css'

class MenuDayItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked:this.props.checked,
      favValue:1,
	  favStatus:true,
      quantity: this.props.qty || 0,
      show: this.props.show || true,
      max:this.props.maxValue || 5,
      min:this.props.minValue || 0
    };
  }

 
  handleChange = () => {
		this.setState({ favValue: 2 });
	  };
	
	  handleFavItem = () => {
		this.setState(previousState => {
		  return { favStatus: !previousState.favStatus };
		});
	  };
  IncrementItem = () => {
    if(this.state.quantity >= this.state.max) {

    }else {
        this.setState({
            quantity: this.state.quantity + 1 
        });
      this.props.getValue({id:this.props.id,quantity: (this.state.quantity + 1 )});
    }
  }
  DecreaseItem = () => {
    if(this.state.quantity <= (this.state.min)) {

    }else {
      this.setState({ quantity: this.state.quantity - 1 });
      this.props.getValue({id:this.props.id,quantity: (this.state.quantity - 1 )});
    }
  }

  render() {
      return (
      	<div className={"p-3 border-bottom "+this.props.itemClass}>
		  
	            <span className="float-right"> 
                <span className="ml-1 mr-1">Mark as Enabled</span>
                <DragSwitch
                    className="ml-2"
                    checked={this.state.checked}
                    onChange={c => {
                    let del_status=(c)?0:1;
                    this.props.updateMenu(this.props.id,del_status)

                    this.setState({ checked: c })
                    }}
                />
	            </span>
           
	           
		   <Media>
		      {this.props.image?
		      	<Image className={"mr-3 rounded-pill " +this.props.imageClass} src={this.props.image} alt={this.props.imageAlt} />
		      	:
		      	<div className="mr-3"><Icofont icon="ui-press" className={"text-"+this.props.badgeVariant+" food-item"} /></div>
		      }
		      <Media.Body>
		         <h6 className="mb-1">{this.props.title} {this.props.showBadge?<Badge variant={this.props.badgeVariant}>{this.props.badgeText}</Badge>:""}</h6>
		         <p className="text-gray mb-0">{this.props.priceUnit}{this.props.price} |
            
             </p>
		      </Media.Body>
		   </Media>
		</div>
    );
  }
}


MenuDayItem.propTypes = {
  itemClass:PropTypes.string,
  title: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  image: PropTypes.string,
  imageClass: PropTypes.string,
  showBadge: PropTypes.bool,
  badgeVariant: PropTypes.string,
  badgeText: PropTypes.string,
  price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  qty: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  getValue: PropTypes.func.isRequired
};
MenuDayItem.defaultProps = {
  itemClass:'gold-members',
  imageAlt:'',
  imageClass:'',
  showBadge: false,
  price: '',
  priceUnit:'$',
  showPromoted: false,
  badgeVariant: 'danger'
}

export default MenuDayItem;