import React from 'react';
import PropTypes from 'prop-types'; 
import {Link } from 'react-router-dom';
import {Image,Media,Badge,span, Button} from 'react-bootstrap';
import Icofont from 'react-icofont';
import {alertCreation,alertError} from '../Alerts'
import Moment from 'moment';
import DataService from "../../services/DataService";
const orderMode=['Delivery Order','Takeout Order']
const orderSpans=["primary","warning"]
class DeliveryOrderCard extends React.Component {
	constructor(props, context) {
	    super(props, context);
      console.log(this.props.orderObj);


	this.dataHandler=new DataService("restaurants");
  this.cartHandler=new DataService('carts')
  this.deliveryStyles=[<span className="btn-default mr-1 ml-4">Waiting</span>,
  <span className="btn-primary mr-1 ml-4">Accepted </span>,
  <span className="btn-success mr-1 ml-4">Completed</span>,
  <span className="btn-info mr-1 ml-2">Requested For Delivery </span>,
  <span className="btn-danger mr-1 ml-4">Canceled</span>];
		
	}
	postRefreshOnline = (orderObj) => {

        let inputParams = {
  "id":orderObj.id
}
this.cartHandler.refreshOnlinePayment(inputParams).then((res)=>{
if(res.data.status=='Sucess')
{
  alertCreation("PayLink ");
}
else
{
  alertError("Customer mobile number is invalid,Please Tray again");
}
})
return		JSON.stringify(inputParams);

       
    };

	postDeliveryRequest = (orderObj) => {
		console.log(orderObj);
		let profileObj=this.dataHandler.getProfile();
		console.log(profileObj);
        let inputParams = {
  "order_id":orderObj.order_id,
  "team_id": 990949,
  "auto_assignment": "0",
  "job_description": "Pick up an item from "+profileObj.restaurant_name+" and deliver to "+orderObj.customer.first_name,
  "job_pickup_phone": profileObj.phone,
  "job_pickup_name": profileObj.restaurant_name,
  "job_pickup_email": profileObj.order_notification_email,
  "job_pickup_address": profileObj.address,
  "job_pickup_latitude": profileObj.lat,
  "job_pickup_longitude": profileObj.lng,
  "job_pickup_datetime": this.props.pickUpDate,
  "customer_email": this.props.orderObj.customer.email,
  "customer_username": this.props.orderObj.customer.first_name,
  "customer_phone": this.props.orderObj.customer.phone,
  "customer_address": this.props.address,
  "latitude": this.props.orderObj.customer['lat'],
  "longitude": this.props.orderObj.customer['lng'],
  "job_delivery_datetime": this.props.pickUpDate,
  "has_pickup": "1",
  "has_delivery": "1",
  "layout_type": "0",
  "tracking_link": 1,
  "timezone": "-330",
  "custom_field_template": "Template_1",
  "meta_data": orderObj.added_items,
  "pickup_custom_field_template": "Template_2",
  "pickup_meta_data": this.props.orderObj.added_items,
  "fleet_id": "",
  "p_ref_images": [
    "http://tookanapp.com/wp-content/uploads/2015/11/logo_dark.png",
    "http://tookanapp.com/wp-content/uploads/2015/11/logo_dark.png"
  ],
  "ref_images": [
    "http://tookanapp.com/wp-content/uploads/2015/11/logo_dark.png",
    "http://tookanapp.com/wp-content/uploads/2015/11/logo_dark.png"
  ],
  "notify": 1,
  "tags": "",
  "geofence": 0,
  "ride_type": 0
}
return		JSON.stringify(inputParams);
		/*
		let taskUrl='/create_task';
		createData(inputParams,taskUrl)
            .then((response) => {
				console.log(response);
                if (response.data.status === 200) {
                    alertCreation('Delevery');
					

                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));
*/
       
    };




	render() {
    	return (
	      <div className="bg-white card mb-4 order-list shadow-sm ">
	          <div className="gold-members p-4 ">
              <h6>{this.props.orderTitle}</h6>
	                <Media>
	                   <Image className="mr-4" src={this.props.image} alt={this.props.imageAlt} />
	                   <Media.Body>
	                   		                          {this.deliveryStyles[this.props.orderObj.delivery_status]}
                                                    {(this.props.info.startsWith('in'))&&
                                                    <h6>
                                                    Future Order <Badge variant="info">{this.props.info}</Badge>
                                                  </h6>
                                                   
                                                  }
                                                  {(this.props.info.startsWith('a few'))&&
                                                    <h4>
                                                    Now <Badge variant="info">{this.props.info}</Badge>
                                                  </h4>
                                                   
                                                  }
                                                   {(!this.props.info.startsWith('few'))&&(!this.props.info.startsWith('in'))&&
                                                  
                                                    <Badge variant="info">{this.props.info}</Badge>
                                                  
                                                   
                                                  }
	                   			                    {' '}{this.props.deliveredDate}
	                      <p className="text-gray mb-1">
	                      	<Icofont icon="location-arrow" /> {this.props.address} 
	                      </p>
	                      <p className="text-gray mb-3">
	                      	<Icofont icon="list" /> ORDER ID:#{this.props.orderNumber}
				
							  
	                      	<Icofont icon="clock-time" className="ml-2" /> {this.props.orderDate} 
	                      </p>
	                      <p className="text-gray mb-3">
	                      	<Icofont icon="list" /> ORDER ID:#{this.props.cu}
				
							  
	                      	<Icofont icon="clock-time" className="ml-2" /> {this.props.orderDate} 
	                      </p>
                        <p>
                        Payment Status:
                        <Badge pill variant={(this.props.orderObj.payment_status===true)?"success":"primary"}>
                        {(this.props.orderObj.payment_status===true)?"PAID":"PENDING"}
                        </Badge>
                        </p>
                        <p className="mb-0 text-info"><small>Pay via {this.props.orderObj.payment_mode}
	                               {/*  <span className="float-right text-danger">$620 OFF</span>*/}</small>
	                              </p>
                        <p>
                          <br/>
                          {(this.props.orderObj.payment_status===false)&&
                          <Button className={"primary"}
                          onClick={()=>{

                            this.postRefreshOnline(this.props.orderObj)
                          }}
                          >Generate Pay Link</Button>}

                          </p>
	                      <Badge className={"primary"}>Delivery Status : {this.props.orderObj.delivery_status}</Badge>
	                      <hr />
	                      <div className="float-right">
                          {(this.props.orderObj.delivery_status==0)&&
		                      <Link className="btn btn-sm btn-outline-primary mr-1"   onClick={(e)=>{this.props.updateOrder(e,this.props.orderObj.id, {delivery_status:1});}} to={this.props.helpLink}><Icofont icon="check" /> ACCEPT </Link>}
		                      {(this.props.orderObj.delivery_status==0)&&
                           <Link className="btn btn-sm btn-danger mr-1"
							  
								onClick={(e)=>{this.props.updateOrder(e,this.props.orderObj.id, {delivery_status:4});}}
                to={this.props.helpLink}
							 ><Icofont icon="trash" /> CANCEL</Link>
                          }
							      {(this.props.orderObj.delivery_status==1)&& (this.props.orderObj.order_mode==0)&& <Link className="btn btn-sm btn-outline-primary mr-1 pl-1 pr-1"   onClick={(e)=>{
                      //this.postDeliveryRequest(this.props.orderObj);

                  this.props.updateOrder(e,this.props.orderObj.id, {delivery_status:3,delivery_params:this.postDeliveryRequest(this.props.orderObj)});
                    
                    }} to={this.props.helpLink}><Icofont icon="headphone-alt" /> REQUEST FOR DELIVERY</Link>}
                    {(this.props.orderObj.delivery_status==1)&& <Link className="btn btn-sm btn-outline-primary mr-1 pl-1 pr-1"   onClick={(e)=>{this.props.updateOrder(e,this.props.orderObj.id, {delivery_status:2});}} to={this.props.helpLink}><Icofont icon="headphone-alt" />COMPLETE ORDER </Link>}
         
	                      </div>
	                      
	                   </Media.Body>
	                </Media>
	          </div>
			  <div className="gold-members p-1" style={{background:this.props.backColor}}></div>
	       </div>
    	);
    }
}

DeliveryOrderCard.propTypes = {
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  orderNumber: PropTypes.string.isRequired,
  orderDate: PropTypes.string.isRequired,
  backColor: PropTypes.string.isRequired,
  deliveredDate: PropTypes.string,
  orderTitle: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
};
export default DeliveryOrderCard;