import React from 'react';
import {Link} from 'react-router-dom';
import {Row,Col,Container,Badge} from 'react-bootstrap';
import Icofont from 'react-icofont';
import CartDropdownItem from './cart/CartDropdownItem';
import {toFullDate} from "../services/DataFormatHandler"
import Map from '../components/location/map';
const orderMode=['Delivery Order','Takeout Order']
const orderSpans=["primary","warning"]
class TrackOrder extends React.Component {
	constructor(props, context) {
	    super(props, context);
		this.state={
			orderObject:this.props.history.location.state.orderObject,
			random:0,
		}
		console.log(this.state.orderObject);
	}



	getCartSubTotal=(cartObj)=>
	{

		return cartObj.reduce((total, item) => total += (item.price*item.quantity),0); 
	}
	getDiscountTotal=(cartObj)=>
	{
	return cartObj.reduce((total, item) => total += (item.discount*item.quantity),0); 
	}
	valueCoupen=(coupen,total)=>{
		var coupenDiscount=Number(coupen.coupen_discount);
		if(coupen.enable_percentage==true)
		{
		  coupenDiscount=  Number((total * coupenDiscount) / 100).toFixed(2);
		}
		return coupenDiscount;
	  }
	getDeliveryTotal=(cartObj)=>
	{
let TotalAmount=this.getCartSubTotal(cartObj)-this.getDiscountTotal(cartObj);

let deliveryCharge=((TotalAmount==0)||(TotalAmount>this.state.paymentTerms['deliveryFree']))?0:this.state.paymentTerms['deliveryCharge'];
		return deliveryCharge; 
	}
	getCartTotal=(cartObj)=>
	{ 
	let cartTotal=(this.getCartSubTotal(cartObj)+this.getDeliveryTotal(cartObj))-this.getDiscountTotal(cartObj);
	console.log(cartTotal);
	return cartTotal; 
	}

	render() {
		let YOUR_LAT='9.981636';
		let YOUR_LON='76.29988';
		
		let g_map_path="";
	
		console.log(g_map_path);
    	return (
			<div>
    		<section className="section bg-white osahan-track-order-page position-relative">
			

	         <Container className="pt-5 pb-5">

	            <Row className="d-flex">
	          


	               <Col md={12}>
	                  <div className="bg-white p-4 shadow-lg mb-2">
	                    <div className="mb-2"><small>Order Id :{this.state.orderObject.order_id} <Link className="float-right font-weight-bold" to="myaccount/orders"><Icofont icon="home"/> Home</Link></small></div>
	                    
	                     <p className="text-gray mb-0"><Icofont icon="clock-time"/> {toFullDate(this.state.orderObject.published_date)} | {this.state.orderObject.added_items.length} Items | {this.state.orderObject.total_amount}$</p>
			 

   

	                  </div>
	                  <div className="bg-white p-4 shadow-lg">
	                     <div className="osahan-track-order-detail po">
	                        <h5 className="mt-0 mb-3">Order Details</h5>
										 {(typeof(this.state.orderObject.order_mode)!='undefined')&&
                          <Badge pill variant={orderSpans[this.state.orderObject.order_mode]}>
    {orderMode[this.state.orderObject.order_mode]}
  </Badge>}
	                        <Row>
	                           <Col md={5}>
	                             <small>To</small>
	                              <h6 className="mb-1 mt-1"><Link to="/detail" className="text-black"><Icofont icon="food-cart"/> {this.state.orderObject.customer.first_name}
								  {this.state.orderObject.customer.last_name}
	                                 </Link>
								</h6>
	                              <p className="text-gray mb-5">
								  <span className="text-black"><Icofont icon="map-pins"/> 
									  {this.state.orderObject.customer.address}</span>
								  
								  </p>
	                              <small>Payment Mode</small>
	                              <h6 className="mb-1 mt-1">
								  {' '}<b>  {this.state.orderObject.customer.payment_mode}</b>{' '}
	                            
	                              </h6>
	                              <p className="text-gray mb-0">  <Badge pill variant={(this.state.orderObject.payment_status===true)?"success":"primary"}>
  {(this.state.orderObject.payment_status===true)?"PAID":"PENDING"}
  </Badge>
	                              </p>
	                           </Col>
	                           <Col md={7}>
	                                <div className="mb-2"><small><Icofont icon="list"/> {this.state.orderObject.added_items.length}  ITEMS</small></div>
									{this.state.orderObject.added_items.map((itemObj)=>{

				                    return <CartDropdownItem 
				                     	icoIcon='ui-press'
										item={itemObj}
				                     	iconClass='text-primary food-item'
				                     	title={itemObj.item+" X "+itemObj.quantity}
				                     	price={itemObj.price}
				                    />
									})}
				                   
				                  <hr />
								  <p className="mb-0 font-weight-bold text-black">Delivery Charge <span className="float-right text-secondary"> {this.state.orderObject.total_delivery}</span></p>
	                              <p className="mb-0 font-weight-bold text-black">Total Discount  <span className="float-right text-secondary"> {this.state.orderObject.total_discount}</span></p>
								  <p className="mb-0 font-weight-bold text-black">
									  Total deduction from Applied Coupens  
									  {this.state.orderObject.applied_coupens.map((c_obj)=>(
										 <span><Badge variant="primary"> {c_obj.title}</Badge>{" "}</span>
										 
										
									  ))}
									  <span className="float-right text-secondary"> {this.state.orderObject.total_coupens_applied}</span></p>
									  
									  {this.state.orderObject.extra_payments.map((e_obj)=>(
					
										 <p className="mb-0 font-weight-bold text-black">{e_obj.title}  <span className="float-right text-secondary"> {e_obj.value}</span></p>
										
									  ))}
									  
	                              <p className="mb-0 font-weight-bold text-black">TIP  <span className="float-right text-secondary"> {this.state.orderObject.tip}</span></p>
	                              <p className="mb-0 font-weight-bold text-black">TAX </p>
								  {(this.state.orderObject.applicable_tax)&&	
								  <div>{							  
		this.state.orderObject.applicable_tax.map((taxobj)=>{
									
								 return <p className="mb-0 font-weight-bold text-black">{taxobj.tax_title}({taxobj.percentage}%)
								   <span className="float-right text-secondary"> {taxobj.amount}</span></p>

								
									   })}</div>}
								  
								  

	                              <p className="mb-0 font-weight-bold text-black">TOTAL BILL  <span className="float-right text-secondary"> {this.state.orderObject.total_amount}</span></p>
	                              <p className="mb-0 text-info"><small>Pay via {this.state.orderObject.customer.payment_mode}
	                               {/*  <span className="float-right text-danger">$620 OFF</span>*/}</small>
	                              </p>
	                           </Col>
	                        </Row>
	                     </div>
	                  </div>
	                  <div className="bg-white p-4 shadow-lg mt-2">
	                     <Row className="text-center">
	                       {(this.state.orderObject.delivery_status==0)&& <Col>
	                        	<Icofont icon="tasks" className="icofont-3x text-info"/>
	                           <p className="mt-1 font-weight-bold text-dark mb-0">Order Received Only</p>
	                           <small className="text-info mb-0">NOW</small>
	                        </Col>
	}

	{
		(this.state.orderObject.delivery_status==1)&&   <Col>
		  <Icofont icon="check-circled" className="icofont-3x text-success"/>
		 <p className="mt-1 font-weight-bold text-dark mb-0">Order Accepted</p>
		 <small className="text-success mb-0"></small>
	  </Col>
	}
		{
		(this.state.orderObject.delivery_status==2)&&   <Col>
		  <Icofont icon="check-circled" className="icofont-3x text-success"/>
		 <p className="mt-1 font-weight-bold text-dark mb-0">Order Completed</p>
		 <small className="text-success mb-0"></small>
	  </Col>
	}
	                      <Col>
						  <p>
							<b>

							suggestion :
							</b>
							{this.state.orderObject.suggestion}
						  </p>
						  <p>
							<b>

							Delivery Instruction :
							</b>
							{this.state.orderObject.customer.delivery_instructions}
						  </p>
						  </Col>
	                     </Row>
	                  </div>
	               </Col>
	            </Row>
	         </Container>
	      </section>
    	</div>);
    }
}


export default TrackOrder;