import React from 'react';
import {Row,Col} from 'react-bootstrap';
import ExtraPaymentCard from '../common/ExtraPaymentCard';
import DeleteAddressModal from '../modals/DeleteAddressModal';
import Icofont from 'react-icofont';
import {NavLink,Link} from 'react-router-dom';
import {NavDropdown,Nav} from 'react-bootstrap';
import DropDownTitle from '../common/DropDownTitle';
import DataService from '../../services/DataService';
import {confirmDeleteAction,alertError,alertWarning,alertCreation,alertDeletion} from '../../components/Alerts';
import SimpleReactValidator from 'simple-react-validator';
import { data } from 'jquery';
import ExtraPaymentModel from '../modals/ExtraPaymentModel';

class ExtraPayments extends React.Component {
	constructor(props, context) {
	    super(props, context);
		this.dataHandler=new DataService("extra_payments");
		this.validator = new SimpleReactValidator({autoForceUpdate: this});

	    this.state = {
			title:"New Extra Payment",
		itemsDisplayed:[],
		createForm:{
			enable_takeout:true,
			enable_delivery:true,
			enable_percentage:true
		},
		selectedItem:{},
		validator:this.validator,
		addMethod:true,	
	    showDeleteModal: false,
		showAddExtraPayment: false,
        selectedRestaurant: this.dataHandler.getSelectedRestaurant(),
	    };
		this.reloadData();
		this.createFormHandler=this.createFormHandler.bind(this);
		this.showUpdateExtraPaymentModel=this.showUpdateExtraPaymentModel.bind(this);
		this.addExtraPayment=this.addExtraPayment.bind(this);
		this.deleteConfirm=this.deleteConfirm.bind(this);
		this.updateExtraPayment=this.updateExtraPayment.bind(this);
	}
	deleteConfirm = (id,lengthItems) => {


        confirmDeleteAction('Extra Payment').then((userResponse) => {
            if (userResponse.isConfirmed == true) {
                this.deleteExtraPayment(id);
            }
        });
	

    };

    deleteExtraPayment = (id) => {
        let inputParams = {};
        inputParams['id'] = id;
        this.dataHandler.deleteData(inputParams)
            .then((response) => {
				console.log(response);

                if (response.data['status'] === 'sucess') {
                    alertDeletion('Extra Payment');
                    this.reloadData();
                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));
    };
	
	createFormHandler = (event) => {
        let key = '';
        let value = '';

        let inputForm = {...this.state.createForm};
		if ((event.target.type == 'text')|(event.target.type == 'number') ){
            key = event.target.name;
            value = event.target.value;
        } else if (event.target.type == 'date') {
            key = event.target.name;
            value = event.target.value;

        }
		else if (event.target.type == 'select') {
            key = event.target.name;
            value = event.target.value;

        }
		else if (event.target.type == 'checkbox') {
            key = event.target.name;
            value = event.target.checked;
        }

    
                inputForm[key] = value;
				console.log(inputForm)
                this.setState({ createForm: inputForm });
            
        
    };


	updateExtraPayment= (e) => {
		if((this.validator.allValid())==true)
        {
        let inputParams = this.state.createForm;
     
			console.log(inputParams);
		this.dataHandler.updateData(inputParams,this.state.selectedItem.id)
            .then((response) => {
                if (response.data['status'] === 'Sucess') {
                    alertCreation('Extra Payment');
					this.setState({createForm:{}})
					this.hideExtraPaymentNew();
	
					this.reloadData();
                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));

        
		}
		else
		{
			this.validator.showMessages();

		}
    };




	addExtraPayment= (e) => {
		if((this.validator.allValid())==true)
        {
        let inputParams = this.state.createForm;
        if (this.state.selectedRestaurant != 0) {
			inputParams = {
				rest_id: this.state.selectedRestaurant,
				...inputParams,
			};
		}
        let formData = new FormData(); //formdata object
        Object.keys(inputParams).forEach(function (key) {
            formData.append(key, inputParams[key]); //append the values with key, value pair
        });

        this.dataHandler.createDataFromForm(formData)
            .then((response) => {
                if (response.data['status'] === 'Sucess') {
                    alertCreation('Extra Payment');
					this.hideExtraPaymentNew();
					this.reloadData();

                } else {
                    alertError(response.data['message']);
                }
            })
            .catch((err) => alert(err));

        
		}
		else
		{
			this.validator.showMessages();

		}
    };










	comparePriority( a, b ) {
		if ( a.priority < b.priority ){
		  return -1;
		}
		if ( a.priority > b.priority ){
		  return 1;
		}
		return 0;
	  }


	  showUpdateExtraPaymentModel=(extraPaymentObj)=>
	  { 
		let inputParams={'title':extraPaymentObj.title,
		
		'enable_percentage':extraPaymentObj.enable_percentage,
		'enable_takeout':extraPaymentObj.enable_takeout,

		'enable_delivery':extraPaymentObj.enable_delivery,

		'value':extraPaymentObj.value,

	};		
		this.setState({ selectedItem:extraPaymentObj,
			addMethod:false,showAddExtraPayment: true,
			createForm:inputParams });
	  }
	  

    /*
    fetch elements from server and reload data elements
    */
    reloadData = () => {
        let inputParams = {};
        if (this.state.selectedRestaurant != 0) {
			inputParams = {
				rest_id: this.state.selectedRestaurant,
				...inputParams,
			};
		}
        this.dataHandler.getData(inputParams).then((response) => {
            let currentData = response.data.data;
			console.log(currentData);
            this.setState({itemsDisplayed:currentData,addMethod:true});
        });
    };

	hideExtraPaymentNew = () => this.setState({
		title:"New Extra Payment",
	createForm:{

		enable_percentage:true
	},
	selectedItem:{},
	validator:this.validator,
	addMethod:true,	
	showDeleteModal: false,
	showAddExtraPayment: false,
	});
    hideDeleteModal = () => this.setState({ showDeleteModal: false });
	render() {
    	return (
    		<>
				<ExtraPaymentModel show={this.state.showAddExtraPayment} createFormHandler={this.createFormHandler}
				 createForm={this.state.createForm}
				 addExtraPayment={this.addExtraPayment} 
				 updateExtraPayment={this.updateExtraPayment}
				 addMethod={this.state.addMethod}
				 title={this.state.title}
				 validator={this.state.validator} 
				  onHide={this.hideExtraPaymentNew}/>
        		<DeleteAddressModal show={this.state.showDeleteModal} onHide={this.hideDeleteModal}/>
    		    <div className='p-4 bg-white shadow-sm'>
	              <Row>
	               <Col md={12}>
					<Nav activeKey={0} className="ml-auto" onSelect={this.closeMenu}>
						<Nav.Link eventKey={0} as={NavLink} activeclassname="active" exact to="">
							<h4 className="font-weight-bold mt-0 mb-4">  ExtraPayment List </h4><span className="sr-only">(current)</span>
						</Nav.Link>

							<Link to='#' onClick={() => this.setState({ showAddExtraPayment: true })} className="text-primary mr-3 mt-3"><i className="icofont-plus-circle"></i> New Extra Payment</Link>
					</Nav>
	               </Col>

				   {this.state.itemsDisplayed.map((dataObj,idx)=>
	               <Col md={6}>
	               	<ExtraPaymentCard
	               		title= {dataObj.title}
						extraPaymentObj={dataObj}
						onHide={this.hideExtraPaymentNew}
						showUpdateExtraPaymentModel={this.showUpdateExtraPaymentModel}
						imageclassName= 'mr-3'
						onClick={() => {
							this.deleteConfirm(dataObj.id);
						}}
	               	/>
	               </Col>
				   )}
	               
	            </Row>
			    </div>
		    </>
    	);
    }
}
export default ExtraPayments;