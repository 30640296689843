import React from "react";
import { Row, Col } from "react-bootstrap";
import MinAmountFreeOffer from "./MinAmountFreeOffer";

class Offers extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {};
	}

	render() {
		return (
			<div className="p-4 bg-white shadow-sm">
				{/* <Tabs className="mb-3">
						<Tab
							eventKey="min_amount_free_offer"
							title="Minimum Amount Free Offer"
						></Tab>
					</Tabs> */}
				<Row>
					<Col md={12}>
						<h4 className="font-weight-bold mt-0 mb-4">
							Minimum Amount Free Offers
						</h4>
					</Col>
					<Col md={12}>
						<MinAmountFreeOffer />
					</Col>
				</Row>
			</div>
		);
	}
}
export default Offers;
