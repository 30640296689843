import React from "react";
import { Card, Media, Button, Badge } from "react-bootstrap";
import Icofont from "react-icofont";

class OfferCard extends React.Component {
	render() {
		return (
			<Card className="bg-white payments-item mb-4 shadow-sm">
				<div className="gold-members p-4">
					<span className="float-right">
						<Button
							variant="outline-secondary"
							onClick={() =>
								this.props.showMinAmountFreeOfferUpdateModal(
									this.props.offer
								)
							}
							size="sm"
							title="edit"
						>
							<Icofont icon="pencil-alt-1" />
						</Button>

						{/* <Button
							variant="outline-secondary"
							onClick={this.props.onClick}
							size="sm"
							className="ml-2"
							title="delete"
						>
							<Icofont icon="trash" />
						</Button> */}
					</span>
					<Media>
						<Media.Body>
							<h5 className="mb-1">
								Title : {this.props.offer.title}
							</h5>
							<p>
								<h7>
									Offer Message: {this.props.offer.offer_message}
								</h7>
							</p>
							<p>
								<h7>
									Minimum Amount Required{" "}
									<Badge variant="secondary">
										{
											this.props.offer
												.minim_amount_to_be_purchased
										}
									</Badge>
								</h7>
								<p></p>
								<h7>Free Items Offered</h7>{" "}
								{this.props.offer.offered_items.map((item) => (
									<>
										<Badge variant="secondary">
											{item.item_name}
										</Badge>
										{"    "}
										{"    "}
									</>
								))}
								<p></p>
								<h7>
									Maximum Selectable Items{" "}
									<Badge variant="secondary">
										{
											this.props.offer
												.max_number_of_items_can_be_selected
										}
									</Badge>
								</h7>
								<p></p>
								<h7
									style={{
										color: this.props.offer.enable_takeout
											? "green"
											: "red",
									}}
								>
									{this.props.offer.enable_takeout
										? "Takeout Enabled"
										: "Takeout Enabled"}
								</h7>
								<p></p>
								<h7
									style={{
										color: this.props.offer.enable_delivery
											? "green"
											: "red",
									}}
								>
									{this.props.offer.enable_delivery
										? "Delivery Enabled"
										: "Delivery Disabled"}
								</h7>
								<p></p>
								<h7
									style={{
										color: this.props.offer.active
											? "green"
											: "red",
									}}
								>
									{this.props.offer.active
										? "Active"
										: "Inactive"}
								</h7>
							</p>
						</Media.Body>
					</Media>
				</div>
			</Card>
		);
	}
}

export default OfferCard;
