import React from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Badge,
  Form,
  InputGroup,
  Button,
  Tab,
  Nav,
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup,
  Image,
  OverlayTrigger,
  Tooltip,
  Table,
  Collapse,
} from "react-bootstrap";
import ItemsCarousel from "./common/ItemsCarousel";
import ChooseAddressCard from "./common/ChooseAddressCard";
import CheckoutItem from "./common/CheckoutItem";
import MutilUpload from "./common/MultipleImageUploadComponent";
import AddAddressModal from "./modals/AddAddressModal";
import FontAwesome from "./common/FontAwesome";
import Icofont from "react-icofont";
import { Multiselect } from "multiselect-react-dropdown";
import DataService from "../services/DataService";
import { alertCreation, alertError } from "../components/Alerts";
import SimpleReactValidator from "simple-react-validator";
import TopUpItems from "./TopUpItems";
import CustomSuggestionTab from "./CustomSuggestionTab"
import SuggestionTab from "./SuggestionTab";
import { createBrowserHistory } from 'history'
import {currentURL} from '../helpers/globalConstants';
const KeyCodes = {
  comma: 188,
  enter: 13,
};
console.log("reached");
const delimiters = [KeyCodes.comma, KeyCodes.enter];
const history = createBrowserHistory()

class AddCoupon extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.dataHandler = new DataService("restaurant_food_items");
    this.state = {
      showAddressModal: false,
      createForm: {
        is_delivery_enabled:true,
        is_takeout_enabled:true

      },
      showHideDelivery: false,
	  customSuggestions:[],
      tags: [],
      options: [],
      optionsFood: [],
      payOptions: [
        { id: "1", name: "Free" },
        { id: "2", name: "Paid" },
      ],
      payValues: { 1: "Free", 2: "Paid" },
      mandatoryOptions: [
        { id: 1, name: "Optional" },
        { id: 2, name: "Mandatory" },
      ],
      mandatoryValues: { 1: "Optional", 2: "Mandatory" },
	  questionOptions:[
        { id: "1", name: "Yes Or No" },
        { id: "2", name: "Descriptive" },
      ],
      FoodValues: {},
      upsellItems: [],
    };
    this.reloadCategoryData();
    this.reloadFoodData();
    this.createFormHandler = this.createFormHandler.bind(this);
    this.addFoodItem = this.addFoodItem.bind(this);
    this.hideDelivery = this.hideDelivery.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
    this.addNewTab = this.addNewTab.bind(this);
    this.addNewCustomaization = this.addNewCustomaization.bind(this);
    this.removeTab = this.removeTab.bind(this);
    this.removeFoodItem = this.removeFoodItem.bind(this);
    this.removeQuestionTab = this.removeQuestionTab.bind(this);
    this.removeQuestionItem = this.removeQuestionItem.bind(this);
	this.addNewQuestionItem=this.addNewQuestionItem.bind(this);
	this.addNewQuestionTab=this.addNewQuestionTab.bind(this);
  }

  removeTab = (currentTab) => {
    let inputItems = [...this.state.upsellItems];
    inputItems.splice(inputItems.indexOf(currentTab), 1);
    this.setState({ upsellItems: inputItems });
  };

  removeFoodItem = (id, foodObj) => {
    let inputItems = [...this.state.upsellItems];

    let currentId = id;
    inputItems = inputItems.map((tabObj) => {
      if (tabObj.id === id) {
        tabObj.added_items.splice(tabObj.added_items.indexOf(foodObj), 1);
      }
      return tabObj;
    });

    this.setState({ upsellItems: inputItems });
  };

  addNewTab = (tabForm) => {
    let currentId =1;
	currentId=(this.state.upsellItems.length>0)?this.state.upsellItems[this.state.upsellItems.length-1].id+1:1
    let currentItem = {
		title: tabForm["tab_title"],
		id: currentId,
		added_items: [],
		tab_type: tabForm["tab_type"],
	  };
    let inputItems = [...this.state.upsellItems];
    inputItems.push(currentItem);
    this.setState({ upsellItems: inputItems });
    console.log(inputItems);
  };



  addNewQuestionTab = (questionTabForm) => {
    let currentId =1;
	currentId=(this.state.customSuggestions.length>0)?this.state.customSuggestions[this.state.customSuggestions.length-1].id+1:1
    let currentItem = {
		title: questionTabForm["tab_title"],
		id: currentId,
		questions: [],
	  };
    let inputItems = [...this.state.customSuggestions];
    inputItems.push(currentItem);
    this.setState({ customSuggestions: inputItems });
    console.log(inputItems);
  };
  removeQuestionTab = (currentTab) => {
    let inputItems = [...this.state.customSuggestions];
    inputItems.splice(inputItems.indexOf(currentTab), 1);
    this.setState({ customSuggestions: inputItems });
  };

removeTab = (currentTab) => {
    let inputItems = [...this.state.upsellItems];
    inputItems.splice(inputItems.indexOf(currentTab), 1);
    this.setState({ upsellItems: inputItems });
  };

  removeFoodItem = (id, foodObj) => {
    let inputItems = [...this.state.upsellItems];

    let currentId = id;
    inputItems = inputItems.map((tabObj) => {
      if (tabObj.id === id) {
        tabObj.added_items.splice(tabObj.added_items.indexOf(foodObj), 1);
      }
      return tabObj;
    });

    this.setState({ upsellItems: inputItems });
  };




  addNewQuestionItem = (id, questionForm) => {
    let inputItems = [...this.state.customSuggestions];

    let currentId = id;
    inputItems = inputItems.map((tabObj) => {
      if (tabObj.id === id) {
        let currentItem = {
		quetion_title: questionForm["quetion_title"],
    	id: currentId,
        question_type: questionForm["question_type"],
        answers: questionForm["answers"]

        };
        tabObj.questions.push(currentItem);
      }
      return tabObj;
    });
console.log(inputItems);
    this.setState({ customSuggestions: inputItems });
  };

  removeQuestionItem = (id, questionObj) => {
    let inputItems = [...this.state.customSuggestions];

    let currentId = id;
    inputItems = inputItems.map((tabObj) => {
      if (tabObj.id === id) {
        tabObj.questions.splice(tabObj.questions.indexOf(questionObj), 1);
      }
      return tabObj;
    });

    this.setState({ customSuggestions: inputItems });
  };




  addNewCustomaization = (id, foodForm) => {
    let inputItems = [...this.state.customSuggestions];
    console.log(inputItems);

    let currentId = id;
    inputItems = inputItems.map((tabObj) => {
      console.log(tabObj);
      if (tabObj.id === id) {
        if(tabObj["tab_type"]==1)
{
        let currentItem = {
          food_item: foodForm["food_item"],
          id: currentId,
          payment_type: foodForm["payment_type"],
          choice_type:foodForm["choice_type"],
          free_available:foodForm['free_available'],
          item_price:foodForm['item_price']

        };
        tabObj.added_items.push(currentItem);
        console.log(tabObj.added_items);

      }else{

        let currentItem = {
          quetion_title: foodForm["quetion_title"],
          id: currentId,
          question_type: foodForm["question_type"],
          answers: foodForm["answers"]
      
              };

              tabObj.added_items.push(currentItem);

      }

      }
      return tabObj;
    });
console.log(inputItems);
    this.setState({ upsellItems: inputItems });
  };

  /*
    fetch elements from server and reload data elements
    */
  reloadFoodData = () => {
    let inputParams = {};
    this.dataHandler.getData(inputParams).then((response) => {
      let currentData = response.data.data;
      let abstractData = currentData.map((obj) => ({
        name: obj["title"],
        id: obj.id,
        price:obj.price
      }));
      this.setState({ optionsFood: abstractData });
      this.setState({ itemsDisplayed: currentData });
    });
  };
  /*
    fetch elements from server and reload data elements
    */
  reloadCategoryData = () => {
    let inputParams = {};
    new DataService("restaurant_food_category")
      .getData(inputParams)
      .then((response) => {
        let abstractData = response.data.data.map((obj) => ({
          name: obj["title"],
          id: obj.id,
        }));
        this.setState({ options: abstractData });
      });
  };

  onRemove = (selectedList, selectedItem) => {
    let inputForm = { ...this.state.createForm };

    let abstractData = selectedList.map((obj) => obj.id);
    inputForm["categories"] = JSON.stringify(abstractData);
    this.setState({ createForm: inputForm });
  };

  onSelect = (selectedList, selectedItem) => {
    let inputForm = { ...this.state.createForm };

    let abstractData = selectedList.map((obj) => obj.id);
    inputForm["categories"] = JSON.stringify(abstractData);
    this.setState({ createForm: inputForm });
  };

  onSelectMenuCategory = (selectedList, selectedItem) => {
    let inputForm = { ...this.state.createForm };
    console.log(selectedItem);
    let cat_id = selectedItem.id;
    inputForm["menu_category"] = cat_id;
    this.setState({ createForm: inputForm });
  };

  createFormHandler = (event) => {
    let key = "";
    let value = "";

    let inputForm = { ...this.state.createForm };

    if (event.target.type == "text" || event.target.type == "textarea") {
      key = event.target.name;
      value = event.target.value;
    }
    else if (event.target.type == 'checkbox') {
      key = event.target.name;
      value = event.target.checked;
  }
    else if (event.target.type == "file") {
      key = event.target.name;
      value = event.target.files[0];
    }

    inputForm[key] = value;
    this.setState({ createForm: inputForm });
  };
/*
adding Dood Item
*/
  addFoodItem = (e) => {

    if (this.validator.allValid() == true) {
      e.target.setAttribute("disabled", "disabled");

      let inputParams = this.state.createForm;
	  let upsellItems=this.state.upsellItems;
console.log(upsellItems)
      let formData = new FormData(); //formdata object
      Object.keys(inputParams).forEach(function (key) {
        formData.append(key, inputParams[key]); //append the values with key, value pair
      });
	
	  if(upsellItems.length>0){

		let customSuggestionsString=JSON.stringify(upsellItems);
		formData.append('custom_suggestions',customSuggestionsString)
		}
      this.dataHandler
        .createDataFromForm(formData)
        .then((response) => {
          if (response.data["status"] === "Sucess") {
            alertCreation("Food Item");
            window.location = currentURL+'myaccount/dishItems/';

          } else {
            alertError(response.data["message"]);
          }
        })
        .catch((err) => alert(err));
    } else {
      this.validator.showMessages();
    }
  };

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    this.setState((state) => ({ tags: [...state.tags, tag] }));
  }

  handleDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  handleTagClick(index) {
    console.log("The tag at index " + index + " was clicked");
  }

  hideAddressModal = () => this.setState({ showAddressModal: false });

  hideDelivery(name) {
    this.setState({ showHideDelivery: !this.state.showHideDelivery });
  }

  getQty = ({ id, quantity }) => {
    //console.log(id);
    //console.log(quantity);
  };

  render() {
    const { tags } = this.state;
    return (
      <section className="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
        <AddAddressModal
          show={this.state.showAddressModal}
          onHide={this.hideAddressModal}
        />

        <Container>
          <Row>
          
            <Col md={12}>
              <div className="offer-dedicated-body-left">
                <div className="pt-2"></div>

                <span className="hidden">
                  {/*this.props.shouldHide ? 'hidden' : ''*/}
                  <div className="bg-white rounded shadow-sm p-4 mb-4">
                    <Row>
                      <Col md={12}>
                        <Form>
                          <div className="form-row">
                           
                        
                           
                            <Form.Group className="col-md-12">
                              <Form.Label>Coupon Title</Form.Label>
                              {this.validator.message(
                                "title",
                                this.state.createForm["title"],
                                "required|min:2|max:20",
                                { className: "text-danger" }
                              )}
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  onChange={this.createFormHandler}
                                  name="title"
                                  placeholder="Coupon Title"
                                />
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="col-md-12">
                              <Form.Label>Coupon Code</Form.Label>
                              {this.validator.message(
                                "title",
                                this.state.createForm["title"],
                                "required|min:2|max:20",
                                { className: "text-danger" }
                              )}
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  onChange={this.createFormHandler}
                                  name="title"
                                  placeholder="Coupon Code"
                                />
                              </InputGroup>
                            </Form.Group>

                           

                            <Form.Group className="col-md-12">
                              <Form.Label>Discount percentage </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="text"
                                  name="price"
                                  onChange={this.createFormHandler}
                                  placeholder="%"
                                />
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="col-md-12">
                              <Form.Label>Min Amount  </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="date"
                                  name="price"
                                  onChange={this.createFormHandler}
                                  placeholder="Min Amount"
                                />
                              </InputGroup>
                            </Form.Group>
                                
                            <Form.Group className="col-md-12">
                              <Form.Label>Start date  </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="date"
                                  name="price"
                                  onChange={this.createFormHandler}
                                  placeholder="Start Date"
                                />
                              </InputGroup>
                            </Form.Group>
                            <Form.Group className="col-md-12">
                              <Form.Label>Publishing date  </Form.Label>
                              <InputGroup>
                                <Form.Control
                                  type="date"
                                  name="price"
                                  onChange={this.createFormHandler}
                                  placeholder="Publishing Date"
                                />
                              </InputGroup>
                            </Form.Group>
                         
                            <Form.Group className="mb-0 col-md-12">
                              <Button onClick={
                                
                                this.addFoodItem}>
                                ADD Coupon
                              </Button>
                            </Form.Group>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </div>


                 { (1==0)&&<SuggestionTab
                    options={this.state.options}
                    optionsFood={this.state.optionsFood}
                    payOptions={this.state.payOptions}
                    mandatoryOptions={this.state.mandatoryOptions}
                    mandatoryValues={this.state.mandatoryValues}
                    addNewTab={this.addNewTab}
                    addNewCustomaization={this.addNewCustomaization}
                    addNewQuestionItem={this.addNewQuestionItem}
                    removeFoodItem={this.removeFoodItem}
                    removeTab={this.removeTab}
                    upsellItems={this.state.upsellItems}
                  ></SuggestionTab>
                 }

                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default AddCoupon;
